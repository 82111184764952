import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveRadar } from '@nivo/radar';
import { Box, useTheme } from '@mui/material';
import { FdDelayed } from '@fifthdomain/fe-shared';

const RadarNivo = ({ data, keys, indexBy, colors, ...props }) => {
  const theme = useTheme();
  const customTheme = {
    ...theme.nivoGraph,
    tooltip: {
      container: {
        fontFamily: 'Montserrat, sans-serif',
      },
    },
  };

  return (
    <Box data-cy="radar-chart" height="100%" width="100%">
      <FdDelayed>
        <ResponsiveRadar
          data={data}
          keys={keys}
          indexBy={indexBy}
          margin={{
            top: 20,
            right: 0,
            bottom: -40,
            left: 0,
          }}
          colors={colors}
          axisTop={null}
          axisRight={null}
          enableLabel={false}
          animate
          theme={customTheme}
          gridLevels={0}
          isInteractive={false}
          borderWidth={2}
          enableDots={false}
          gridLabelOffset={5}
          padding={0.5}
          fillOpacity={1}
          {...props}
        />
      </FdDelayed>
    </Box>
  );
};

RadarNivo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape({})),
  indexBy: PropTypes.string.isRequired,
  keys: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

RadarNivo.defaultProps = {
  colors: [],
};

export default RadarNivo;
