import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const SquareButton = ({ label, ...props }) => (
  <Box
    className="flex items-center justify-center w-full border-solid border-2 border-dashed rounded mt-4 cursor-pointer"
    sx={{
      height: '48px',
      fontWeight: 600,
      color: 'rgba(51, 51, 255, 1)',
      borderColor: 'rgba(51, 51, 255, 1)',
    }}
    {...props}
  >
    <FdTypography
      variant="body2"
      color="rgba(51, 51, 255, 1)"
      sx={{ letterSpacing: '0.4px', fontWeight: 600 }}
    >
      {label}
    </FdTypography>
  </Box>
);

SquareButton.propTypes = {
  header: PropTypes.node.isRequired,
};

export default SquareButton;
