import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdCard, useSnapshot, globalStore } from '@fifthdomain/fe-shared';
import ReadinessScore from './Fragments/ReadinessScore';
import SummaryCell from './Fragments/SummaryCell';
import { getCortexTargets, getReadinessScore } from './utils';

const FunctionSummary = ({ loading, userMetrics }) => {
  const {
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);
  const { getValues } = useFormContext();
  const allFunctions = getValues('allFunctions') || [];
  const activeFunctions = getValues('activeFunctions') || [];
  const activeCount = activeFunctions?.length || 0;
  const inActiveCount = getValues('inactiveFunctions')?.length || 0;
  const squads =
    getValues('activeFunctions')
      ?.map((fn) => fn?.squads)
      ?.flat() || [];
  const squadMemberCount =
    squads
      ?.map((s) => s?.members?.items?.filter((sm) => sm?.status === 'ACTIVE'))
      .flat()?.length || 0;
  const orgTargets = getCortexTargets(allFunctions);
  const orgSquads = allFunctions?.map((fn) => fn?.squads)?.flat() || [];
  const { readinessScore } = getReadinessScore(
    orgTargets,
    userMetrics,
    orgSquads,
  );

  return (
    <Box>
      <FdCard variant="outlined">
        <Box
          className={`grid ${hasSkillTarget ? 'grid-cols-5' : 'grid-cols-4'} divide-x`}
        >
          {hasSkillTarget && (
            <SummaryCell
              header={<ReadinessScore score={readinessScore} />}
              loading={loading}
              label="Readiness Score"
              tooltipText="Your organisation's Readiness Score (0-100) is calculated based on the percentage of fulfilled skill-proficiency units out of the total required units across all functions. Each function also has its own Readiness Score."
            />
          )}
          <SummaryCell
            header={activeCount}
            label="Active Functions"
            loading={loading}
          />
          <SummaryCell
            header={squads?.length || 0}
            label="Squads"
            loading={loading}
          />
          <SummaryCell
            header={squadMemberCount}
            loading={loading}
            label="Contributing Members"
            tooltipText="Contributing members are users within your organisation whose skills contribute to any Function. For a user's skills to contribute to a Function, they must be added to a squad within that Function."
          />
          <SummaryCell
            header={inActiveCount}
            label="Inactive Functions"
            loading={loading}
          />
        </Box>
      </FdCard>
    </Box>
  );
};

FunctionSummary.defaultProps = {
  loading: true,
};

FunctionSummary.propTypes = {
  loading: PropTypes.bool,
  userMetrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FunctionSummary;
