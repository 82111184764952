import React, { useState, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FdTypography, FdButton, FdTextField } from '@fifthdomain/fe-shared';
import ChoiceCard from './ChoiceCard';
import update from 'immutability-helper';

const useStyles = makeStyles()(() => ({
  button: {
    border: '1px dashed #1976D2 !important',
    padding: '22px !important',
    fontWeight: '400 !important',
    '&:hover': {
      border: '1px solid #1976D2 !important',
    },
  },
  optionContainer: {},
}));

const MultipleChoiceQuestion = ({
  setShowCompetencyModal,
  isEdit,
  options = [],
  question,
}) => {
  const { classes } = useStyles();

  const [choices, setChoices] = useState([
    { id: '1', correct: false },
    { id: '2', correct: false },
    { id: '3', correct: false },
    { id: '4', correct: false },
  ]);
  const [multipleChoiceQuestionTitle, setMultipleChoiceQuestionTitle] =
    useState('');

  useEffect(() => {
    if (isEdit) {
      setChoices(options);
      setMultipleChoiceQuestionTitle(question);
    }
  }, [isEdit]);

  const handleDeleteChoice = (id) => {
    const values = choices.filter((choice) => choice.id !== id);
    setChoices(values);
  };

  const handleCorrectQuestion = (id) => {
    const values = choices.map((choice) => {
      if (choice.id === id) {
        return {
          ...choice,
          correct: choice.correct ? false : true,
        };
      }
      return choice;
    });
    setChoices(values);
  };

  const handleAddOption = () => {
    let values = [...choices, { id: `${choices.length + 1}` }];
    setChoices(values);
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setChoices((prevChoices) =>
      update(prevChoices, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevChoices[dragIndex]],
        ],
      }),
    );
  }, []);

  const handleOptionupdate = (e) => {
    let name = e.target.name;
    if (name) {
      const values = choices.map((choice) => {
        if (choice.id === name) {
          return {
            ...choice,
            label: e.target.value,
          };
        }
        return choice;
      });
      setChoices(values);
    }
  };

  const renderCard = (choice, index) => {
    return (
      <ChoiceCard
        key={choice.id}
        choice={choice}
        index={index}
        moveCard={moveCard}
        handleDeleteChoice={handleDeleteChoice}
        handleCorrectQuestion={handleCorrectQuestion}
        handleOptionupdate={handleOptionupdate}
      />
    );
  };

  return (
    <Box width="100%">
      <FdTypography size="small" variant="caption" color="secondary">
        Use the checkboxes to select the correct answer(s). It is a multiple
        choice question.
      </FdTypography>
      <Box mt={2}>
        <FdTypography variant="body1">Question</FdTypography>
        <Box mt={1}>
          <FdTextField
            id="question"
            fullWidth
            placeholder="Type your quiz question here"
            value={multipleChoiceQuestionTitle}
            onChange={(e) => setMultipleChoiceQuestionTitle(e.target.value)}
          />
        </Box>
      </Box>
      <Box mt={3}>
        <FdTypography variant="body1">Multiple Choice Options</FdTypography>
        <Box mt={2}>
          {choices && choices.map((choice, index) => renderCard(choice, index))}
        </Box>
        <FdButton
          variant="secondary"
          fullWidth
          className={classes.button}
          onClick={handleAddOption}
        >
          ADD OPTION
        </FdButton>
      </Box>
      <Box mt={2} mb={2}>
        <FdTypography>Points for the Question</FdTypography>
        <Box>
          <FdTextField
            id="time-limit"
            defaultValue={1}
            required
            type="number"
            width="134px"
          />
        </Box>
      </Box>
      <FdButton onClick={() => setShowCompetencyModal(true)}>
        ADD COMPETENCY
      </FdButton>
      <Box display="flex" mt={2}>
        <Box pr={1} ml="auto">
          <FdButton variant="secondary" size="small">
            CANCEL
          </FdButton>
        </Box>
        <Box>
          <FdButton variant="primary" size="small">
            Save
          </FdButton>
        </Box>
      </Box>
    </Box>
  );
};

export default MultipleChoiceQuestion;
