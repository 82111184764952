import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const LegendElement = ({ Icon, label }) => (
  <Box className="flex items-center justify-center gap-x-2">
    <Icon />
    <FdTypography variant="captiontext2">{label}</FdTypography>
  </Box>
);

LegendElement.propTypes = {
  Icon: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
};

export default LegendElement;
