import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import * as singleSpa from 'single-spa';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdCheckbox,
  FdButton,
  FdAlert,
  globalStore,
  useSnapshot,
} from '@fifthdomain/fe-shared';
import ReadinessScore from './ReadinessScore';
import { getCommaSeparated } from '../../../shared/utils/stringUtils';
import { getCortexTargets, getReadinessScore } from '../utils';

const SquadManagerChip = () => {
  return (
    <Box
      className="rounded-xl px-2 py-0.5"
      sx={{ backgroundColor: 'rgba(179, 229, 252, 1)' }}
    >
      <FdTypography variant="captiontext2">Squad Manager</FdTypography>
    </Box>
  );
};

const TypographyItalic = ({ children }) => (
  <FdTypography
    variant="captiontext2"
    color="secondary"
    style={{ fontStyle: 'italic' }}
  >
    {children}
  </FdTypography>
);

TypographyItalic.propTypes = {
  children: PropTypes.node.isRequired,
};

const FunctionRowCard = ({ fn, inActive, isYourSquad, userMetrics }) => {
  const {
    userId,
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);
  const { watch, setValue } = useFormContext();
  const watchSelectedFunctions = watch('selectedFunctions');
  const isActive = !(isYourSquad || inActive);
  const { name, id, squads, mySquadMemberCount } = fn || {};
  const functionLeads =
    fn?.functionLeads?.items?.map((fl) => fl?.user?.name || '') || [];
  const functionLeadsDisplay =
    functionLeads.length > 0
      ? getCommaSeparated(functionLeads)
      : 'Not Assigned';
  const membersCount = squads?.reduce(
    (acc, curr) =>
      acc +
      (curr?.members?.items?.filter((m) => m?.status === 'ACTIVE')?.length ||
        0),
    0,
  );
  const managerForSquad = squads?.find((s) =>
    s?.members?.items?.some(
      (sm) => sm?.userId === userId && sm?.role === 'MANAGER',
    ),
  )?.name;
  const isFunctionSelected = watchSelectedFunctions.some((f) => f?.id === id);
  const fnTargets = getCortexTargets([fn]);
  const { readinessScore } = getReadinessScore(fnTargets, userMetrics, squads);

  return (
    <Box
      className="flex items-start w-full relative my-3"
      sx={{
        borderRadius: '8px',
        backgroundColor: '#F9FAFB',
        padding: '8px 16px',
      }}
    >
      {isActive && (
        <Box className="absolute top-0 left-5">
          <FdCheckbox
            checked={isFunctionSelected}
            onChange={(checked) => {
              const selectedFunctions = checked
                ? [...watchSelectedFunctions, fn]
                : watchSelectedFunctions.filter((f) => f?.id !== id);
              setValue('selectedFunctions', selectedFunctions);
            }}
          />
        </Box>
      )}
      <Box
        className={`flex ${
          isYourSquad ? 'items-start' : 'items-center'
        } justify-between w-full`}
      >
        <Box className={`flex flex-col gap-y-2 ${isActive && 'ml-10'}`}>
          <Box className="flex">
            <Box className="flex items-center gap-x-2">
              {isActive && hasSkillTarget && (
                <ReadinessScore score={readinessScore} />
              )}
              <FdTypography variant="body1">{name}</FdTypography>
            </Box>
          </Box>
          {isActive && (
            <FdTypography variant="captiontext1" color="secondary">
              <strong>{squads?.length || 0}</strong>
              {' Squads, '}
              <strong>{membersCount}</strong>
              {' Contributing Members'}
            </FdTypography>
          )}
          {isYourSquad && (
            <FdTypography variant="captiontext1" color="secondary">
              <strong>{mySquadMemberCount}</strong>
              {' Contributing Members'}
            </FdTypography>
          )}
          {!isYourSquad && (
            <Box className="flex items-center gap-x-2">
              <FdTypography variant="captiontext2">
                Function Lead(s):
              </FdTypography>
              <FdTypography variant="captiontext2" color="secondary">
                {functionLeadsDisplay}
              </FdTypography>
            </Box>
          )}
          {isActive && managerForSquad && (
            <Box className="flex items-center gap-x-1">
              <TypographyItalic>You are a</TypographyItalic>
              <SquadManagerChip />
              <TypographyItalic>
                for &apos;{managerForSquad}&apos;
              </TypographyItalic>
            </Box>
          )}
          {isYourSquad && (
            <Box>
              <Box className="flex items-center gap-x-1">
                <TypographyItalic>You are the</TypographyItalic>
                <SquadManagerChip />
                <TypographyItalic>for this Squad</TypographyItalic>
              </Box>
              <Box mt={2}>
                <FdAlert
                  variant="info"
                  message={
                    hasSkillTarget
                      ? "Although you are a Squad Manager for this Squad, you are not a Function Lead for this Squad's Function. This means that while you can see your Squad's skills (within the Squad's profile), you don't have visibility of the target skills for this Squad's Function. Thus, you can't overlay your Squad's skills in the Organisational Skills Profile."
                      : "Although you are a Squad Manager for this Squad, you are not a Function Lead for this Squad's Function."
                  }
                />
              </Box>
            </Box>
          )}
        </Box>
        <FdButton
          variant="secondary"
          onClick={() =>
            singleSpa.navigateToUrl(
              isYourSquad
                ? `/user-management/squads/view/${id}`
                : `/user-management/workforce/functions/edit/${id}`,
            )
          }
        >
          View
        </FdButton>
      </Box>
    </Box>
  );
};

FunctionRowCard.propTypes = {
  inActive: PropTypes.bool,
  isYourSquad: PropTypes.bool,
  userMetrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fn: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    squads: PropTypes.arrayOf(PropTypes.shape({})),
    functionLeads: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

FunctionRowCard.defaultProps = {
  inActive: false,
  isYourSquad: false,
};

export default FunctionRowCard;
