import { gql } from '@apollo/client';
import _ from 'lodash';
import {
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { listCustomerWorkRolesByOrgId } from '../graphql/queries';

const useCustomerWorkRoles = () => {
  const { orgId } = useSnapshot(globalStore);
  const { data: customerWorkRolesData, loading: customerWorkRolesLoading } =
    useQueryRecursive(gql(listCustomerWorkRolesByOrgId), {
      variables: {
        orgId,
      },
      skip: !orgId,
      staleTime: { seconds: 0 },
    });
  // if there are same skills repeating then pick the highest difficulty
  const customerWorkRoles =
    customerWorkRolesData?.listCustomerWorkRolesByOrgId?.items?.map((cw) => ({
      ...cw,
      customerSkills: {
        items: _(cw?.customerSkills?.items)
          .flatMap(
            (cwSkill) =>
              cwSkill?.skills?.items?.map((skillItem) => ({
                ...skillItem,
                difficulty: cwSkill.difficulty,
              })) || [],
          )
          .groupBy('skill.alias')
          .map((skills) => {
            // Get highest difficulty for each skill
            const highestDifficultySkill = _.maxBy(skills, 'difficulty');
            return {
              difficulty: highestDifficultySkill.difficulty,
              skills: {
                items: skills.map(({ skill, skillId }) => ({ skill, skillId })),
              },
            };
          })
          .groupBy('difficulty')
          // Group by difficulty level to avoid repetition
          .map((skills, difficulty) => {
            return {
              difficulty: parseInt(difficulty, 10),
              skills: {
                items: skills
                  ?.map((sk) => {
                    return {
                      ...sk?.skills?.items[0],
                    };
                  })
                  .flat(),
              },
            };
          })
          .value(),
      },
    })) || [];

  return {
    customerWorkRoles,
    customerWorkRolesLoading,
  };
};

export default useCustomerWorkRoles;
