import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import * as singleSpa from 'single-spa';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdIconWithTooltip,
  FdButton,
  FdTab,
  FdSkeleton,
  FdPageHeading,
} from '@fifthdomain/fe-shared';
import FunctionList from './FunctionList';

const FunctionDashboard = ({ loading, canManageFunctions, userMetrics }) => {
  const { getValues } = useFormContext();
  const activeFunctions = getValues('activeFunctions');
  const inActiveFunctions = getValues('inactiveFunctions');

  return (
    <Box>
      <Box className="flex items-center justify-between">
        <Box className="flex">
          <FdPageHeading type="FUNCTION" variant="h4">
            Function Dashboard
          </FdPageHeading>
          <FdIconWithTooltip
            title={
              <Box>
                Your Function Dashboard displays all Functions that you have
                access to in this organisation.
                <br />
                <br />
                <ul className="list-disc list-outside ml-4">
                  <li>
                    With the Manage Functions permission, you can access all
                    Functions (active and inactive) and the Squads within them.
                  </li>
                  <li>
                    As a Function Lead, you can access all Functions that you
                    lead (by default, these will be active Functions) and the
                    Squads within them.
                  </li>
                  <li>
                    If you&apos;re a Squad Manager, you can access your
                    squad&apos;s profile.
                  </li>
                </ul>
              </Box>
            }
          />
        </Box>
        {canManageFunctions && (
          <FdButton
            onClick={() => {
              singleSpa.navigateToUrl(
                '/user-management/workforce/functions/create',
              );
            }}
          >
            Create
          </FdButton>
        )}
      </Box>
      <Box mt={1}>
        <FdTypography variant="captiontext1" color="secondary">
          Your Function Dashboard displays all Functions that you have access to
          in this organisation. Click &apos;View&apos; to open a Function (or
          Squad) below.
        </FdTypography>
      </Box>
      <Box>
        {canManageFunctions ? (
          <FdTab
            label={[
              {
                label: 'Active Functions',
                index: 0,
                data: (
                  <FdSkeleton height={1000} loading={loading}>
                    <FunctionList
                      data={activeFunctions}
                      userMetrics={userMetrics}
                      canManageFunctions={canManageFunctions}
                      isActive
                    />
                  </FdSkeleton>
                ),
              },
              {
                label: 'Inactive Functions',
                index: 1,
                data: (
                  <FdSkeleton height={1000} loading={loading}>
                    <FunctionList data={inActiveFunctions} />
                  </FdSkeleton>
                ),
              },
            ]}
          />
        ) : (
          <FdSkeleton height={1000} loading={loading}>
            <FunctionList
              data={activeFunctions}
              userMetrics={userMetrics}
              isActive
            />
          </FdSkeleton>
        )}
      </Box>
    </Box>
  );
};

FunctionDashboard.propTypes = {
  loading: PropTypes.bool.isRequired,
  canManageFunctions: PropTypes.bool.isRequired,
  userMetrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FunctionDashboard;
