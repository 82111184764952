import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { FdTable, FdModal, FdTypography } from '@fifthdomain/fe-shared';

const GroupUsersTable = ({ rows = [], onDeleteRow }) => {
  const [groupToDelete, setGroupToDelete] = useState(undefined);
  const columns = [
    { field: 'name', width: 450, headerName: 'Name' },
    { field: 'email', width: 350, headerName: 'Email' },
  ];
  const actions = [
    {
      label: 'Delete',
      show: true,
      onClick: (row) => setGroupToDelete(row),
    },
  ];

  return (
    <Box height="715px" bgcolor="#fff">
      <FdTable
        toolbarSettings={{
          title: '',
          filterButton: true,
          searchBox: true,
        }}
        rows={rows}
        columns={columns}
        tablePageSize={10}
        actions={actions}
        gridId="usermanagement-group-users"
      />
      <FdModal
        size="md"
        title={
          <Box display="flex" alignItems="center">
            <WarningIcon
              style={{
                fontSize: 38,
                color: '#C62828',
                paddingRight: '0.5rem',
              }}
            />
            <span>Remove User</span>
          </Box>
        }
        description={
          <Box>
            <FdTypography variant="subtitle1">
              Are you sure you want to remove the User?
            </FdTypography>
            <Box mt={2}>
              <FdTypography variant="body1" color="secondary">
                This will remove their account from this group, and unassign any
                courses.
              </FdTypography>
            </Box>
          </Box>
        }
        confirm="OK"
        dismiss="CANCEL"
        open={groupToDelete}
        onConfirm={() => {
          onDeleteRow(groupToDelete);
          setGroupToDelete(undefined);
        }}
        onDismiss={() => {
          setGroupToDelete(undefined);
        }}
      />
    </Box>
  );
};

GroupUsersTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onDeleteRow: PropTypes.func.isRequired,
};

export default GroupUsersTable;
