import React from 'react';

const WorkforceIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0.437256C5.376 0.437256 0 5.81326 0 12.4373C0 19.0613 5.376 24.4373 12 24.4373C18.624 24.4373 24 19.0613 24 12.4373C24 5.81326 18.624 0.437256 12 0.437256ZM7.2 19.0373C5.544 19.0373 4.2 17.6933 4.2 16.0373C4.2 14.3813 5.544 13.0373 7.2 13.0373C8.856 13.0373 10.2 14.3813 10.2 16.0373C10.2 17.6933 8.856 19.0373 7.2 19.0373ZM9 7.63726C9 5.98126 10.344 4.63726 12 4.63726C13.656 4.63726 15 5.98126 15 7.63726C15 9.29326 13.656 10.6373 12 10.6373C10.344 10.6373 9 9.29326 9 7.63726ZM16.8 19.0373C15.144 19.0373 13.8 17.6933 13.8 16.0373C13.8 14.3813 15.144 13.0373 16.8 13.0373C18.456 13.0373 19.8 14.3813 19.8 16.0373C19.8 17.6933 18.456 19.0373 16.8 19.0373Z"
      fill="rgba(0, 0, 0, 0.54)"
    />
  </svg>
);

export default WorkforceIcon;
