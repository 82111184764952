import { capitalize } from 'lodash';

export const addUsers = (squadId, users, mutation) => {
  const mutationPromises = users?.map((u) =>
    mutation({
      variables: { input: { squadId, userId: u?.userId, role: u?.role } },
    }),
  );

  return Promise.all(mutationPromises);
};

export const getCurrentUserRole = (_allSquads, _squadId, _userId) => {
  const squad = _allSquads?.find((s) => s?.id === _squadId);
  const role = squad?.members?.items?.find((m) => m?.userId === _userId)?.role;
  return role ? `Squad ${capitalize(role)}` : '';
};

export const modalContentByType = {
  managers: {
    title: 'Squad Manager',
    description:
      'Removing a Squad Manager will revoke their access to the squad, including its skills and performance data, and their ability to add or remove Squad Members and Squad Viewers from the squad.',
    role: 'MANAGER',
  },
  members: {
    title: 'Squad Member',
    description:
      "Removing a Squad Member will deduct their skills from the squad's overall skill set and performance data.",
    role: 'MEMBER',
  },
};
