import React from 'react';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';
import BlueFillerAniImage from '../icons/BlueFillerIAniImage';

const NoWorkRoleAssigned = () => (
  <Box
    className="flex flex-col items-center justify-center w-full rounded mt-2"
    sx={{
      backgroundColor: 'rgba(250, 250, 250, 1)',
      height: '500px',
    }}
  >
    <Box width="150px" height="150px" mb={2}>
      <BlueFillerAniImage />
    </Box>
    <FdTypography variant="body2" color="secondary">
      When you add work roles as function target, they get added here.
    </FdTypography>
  </Box>
);

export default NoWorkRoleAssigned;
