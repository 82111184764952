import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdIconWithTooltip,
  FdSkeleton,
} from '@fifthdomain/fe-shared';

const SummaryCell = ({ header, label, tooltipText, loading }) => (
  <Box className="flex flex-col items-center justify-center">
    <FdTypography
      variant="subtitle1"
      color="secondary"
      style={{ fontWeight: 600 }}
    >
      <FdSkeleton loading={loading} height={28} width={25}>
        {header}
      </FdSkeleton>
    </FdTypography>
    <Box className="flex items-center justify-center">
      <FdTypography variant="captiontext1" color="secondary">
        {label}
      </FdTypography>
      <Box height="36px">
        {tooltipText && <FdIconWithTooltip title={tooltipText} />}
      </Box>
    </Box>
  </Box>
);

SummaryCell.defaultProps = {
  tooltipText: undefined,
  loading: false,
};

SummaryCell.propTypes = {
  header: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  tooltipText: PropTypes.string,
  loading: PropTypes.bool,
};

export default SummaryCell;
