import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography, FdButton } from '@fifthdomain/fe-shared';

const EditContainer = ({
  title,
  labelOnly,
  children,
  field,
  onCancel,
  onSave,
  error,
  loading,
}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <Box mb={1}>
      <Box className="flex items-center justify-between">
        <Box className="flex items-center">
          <FdTypography variant="body1" style={{ fontWeight: '500' }}>
            {title}
          </FdTypography>
        </Box>
        {editMode ? (
          <Box className="flex space-x-2">
            <FdButton
              variant="secondary"
              onClick={() => {
                setEditMode(false);
                onCancel(field);
              }}
            >
              Cancel
            </FdButton>
            <FdButton
              variant="primary"
              onClick={() => {
                if (!error) {
                  setEditMode(false);
                  onSave(field);
                }
              }}
              disabled={error}
            >
              Save
            </FdButton>
          </Box>
        ) : (
          <FdButton
            variant="primary"
            onClick={() => {
              setEditMode(true);
            }}
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Edit'}
          </FdButton>
        )}
      </Box>
      <Box mt={1}>{editMode ? children : labelOnly}</Box>
    </Box>
  );
};

EditContainer.propTypes = {
  title: PropTypes.string.isRequired,
  labelOnly: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  field: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

export default EditContainer;
