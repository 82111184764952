import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const HighlightedLabel = ({ value }) => (
  <Box
    className="flex items-center justify-center mr-2"
    style={{
      backgroundColor: 'rgba(64, 84, 128, 1)',
      height: '28px',
      borderRadius: '4px',
    }}
  >
    <FdTypography
      variant="subtitle1"
      style={{ color: '#fff', padding: '0 0.8rem' }}
    >
      {value}
    </FdTypography>
  </Box>
);

HighlightedLabel.propTypes = {
  value: PropTypes.string.isRequired,
};

export default HighlightedLabel;
