import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FdSelect, FdTypography, FdChip } from '@fifthdomain/fe-shared';

const ClassificationList = [
  'Information Security',
  'Information Security',
  'Information Security',
];

const CompetencyList = [
  'Process for Packaging, Transporting and Storing',
  'Process for Packaging, Transporting and Storing',
  'Process for Packaging, Transporting and Storing',
];

const Concepts = [
  'Analysis',
  'Cryptography',
  'Defensive Computing',
  'Encryption',
  'General',
];

const Context = [
  'Cyber Security',
  'Data Backup',
  'Discovery',
  'Intelligence',
  'Malware',
  'Networking',
  'Public Networks',
];

const useStyles = makeStyles()(() => ({
  chipCover: {
    '& div': {
      cursor: 'pointer',
    },
  },
  chipCoverSelected: {
    '& div': {
      cursor: 'pointer',
    },
    '& span': {
      color: '#fff',
    },
  },
}));

const SelectCompetency = ({
  setSelectedClassification,
  handleSelectedConcepts,
  selectedConcepts,
  handleSelectedContexts,
  selectedContexts,
  setSelectedCompetency,
}) => {
  const { classes } = useStyles();
  return (
    <Box>
      <Box mb={1}>
        <FdTypography color="secondary">Select Classification</FdTypography>
      </Box>
      <FdSelect
        id="classification"
        options={ClassificationList}
        width="100%"
        onChange={setSelectedClassification}
      />
      <Box mt={2}>
        <Box mb={1}>
          <FdTypography color="secondary">Select Concept</FdTypography>
        </Box>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {Concepts.map((concept) => (
            <Box
              mr={1}
              mb={1}
              onClick={() => handleSelectedConcepts(concept)}
              className={
                selectedConcepts.includes(concept)
                  ? classes.chipCoverSelected
                  : classes.chipCover
              }
            >
              <FdChip
                label={concept}
                color={selectedConcepts.includes(concept) ? '' : 'default'}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        <Box mb={1}>
          <FdTypography color="secondary">Select Context</FdTypography>
        </Box>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          {Context.map((context) => (
            <Box
              mr={1}
              mb={1}
              onClick={() => handleSelectedContexts(context)}
              className={
                selectedContexts.includes(context)
                  ? classes.chipCoverSelected
                  : classes.chipCover
              }
            >
              <FdChip
                label={context}
                color={selectedContexts.includes(context) ? '' : 'default'}
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box mb={1}>
        <FdTypography color="secondary">Select Competency</FdTypography>
      </Box>
      <FdSelect
        id="competency"
        options={CompetencyList}
        width="100%"
        onChange={setSelectedCompetency}
      />
    </Box>
  );
};

export default SelectCompetency;
