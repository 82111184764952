import Yup from '../Common';

const functionSchema = Yup.array().of(
  Yup.object().shape({
    functionId: Yup.string(),
    functionName: Yup.string(),
    status: Yup.string(),
    workRoles: Yup.array().of(
      Yup.object().shape({
        pkId: Yup.string(),
        workRoleId: Yup.string(),
        workRoleName: Yup.string(),
        units: Yup.number(),
      }),
    ),
    functionLeads: Yup.array().of(
      Yup.object().shape({
        id: Yup.string(),
        name: Yup.string(),
        email: Yup.string(),
      }),
    ),
    squads: Yup.array().of(
      Yup.object().shape({
        squadId: Yup.string(),
        squadName: Yup.string(),
        squadMembers: Yup.array().of(
          Yup.object().shape({
            pkId: Yup.string(),
            id: Yup.string(),
            name: Yup.string(),
            email: Yup.string(),
            userId: Yup.string(),
          }),
        ),
        squadManagers: Yup.array().of(
          Yup.object().shape({
            pkId: Yup.string(),
            id: Yup.string(),
            name: Yup.string(),
            email: Yup.string(),
            userId: Yup.string(),
          }),
        ),
      }),
    ),
  }),
);

export const initialValues = {
  activeFunctions: [],
  inactiveFunctions: [],
  selectedFunctions: [],
  allFunctions: [],
};

export const getValidationSchema = () =>
  Yup.object().shape({
    activeFunctions: functionSchema,
    inactiveFunctions: functionSchema,
    selectedFunctions: functionSchema,
    allFunctions: functionSchema,
  });
