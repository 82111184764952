import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FdCard, useSnapshot, globalStore } from '@fifthdomain/fe-shared';
import ReadinessScore from './Fragments/ReadinessScore';
import SummaryCell from './Fragments/SummaryCell';
import { getFunctionCortexTargets, getReadinessScore } from './utils';

const SquadSummary = ({ userMetrics }) => {
  const {
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);
  const { getValues } = useFormContext();
  const workRoles = getValues('workRoles');
  const squads = getValues('squads');
  const contributingMembers = squads.reduce(
    (acc, squad) =>
      acc +
        ((squad?.squadMembers?.length || 0) +
          (squad?.squadManagers?.length || 0)) || 0,
    0,
  );
  const { targets } = getFunctionCortexTargets(workRoles);
  const requiredUnits = _.sumBy(targets, 'units');
  const squadsForMetrics =
    squads?.map((s) => ({
      members: {
        items: [
          ...(s?.squadManagers?.map((sm) => ({ userId: sm?.userId })) || []),
          ...(s?.squadMembers?.map((sm) => ({ userId: sm?.userId })) || []),
        ],
      },
    })) || [];
  const { fulfilledUnitsDisplay, readinessScore } = getReadinessScore(
    targets,
    userMetrics,
    squadsForMetrics,
  );

  return (
    <Box>
      <FdCard variant="outlined">
        <Box
          className={`grid ${hasSkillTarget ? 'grid-cols-5' : 'grid-cols-2'} divide-x`}
        >
          {hasSkillTarget && (
            <>
              <SummaryCell
                header={<ReadinessScore score={readinessScore} />}
                label="Readiness Score"
                tooltipText="A Function's Readiness Score (0-100) is based on the percentage of fulfilled skill-proficiency units out of the total required skill-proficiency units, based on the current users in the function's squads."
              />
              <SummaryCell
                header={requiredUnits}
                label={
                  <Box className="flex items-center gap-x-2">
                    <Box
                      sx={{
                        width: '16px',
                        height: '16px',
                        border: '3px solid rgba(0, 151, 167, 1)',
                      }}
                    />
                    Required Units
                  </Box>
                }
                tooltipText="Each square on the Skills Cortex represents a Skill-Proficiency unit. The Required Units for a function are the total skill-proficiency units needed, combining all units required for each selected work role. This is represented by highlighted borders on the Skills Cortex"
              />
              <SummaryCell
                header={`${fulfilledUnitsDisplay}/${requiredUnits}`}
                label={
                  <Box className="flex items-center gap-x-2">
                    <CheckCircleIcon
                      sx={{
                        width: '22px',
                        height: '22px',
                        color: 'rgba(92, 237, 202, 1)',
                      }}
                    />
                    Fulfilled Units
                  </Box>
                }
                tooltipText={
                  <>
                    Fulfilled units represent the total number of units
                    fulfilled out of the total units required. Skill fulfillment
                    is shown by filled-in squares on the cortex with numbers.
                    <br />
                    <br />
                    A unit is considered fulfilled when a unique user solves at
                    least one challenge mapped to that skill-proficiency or to
                    the same skill at a higher proficiency level.
                    <br />
                    <br />
                    The number on each box indicates how many users have solved
                    at least one challenge for that skill-proficiency. When the
                    required units for a skill-proficiency match the number of
                    unique users who have solved at least one challenge for that
                    skill-proficiency or at a higher proficiency level, the
                    skill-proficiency is considered fulfilled.
                  </>
                }
              />
            </>
          )}
          <SummaryCell header={squads?.length} label="Squads" />
          <SummaryCell
            header={contributingMembers}
            label="Contributing Members"
          />
        </Box>
      </FdCard>
    </Box>
  );
};

SquadSummary.propTypes = {
  userMetrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SquadSummary;
