import React from 'react';
import { useTheme } from '@mui/material';

const CompetitionsIcon = () => {
  const theme = useTheme();
  const { competitions } = theme?.fdProColors?.event || {};

  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="30" rx="4" fill={competitions} />
      <g opacity="0.85" clipPath="url(#clip0_33915_34739)">
        <path
          d="M22 8H20V6H10V8H8C6.9 8 6 8.9 6 10V11C6 13.55 7.92 15.63 10.39 15.94C11.02 17.44 12.37 18.57 14 18.9V22H10V24H20V22H16V18.9C17.63 18.57 18.98 17.44 19.61 15.94C22.08 15.63 24 13.55 24 11V10C24 8.9 23.1 8 22 8ZM8 11V10H10V13.82C8.84 13.4 8 12.3 8 11ZM22 11C22 12.3 21.16 13.4 20 13.82V10H22V11Z"
          fill="black"
          fillOpacity="0.87"
        />
      </g>
      <defs>
        <clipPath id="clip0_33915_34739">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CompetitionsIcon;
