import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdCortex,
  cortexSort,
  getCortexLevels,
} from '@fifthdomain/fe-shared';
import { proficiencyLevels } from '../../../constants';
import useGetSkills from '../../../hooks/useGetSkills';

const ToolTip = ({ skillName, proficiencyName, text }) => (
  <Box>
    <FdTypography
      variant="captiontext2"
      color="contrastText"
      style={{ fontWeight: 600, marginBottom: '0.2rem' }}
    >
      {skillName}
    </FdTypography>
    <FdTypography variant="captiontext2" color="contrastText">
      {proficiencyName}
    </FdTypography>
    <Box mt={1}>
      <FdTypography variant="captiontext2" color="contrastText">
        {text ? (
          <span>
            Required Units: <strong>{text}</strong>
          </span>
        ) : (
          <span>Not required</span>
        )}
      </FdTypography>
    </Box>
  </Box>
);

ToolTip.propTypes = {
  skillName: PropTypes.string.isRequired,
  proficiencyName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const defaultBorder = 'rgba(219, 230, 255, 1)';
const defaultBorderWidth = '1px';
const targetBorder = 'rgba(0, 151, 167, 1)';
const targetBorderWidth = '2px';
const fillerBorder = 'rgba(77, 208, 225, 1)';

const TargetsCortex = ({ data, loading, showUnits }) => {
  const { skills, skillsLoading } = useGetSkills();
  const { targets, fillers } = data || {};

  const specialties = _(skills)
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items: items?.filter((s) => s?.alias),
    }))
    .value();
  const cortexLoading = skillsLoading || loading;

  const specialtiesFormatted =
    specialties?.sort(cortexSort)?.map((specialty, idx) => {
      const { levels = [], flip = false } = getCortexLevels(idx);

      const dataSorted = specialty?.items?.map((skill) => {
        const targetUnits = targets?.reduce(
          (acc, t) => (t?.skillAlias === skill?.alias ? acc + t?.units : acc),
          0,
        );
        const idDisplayLabel = showUnits
          ? `${skill?.alias} x ${targetUnits}`
          : skill?.alias;

        return {
          id: skill?.alias,
          idWidth: '40px',
          idDisplay: (
            <Box className="flex items-center">
              {targetUnits > 0 ? (
                <strong>{idDisplayLabel}</strong>
              ) : (
                skill?.alias
              )}
            </Box>
          ),
          data: [
            ...(levels?.map((l) => {
              const isTarget = targets?.some(
                (t) => t?.skillAlias === skill?.alias && t?.level === l,
              );
              const isFiller = fillers.some(
                (t) => t?.skillAlias === skill?.alias && t?.level === l,
              );
              const units =
                targets?.find(
                  (t) => t?.skillAlias === skill?.alias && t?.level === l,
                )?.units || '';

              return {
                x: l,
                yAlias: skill?.alias,
                yBorderColor: isTarget
                  ? targetBorder
                  : isFiller
                    ? fillerBorder
                    : defaultBorder,
                yBorderWidth: isTarget ? targetBorderWidth : defaultBorderWidth,
                yToolTip: (
                  <ToolTip
                    skillName={`${skill?.name} (${skill?.alias})`}
                    proficiencyName={`Level ${l} - ${proficiencyLevels[l - 1]}`}
                    text={units}
                  />
                ),
              };
            }) || []),
          ],
        };
      });
      return { ...specialty, flip, levels, dataSorted };
    }) || [];

  return (
    <FdCortex specialties={specialtiesFormatted} loading={cortexLoading} />
  );
};

TargetsCortex.defaultProps = {
  showUnits: false,
};

TargetsCortex.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  showUnits: PropTypes.bool,
};

export default TargetsCortex;
