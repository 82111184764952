import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  FdButton,
  BasePage,
  FdModal,
  useQueryRecursive,
  FdTypography,
  FdTab,
  FdSkeleton,
  FdCard,
  useSnapshot,
  globalStore,
  useRecentLinks,
  successToastMessage,
  FdPageHeading,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  getValidationSchema,
  initialValues,
} from '../validation-schemas/Functions';
import FunctionDetails from '../components/Functions/Create/FunctionDetails';
import AssignFunctionLeads from '../components/Functions/Fragments/AssignFunctionLeads';
import FunctionLeadsTable from '../components/Functions/Fragments/FunctionLeadsTable';
import DeactivateFunction from '../components/Functions/Fragments/DeactivateFunction';
import FunctionSkillsTarget from '../components/Functions/Create/FunctionSkillsTarget';
import useGetFunctions from '../hooks/useGetFunctions';
import useGetCustomerWorkRoles from '../hooks/useGetCustomerWorkRoles';
import { getFunction } from '../graphql/queries';
import DeleteFunction from '../components/Functions/Fragments/DeleteFunction';
import { getSquadUsers, onError } from '../components/Functions/utils';
import useGetUsers from '../hooks/useGetUsers';
import useGetSquads from '../hooks/useGetSquads';
import SquadList from '../components/Functions/SquadList';
import SquadSummary from '../components/Functions/SquadSummary';
import FunctionSkillsSquadsProfile from '../components/Functions/FunctionSkillsSquadsProfile';
import { updateFunction } from '../graphql/mutations';
import useGetUserWorkRoles from '../hooks/useGetUserWorkRoles';
import useGetUserMetrics from '../hooks/useGetUserMetrics';

const TabPageTitle = ({ title, hasError }) => {
  return (
    <Box
      className="flex items-center gap-x-2"
      sx={{ color: hasError ? 'rgba(198, 40, 40, 1)' : 'inherit' }}
    >
      {hasError && <WarningAmberIcon />}
      <span>{title}</span>
    </Box>
  );
};

TabPageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  hasError: PropTypes.bool.isRequired,
};

const EditFunction = ({ canManageFunctions }) => {
  const { functionId } = useParams();
  const {
    userId,
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);
  const { search, pathname } = useLocation();
  const tabindex = Number(new URLSearchParams(search).get('tabindex')) || 1;
  const [custIndex, setCustIndex] = useState(tabindex);
  const [showFunctionLeads, setShowFunctionLeads] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const { addRecentLink } = useRecentLinks({ userId });
  const { allFunctions, allFunctionsLoading } = useGetFunctions();
  const { customerWorkRoles } = useGetCustomerWorkRoles();
  const { users, usersLoading } = useGetUsers();
  const { refetchSquads, squads, squadsLoading } = useGetSquads();
  const { userWorkRoles } = useGetUserWorkRoles();
  const validationSchema = getValidationSchema({
    functionNames: allFunctions?.map((fn) => fn.name?.toLowerCase()),
    squadNames: squads?.map((s) => s?.name?.toLowerCase()),
  });
  const [updateFunctionMutation, { loading: updateFunctionMutationLoading }] =
    useMutation(gql(updateFunction), {
      onError,
    });
  const existingSquadMembersInOrg = [
    ...new Set(
      squads
        ?.map((s) =>
          s?.members?.items
            ?.filter((m) => m?.status === 'ACTIVE')
            ?.map((m) => m?.userId),
        )
        .flat(),
    ),
  ];

  const {
    refetch: refetchFunction,
    data: functionData,
    loading: functionLoading,
  } = useQueryRecursive(gql(getFunction), {
    variables: {
      id: functionId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    onCompleted: (_data) => {
      const {
        id,
        name,
        description,
        status,
        functionLeads,
        customerWorkroles,
        squads: functionSquads,
      } = _data?.getFunction || {};
      // check if user is a function lead if not designer
      if (!canManageFunctions) {
        const isFunctionLead = functionLeads?.items?.some(
          (l) => l?.userId === userId,
        );
        if (!isFunctionLead) {
          singleSpa.navigateToUrl('/user-management/workforce/functions');
        }
      }

      // eslint-disable-next-line no-use-before-define
      reset({
        functionId: id,
        functionName: name,
        functionDescription: description,
        status,
        functionLeads:
          functionLeads?.items?.map(({ id: pkId, userId: fnUserId, user }) => ({
            id: fnUserId,
            name: user?.name,
            email: user?.email,
            pkId,
          })) || [],
        workRoles:
          customerWorkroles?.items?.map((wr) => ({
            pkId: wr?.id,
            workRoleId: wr?.customerWorkroleId,
            workRoleName: wr?.customerWorkrole?.name,
            units: wr?.workRoleUnits,
            skills: wr?.customerWorkrole?.customerSkills?.items,
          })) || [],
        squads: functionSquads?.items
          ?.filter((s) => s?.status === 'ACTIVE')
          ?.map((s) => ({
            squadId: s?.id,
            squadName: s?.name,
            squadDescription: s?.description,
            squadMembersSelection: [],
            squadManagersSelection: [],
            squadMembers: getSquadUsers(s?.members?.items, 'MEMBER'),
            squadManagers: getSquadUsers(s?.members?.items, 'MANAGER'),
          })),
      });
      if (status === 'INACTIVE') {
        setCustIndex(0);
      }
      // add recent link
      addRecentLink({
        id,
        name,
        type: 'FUNCTION',
        url: pathname + search,
        role: 'MANAGE',
      });
    },
  });
  const userIds = users?.map((u) => u?.id) || [];
  const { userMetrics, userMetricsLoading } = useGetUserMetrics({
    userIds,
  });
  const usersWithWorkRoles = users?.map((u) => ({
    ...u,
    workRole: userWorkRoles?.find((wr) => wr?.userId === u?.id)?.workRole || '',
  }));
  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    reset,
    getValues,
    trigger,
    formState: { errors },
  } = hookFormMethods;

  const loading =
    allFunctionsLoading ||
    functionLoading ||
    usersLoading ||
    squadsLoading ||
    userMetricsLoading ||
    updateFunctionMutationLoading;
  const { functionName, status } = getValues();
  const isActive = status === 'ACTIVE';
  const hasTabPage0Error =
    errors?.functionLeads || errors?.functionLeadsSelection;
  const hasTabPage1Error = errors?.workRoles || errors?.workRolesSelection;
  const isUserFunctionLead =
    getValues('functionLeads')?.some((l) => l?.id === userId) || false;
  const onSaveFunctionDetails = () => {
    updateFunctionMutation({
      variables: {
        input: {
          id: functionId,
          description: getValues('functionDescription'),
          ...(functionData?.getFunction?.name !== getValues('functionName') && {
            name: getValues('functionName'),
          }),
        },
      },
      onCompleted: () => {
        setShowActivateModal(false);
        successToastMessage('Function saved successfully.');
        refetchFunction();
      },
    });
  };

  return (
    <Box>
      <FdBreadcrumbHeader
        entries={[
          {
            name: 'Functions',
            path: '/user-management/workforce/functions',
            type: 'FUNCTION',
          },
        ]}
        page={{ name: functionName, type: 'FUNCTION' }}
      />
      <BasePage data-cy="edit-function-base-page">
        <FormProvider {...hookFormMethods}>
          <form>
            <Box className="flex items-center justify-between">
              <Box className="flex items-center gap-x-6">
                <FdSkeleton loading={loading} height="48px" width="350px">
                  <FdPageHeading type="FUNCTION">{functionName}</FdPageHeading>
                </FdSkeleton>
                <Box
                  className="flex items-center rounded-full px-3 py-1"
                  sx={{
                    backgroundColor: isActive
                      ? 'rgba(167, 255, 235, 1)'
                      : 'rgba(204, 213, 222, 1)',
                  }}
                >
                  <FdTypography
                    variant="captiontext1"
                    style={{ fontWeight: 500 }}
                  >
                    {isActive ? 'Active' : 'Inactive'}
                  </FdTypography>
                </Box>
              </Box>
              {!isActive && !loading && (
                <FdButton
                  size="large"
                  style={{ backgroundColor: 'rgba(40, 149, 123, 1)' }}
                  onClick={async () => {
                    const result = await trigger([
                      'functionLeads',
                      'workRoles',
                      'workRolesSelection',
                    ]);
                    if (result) {
                      setShowActivateModal(true);
                    }
                  }}
                >
                  Activate Function
                </FdButton>
              )}
            </Box>
            <FdTab
              label={[
                {
                  label: (
                    <TabPageTitle
                      title="Function Details"
                      hasError={hasTabPage0Error}
                    />
                  ),
                  tabRoute: `/user-management/workforce/functions/edit/${functionId}?tabindex=0`,
                  index: 0,
                  data: (
                    <>
                      <FunctionDetails
                        canManageFunctions={canManageFunctions}
                        onClickSave={onSaveFunctionDetails}
                        isEditMode
                      />
                      <FunctionLeadsTable
                        loading={loading}
                        refetchFunction={refetchFunction}
                        showError={hasTabPage0Error}
                        canManageFunctions={canManageFunctions}
                      />
                      {!loading &&
                        canManageFunctions &&
                        (isActive ? (
                          <DeactivateFunction
                            refetchFunction={refetchFunction}
                          />
                        ) : (
                          <DeleteFunction />
                        ))}
                      <AssignFunctionLeads
                        show={showFunctionLeads}
                        setShow={setShowFunctionLeads}
                      />
                    </>
                  ),
                },
                ...(hasSkillTarget && canManageFunctions
                  ? [
                      {
                        label: (
                          <TabPageTitle
                            title="Functions Skills Target"
                            hasError={hasTabPage1Error}
                          />
                        ),
                        tabRoute: `/user-management/workforce/functions/edit/${functionId}?tabindex=1`,
                        index: 1,
                        data: (
                          <FunctionSkillsTarget
                            allCustomerWorkRoles={customerWorkRoles}
                            refetchFunction={refetchFunction}
                            isEditMode
                          />
                        ),
                      },
                    ]
                  : []),
                ...(isActive
                  ? [
                      {
                        label: 'Functions Squads',
                        tabRoute: `/user-management/workforce/functions/edit/${functionId}?tabindex=1`,
                        index: 1,
                        data: (
                          <>
                            <FdCard variant="outlined">
                              <SquadSummary userMetrics={userMetrics} />
                            </FdCard>
                            <FdCard variant="outlined" sx={{ mt: 2 }}>
                              <Box className="flex gap-4">
                                <Box width="60%">
                                  <FunctionSkillsSquadsProfile
                                    userMetrics={userMetrics}
                                  />
                                </Box>
                                <Box width="40%">
                                  <SquadList
                                    users={usersWithWorkRoles}
                                    userMetrics={userMetrics}
                                    existingSquadMembersInOrg={
                                      existingSquadMembersInOrg
                                    }
                                    loading={loading}
                                    refetchFunction={() => {
                                      refetchFunction();
                                      refetchSquads();
                                    }}
                                    canCreate={
                                      isUserFunctionLead || !canManageFunctions
                                    }
                                  />
                                </Box>
                              </Box>
                            </FdCard>
                          </>
                        ),
                      },
                    ]
                  : []),
              ]}
              index={tabindex}
              custIndex={custIndex}
              setCustIndex={setCustIndex}
              tabLinkComponent={RouterLink}
            />
            <FdModal
              size="md"
              title="Ready to activate the function?"
              description="This will activate the function, making it available for the Function Lead to access, who can then begin to create squads for the function."
              confirm="Activate"
              dismiss="Cancel"
              confirmStyles={{ backgroundColor: 'rgba(40, 149, 123, 1)' }}
              open={showActivateModal}
              onConfirm={() => {
                updateFunctionMutation({
                  variables: {
                    input: {
                      id: functionId,
                      status: 'ACTIVE',
                    },
                  },
                  onCompleted: () => {
                    setShowActivateModal(false);
                    successToastMessage(
                      'Success! Function is now activated. Function Lead will now be able to access the function.',
                    );
                    refetchFunction();
                  },
                });
              }}
              onDismiss={() => setShowActivateModal(false)}
            />
          </form>
        </FormProvider>
      </BasePage>
    </Box>
  );
};

EditFunction.propTypes = {
  canManageFunctions: PropTypes.bool.isRequired,
};

export default EditFunction;
