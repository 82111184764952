import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  FdBreadcrumbs,
  FdTab,
  FdLoadingSpinner,
  AuthContext,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import { useQuery, gql } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { listQuizesByOrgId, getUserById } from '../graphql/queries';
import QuizDetails from '../components/Quiz/QuizDetails';
import QuizQuestions from '../components/Quiz/QuizQuestions';
import { initialValues, validationSchema } from '../validation-schemas/Quizzes';

const ViewQuiz = () => {
  const { quizId } = useParams();
  const { user } = useContext(AuthContext);
  const { data: getUserData } = useQuery(gql(getUserById), {
    variables: {
      id: user?.username,
    },
  });

  const orgId = getUserData?.getUserById?.items[0].orgId;
  const { data: quizData, loading: quizLoading } = useQueryRecursive(
    gql(listQuizesByOrgId),
    {
      variables: {
        limit: 500,
        orgId,
      },
    },
  );

  const quizDetails = quizData?.listQuizesByOrgId?.items.find(
    (quiz) => quiz.id === quizId,
  );

  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { control } = hookFormMethods;

  if (quizLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <Box>
      <FdBreadcrumbs
        linkChain={[{ url: '/user-management', name: 'Home' }]}
        currentPage="User Management / Manage Quiz / Preview Quiz"
        linkComponent={RouterLink}
      />
      {/* <FormProvider {...hookFormMethods}> */}
      <form style={{ marginTop: '-1rem' }}>
        <FdTab
          label={[
            {
              label: 'Details',
              tabRoute: `/user-management/view-quiz/${quizId}`,
              index: 0,
              data: (
                <QuizDetails
                  details={quizDetails}
                  control={control}
                  Controller={Controller}
                />
              ),
            },
            {
              label: 'Questions',
              tabRoute: `/user-management/edit-quiz/${quizId}`,
              index: 1,
              data: <QuizQuestions control={control} Controller={Controller} />,
            },
          ]}
        />
      </form>
      {/* </FormProvider> */}
    </Box>
  );
};

export default ViewQuiz;
