import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Box, Switch } from '@mui/material';
import {
  FdTypography,
  useSnapshot,
  globalStore,
  FdPageHeading,
} from '@fifthdomain/fe-shared';
import ReadinessScore from './Fragments/ReadinessScore';
import FunctionCortex from './Cortex/FunctionCortex';
import { getCortexTargets, getReadinessScore } from './utils';

const FunctionSkillsProfile = ({ loading, userMetrics }) => {
  const [showTargetOverlay, setShowTargetOverlay] = useState(true);
  const {
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);
  const { watch, getValues } = useFormContext();
  const selectedFunctions = watch('selectedFunctions');
  const allFunctions = getValues('allFunctions');
  const allMembers =
    selectedFunctions
      ?.map((f) =>
        f?.squads
          ?.map((s) =>
            s?.members?.items
              .filter((m) => m?.status === 'ACTIVE')
              ?.map((m) => m?.userId),
          )
          ?.flat(),
      )
      ?.flat() || [];
  const selectedUserMetrics =
    userMetrics?.filter((m) => allMembers.includes(m?.userId)) || [];
  const targets = getCortexTargets(selectedFunctions);
  const grouped = _.mapValues(
    _.groupBy(targets, 'skillAlias'),
    (items) => _.maxBy(items, 'level').level,
  );
  const fillers = _.flatMap(grouped, (maxLevel, skillAlias) =>
    _.times(maxLevel - 1, (i) => ({
      level: i + 1,
      skillAlias,
    })),
  );
  const showOverlay = showTargetOverlay && hasSkillTarget;
  const cortexData = {
    targets: showOverlay && hasSkillTarget ? targets : [],
    fillers: showOverlay && hasSkillTarget ? fillers : [],
    metrics: selectedUserMetrics,
  };
  const squads = selectedFunctions?.map((f) => f?.squads).flat() || [];
  const targetUnits = _.sumBy(targets, 'units');
  const { fulfilledUnitsDisplay, readinessScore } = getReadinessScore(
    targets,
    selectedUserMetrics,
    squads,
  );
  const orgTargets = getCortexTargets(allFunctions);
  const orgSquads = allFunctions?.map((f) => f?.squads).flat() || [];
  const { readinessScore: readinessScoreOrg } = getReadinessScore(
    orgTargets,
    userMetrics,
    orgSquads,
  );

  return (
    <Box className="p-6">
      <Box className="flex items-center justify-between">
        <FdPageHeading type="SKILL" variant="h4">
          Organisation Skills Profile
        </FdPageHeading>
        {hasSkillTarget && <ReadinessScore score={readinessScoreOrg} />}
      </Box>
      <Box mt={2}>
        <FdTypography variant="captiontext1" color="secondary">
          {hasSkillTarget ? (
            <>
              The Cyber Skills Cortex visualises cyber skills against five
              proficiency levels (1-5). Skill-Proficiency square fills show
              demonstrated skills (actual skills), while borders show targets
              set via Functions (target skills). Use the checkboxes in the
              Function Dashboard section to filter your view of actual and
              target skills. Hover over a square to see target units required
              for that Skill-Proficiency combination.
            </>
          ) : (
            <>
              The Cyber Skills Cortex visualises cyber skills against five
              proficiency levels (1-5). Skill-Proficiency square fills show
              demonstrated skills (actual skills). Use the checkboxes in the
              Function Dashboard section to filter your view of actual skills.
            </>
          )}
        </FdTypography>
        <Box
          className={`flex flex-col gap-y-1 ${!hasSkillTarget && 'items-center'}`}
          sx={{
            backgroundColor: '#F4F4F4',
            borderRadius: '4px',
            mt: 1,
            padding: '8px 16px',
          }}
        >
          <FdTypography variant="body2" color="secondary">
            Selected:<strong>{` ${selectedFunctions.length || 0} `}</strong>
            functions
          </FdTypography>
          {hasSkillTarget && (
            <Box className="flex items-center justify-between">
              <FdTypography variant="body2">
                Fulfilled Units for selected:
                <strong>{` ${fulfilledUnitsDisplay}/${targetUnits} `}</strong>
              </FdTypography>
              <Box className="flex items-center gap-x-2">
                <FdTypography variant="body2">
                  Readiness Score for selected:
                </FdTypography>
                <ReadinessScore score={readinessScore} />
              </Box>
            </Box>
          )}
        </Box>
        <Box mt={3} className="flex flex-col items-center justify-center">
          <FdTypography variant="subtitle1">Cyber Skills Cortex</FdTypography>
          {hasSkillTarget && (
            <Box className="flex items-center gap-x-1">
              <Switch
                checked={showTargetOverlay}
                onChange={() => setShowTargetOverlay(!showTargetOverlay)}
              />
              <FdTypography variant="captiontext2" color="secondary">
                Overlay Target Skills for Selected Functions and Clusters
              </FdTypography>
            </Box>
          )}
          <Box mt={1}>
            <FunctionCortex
              loading={loading}
              data={cortexData}
              squads={squads}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

FunctionSkillsProfile.propTypes = {
  loading: PropTypes.bool.isRequired,
  userMetrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FunctionSkillsProfile;
