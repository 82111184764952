import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormHelperText, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  FdTypography,
  FdAutocompleteStatic,
  FdModal,
} from '@fifthdomain/fe-shared';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';

const UserPickerModal = ({
  open,
  setOpen,
  loading,
  heading,
  description,
  selectHeading,
  toastLabel,
  errorMessage,
  options,
  optional,
  onUsersSelected,
  isEdit,
}) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    setSelectedUsers([]);
    setError(null);
  }, [open]);

  return (
    <FdModal
      size="md"
      title={
        <Box className="flex items-center gap-x-3">
          {heading}
          {optional && (
            <FdTypography variant="body1" color="secondary">
              optional
            </FdTypography>
          )}
        </Box>
      }
      confirm="ADD"
      dismiss="CANCEL"
      open={open}
      confirmLoading={loading}
      onConfirm={() => {
        if (!selectedUsers.length) {
          setError(errorMessage);
          return;
        }
        onUsersSelected(selectedUsers);
        setOpen(false);
        // show message only on create
        if (!isEdit) {
          successToastMessage(`Success! ${toastLabel} added.`);
        }
      }}
      onDismiss={() => {
        setOpen(false);
        warningToastMessage(`${toastLabel} not added.`);
      }}
    >
      <Box>
        <FdTypography variant="body1" color="secondary">
          {description}
        </FdTypography>
        <Box className="my-3">
          <Box mb={1}>
            <FdTypography variant="subtitle1">{selectHeading}</FdTypography>
            <Box my={0.5}>
              <FdTypography variant="captiontext1" color="secondary">
                {`${selectedUsers?.length} selected`}
              </FdTypography>
            </Box>
          </Box>
          <FdAutocompleteStatic
            multiple
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option.name} ${option.email}`}
            renderOption={(option, { selected }) => (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <Box display="flex" flexDirection="column">
                  <FdTypography variant="body1">{option?.name}</FdTypography>
                  <FdTypography variant="caption" color="secondary">
                    {option?.email}
                  </FdTypography>
                </Box>
              </Box>
            )}
            renderTags={(value) => {
              const chipCountWhenClosed = 1;
              const prefix = value
                .map((option) => option?.name)
                .slice(0, chipCountWhenClosed)
                ?.join(', ');
              const suffix =
                value?.length > chipCountWhenClosed
                  ? `+ ${value?.length - chipCountWhenClosed}`
                  : '';
              return (
                <span className="labelTitle">{`${prefix} ${suffix}`}</span>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label=""
                placeholder={`Select ${toastLabel}`}
                error={error}
              />
            )}
            onChange={(__, value) => {
              setSelectedUsers(value);
              if (value.length > 0) {
                setError(null);
              }
            }}
          />
          {error && (
            <Box mt={0.5}>
              <FormHelperText
                style={{
                  color: 'red',
                }}
              >
                {errorMessage}
              </FormHelperText>
            </Box>
          )}
        </Box>
      </Box>
    </FdModal>
  );
};

UserPickerModal.defaultProps = {
  optional: false,
  loading: false,
  isEdit: false,
};

UserPickerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  selectHeading: PropTypes.string.isRequired,
  toastLabel: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  optional: PropTypes.bool,
  onUsersSelected: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
};

export default UserPickerModal;
