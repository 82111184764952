import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { FdTab, FdModal, FdTypography } from '@fifthdomain/fe-shared';
import SquadUsers from '../Create/SquadUsers';
import {
  createSquadMember,
  deleteSquadMember,
} from '../../../graphql/mutations';
import { createSquadUsers, deleteSquadUsers, onError } from '../utils';
import SquadDetailsEdit from './SquadDetailsEdit';
import {
  successToastMessage,
  warningToastMessage,
} from '../../../shared/utils/toast';

const EditSquad = ({
  openModal,
  setOpenModal,
  users,
  refetchFunction,
  existingSquadMembersInOrg,
}) => {
  const [tabPageId, setTabPageId] = useState(0);
  const [userHasEdited, setUserHasEdited] = useState(false);
  const { getValues, trigger, reset } = useFormContext();
  const [deleteSquadUserMutation, { loading: deleteSquadUserMutationLoading }] =
    useMutation(gql(deleteSquadMember), {
      onError,
    });
  const [
    createSquadMemberMutation,
    { loading: createSquadMemberMutationLoading },
  ] = useMutation(gql(createSquadMember), {
    onError,
  });
  const id = openModal?.idx || 0;
  const squadId = `squads[${id}]`;
  const loading =
    deleteSquadUserMutationLoading || createSquadMemberMutationLoading;
  const allManagers = users?.filter((u) =>
    u?.permissions?.includes('MANAGE_SQUADS'),
  );
  const onSubmit = async () => {
    const result = await trigger([
      `${squadId}.squadMembers`,
      `${squadId}.squadManagers`,
    ]);
    if (result) {
      const { squadId: squadIdPk, deletedUsers } = getValues(squadId);
      const squadMembers = [
        ...(getValues(squadId).squadManagers?.map((s) => ({
          ...s,
          role: 'MANAGER',
        })) || []),
        ...(getValues(squadId).squadMembers?.map((s) => ({
          ...s,
          role: 'MEMBER',
        })) || []),
      ]?.filter((u) => !u?.pkId);
      const onDbActionCompleted = () => {
        setTabPageId(0);
        setOpenModal(null);
        successToastMessage('Squad updated.');
        refetchFunction();
      };
      // delete users if they are removed from FE
      deleteSquadUsers({
        squadUserDeleteMutation: deleteSquadUserMutation,
        users: deletedUsers?.map((u) => u?.pkId),
        onCompleted: () => {
          if (squadMembers?.length === 0) {
            onDbActionCompleted();
          }
        },
      });
      createSquadUsers({
        squadUserMutation: createSquadMemberMutation,
        squadId: squadIdPk,
        users: squadMembers,
        onCompleted: () => {
          onDbActionCompleted();
        },
      });
    }
  };
  const showSaveButton = userHasEdited && tabPageId === 1;

  return (
    <FdModal
      size="xl"
      open={openModal}
      showConfirm={showSaveButton}
      confirm={showSaveButton && 'Save'}
      dismiss={showSaveButton ? 'Cancel' : 'Close'}
      onConfirm={onSubmit}
      onDismiss={() => {
        setTabPageId(0);
        setUserHasEdited(false);
        setOpenModal(null);
        warningToastMessage('Squad not updated.');
        reset();
      }}
      disableConfirm={createSquadMemberMutationLoading}
      confirmLoading={createSquadMemberMutationLoading}
      hideHeader
    >
      <Box mt={2.5} mb={1}>
        <FdTypography variant="h3">Edit Squad</FdTypography>
      </Box>
      <FdTab
        label={[
          {
            label: 'Details',
            index: 0,
            data: (
              <SquadDetailsEdit
                squadId={squadId}
                refetchFunction={refetchFunction}
                setOpenModal={setOpenModal}
              />
            ),
          },
          {
            label: 'Users',
            index: 1,
            data: (
              <Box maxHeight={700} sx={{ overflowY: 'auto' }}>
                <SquadUsers
                  squadId={squadId}
                  allManagers={allManagers}
                  allUsers={users}
                  onEdit={setUserHasEdited}
                  existingSquadMembersInOrg={existingSquadMembersInOrg}
                />
              </Box>
            ),
          },
        ]}
        onTabChange={setTabPageId}
        loading={loading}
      />
    </FdModal>
  );
};

EditSquad.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})),
  refetchFunction: PropTypes.func,
  existingSquadMembersInOrg: PropTypes.arrayOf(PropTypes.shape({})),
};

EditSquad.defaultProps = {
  refetchFunction: () => {},
  existingSquadMembersInOrg: [],
  users: [],
};

export default EditSquad;
