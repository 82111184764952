import React, { useState, useEffect } from 'react';
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { FdTypography, FdButton, FdModal } from '@fifthdomain/fe-shared';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useFormContext } from 'react-hook-form';
import SelectCompetency from './SelectCompetency';
import FreeTextQuestion from './FreeTextQuestion';
import MultipleChoiceQuestion from './MultipleChoiceQuestion/MultipleChoiceQuestion';
import SingleChoiceQuestion from './SingleChoiceQuestion/SingleChoiceQuestion';
import OrderingQuestion from './OrderingQuestions/OrderingQuestion';

const QUESTION_TYPES = [
  { id: '1', label: 'FREE_TEXT' },
  { id: '2', label: 'MULTIPLE_CHOICE' },
  { id: '3', label: 'SINGLE_CHOICE' },
  { id: '4', label: 'ORDERING' },
];

const useStyles = makeStyles()(() => ({
  button: {
    border: '1px dashed #1976D2 !important',
    padding: '26px !important',
    fontWeight: '400 !important',
    '&:hover': {
      border: '1px solid #1976D2 !important',
    },
  },
  summary: {
    flexDirection: 'row-reverse',
  },
  menu: {
    padding: 10,
  },
  accordion: {
    marginBottom: 10,
    border: '1px solid #E0E0E0',
    borderRadius: 4,
    boxShadow: 'none',
  },
}));

const AddQuizQuestions = ({ isEdit, allQuestions }) => {
  const [questions, setQuestions] = useState([{ id: '1', type: 'FREE_TEXT' }]);
  const [showTypeSelect, setShowTypeSelect] = useState(null);
  const [questionType, setQuestionType] = useState('FREE_TEXT');
  const [showCompetencyModal, setShowCompetencyModal] = useState(false);
  const [selectedClassification, setSelectedClassification] = useState();
  const [selectedCompetency, setSelectedCompetency] = useState();
  const [selectedConcepts, setSelectedConcepts] = useState([]);
  const [selectedContexts, setSelectedContexts] = useState([]);
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const { classes } = useStyles();

  const Icons = {
    1: FormatAlignJustifyIcon,
    2: CheckBoxOutlinedIcon,
    3: RadioButtonCheckedOutlinedIcon,
    4: FormatListNumberedOutlinedIcon,
  };

  useEffect(() => {
    if (isEdit) {
      setQuestions(allQuestions);
      setExpandedQuestions(allQuestions?.map((value) => value.id));
    }
  }, [isEdit]);

  const handleExpand = (id) => {
    if (expandedQuestions.includes(id)) {
      const values = expandedQuestions.filter((question) => question !== id);
      setExpandedQuestions(values);
    } else {
      const values = [...expandedQuestions, id];
      setExpandedQuestions(values);
    }
  };

  const handleSelectedConcepts = (value) => {
    if (selectedConcepts.includes(value)) {
      const values = selectedConcepts.filter((concept) => concept !== value);
      setSelectedConcepts(values);
    } else {
      const values = [...selectedConcepts, value];
      setSelectedConcepts(values);
    }
  };

  const handleSelectedContexts = (value) => {
    if (selectedContexts.includes(value)) {
      const values = selectedContexts.filter((context) => context !== value);
      setSelectedContexts(values);
    } else {
      const values = [...selectedContexts, value];
      setSelectedContexts(values);
    }
  };

  const handleOpenSelect = (e) => {
    e.stopPropagation();
    setShowTypeSelect(e.currentTarget);
  };
  const handleCloseSelect = () => {
    setShowTypeSelect(null);
  };

  const handleAddQuestion = () => {
    const values = [...questions, { id: `${questions.length + 1}` }];
    setQuestions(values);
  };

  const handleQuestionSelection = (value, id) => {
    const values = questions.map((question) => {
      if (question.id === id) {
        return {
          ...question,
          type: value,
        };
      }
      return question;
    });
    setQuestions(values);
    setQuestionType(value);
    setShowTypeSelect(null);
  };

  const handleDeleteQuestion = (e, id) => {
    e.stopPropagation();
    const values = questions.filter((question) => question.id !== id);
    setQuestions(values);
  };

  const handleDeleteComp = (value) => {
    const values = selectedContexts.filter((context) => context !== value);
    setSelectedContexts(values);
  };

  const { setValue } = useFormContext();

  useEffect(() => {
    setValue('questions', questions);
  }, [questions]);

  return (
    <Box p={3} bgcolor="#fff" mb={4}>
      <Box mt={2}>
        <FdTypography variant="h3">Quiz Questions</FdTypography>
      </Box>
      <Box mt={2} mb={2}>
        {questions.map((question) => {
          return (
            <Accordion
              expanded={expandedQuestions.includes(question.id)}
              onChange={() => handleExpand(question.id)}
              key={question.id}
              classes={{ root: classes.accordion }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                classes={{
                  root: classes.summary,
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ width: '100%' }}
                >
                  <FdTypography>Question #{question.id}</FdTypography>
                  <Box ml="auto" display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        background: 'rgba(0, 0, 0, 0.03)',
                        border: '1px solid #BDBDBD',
                        borderRadius: 4,
                        padding: '2px 10px',
                        cursor: 'pointer',
                      }}
                      onClick={handleOpenSelect}
                    >
                      <FdTypography
                        aria-controls="question-type-select"
                        color="secondary"
                      >
                        {question.type ? question.type : 'Select Question Type'}
                      </FdTypography>
                      <ArrowDropDown />
                    </Box>
                    <Menu
                      anchorEl={showTypeSelect}
                      open={Boolean(showTypeSelect)}
                      onClose={handleCloseSelect}
                      classes={{
                        paper: classes.menu,
                      }}
                    >
                      {QUESTION_TYPES.map((type) => {
                        const Icon = Icons[type.id];
                        return (
                          <Box
                            key={type.id}
                            display="flex"
                            alignItems="center"
                            mb={1}
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={() =>
                              handleQuestionSelection(type.label, question.id)
                            }
                          >
                            <Icon />
                            <Box ml={1}>
                              <FdTypography>{type.label}</FdTypography>
                            </Box>
                          </Box>
                        );
                      })}
                    </Menu>
                    <Box ml={2}>
                      <FdButton
                        size="small"
                        variant="secondary"
                        onClick={(e) => handleDeleteQuestion(e, question.id)}
                      >
                        Delete
                      </FdButton>
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <DndProvider backend={HTML5Backend}>
                  {question.type === 'FREE_TEXT' ? (
                    <FreeTextQuestion
                      setShowCompetencyModal={setShowCompetencyModal}
                      isEdit={isEdit}
                      question={question.question}
                      selectedContexts={selectedContexts}
                      handleDeleteComp={handleDeleteComp}
                    />
                  ) : question.type === 'MULTIPLE_CHOICE' ? (
                    <MultipleChoiceQuestion
                      setShowCompetencyModal={setShowCompetencyModal}
                      isEdit={isEdit}
                      options={question.answers}
                      question={question.question}
                      selectedContexts={selectedContexts}
                    />
                  ) : question.type === 'SINGLE_CHOICE' ? (
                    <SingleChoiceQuestion
                      setShowCompetencyModal={setShowCompetencyModal}
                      isEdit={isEdit}
                      options={question.answers}
                      question={question.question}
                      selectedContexts={selectedContexts}
                    />
                  ) : question.type === 'ORDERING' ? (
                    <OrderingQuestion
                      setShowCompetencyModal={setShowCompetencyModal}
                      isEdit={isEdit}
                      options={question.answers}
                      question={question.question}
                      selectedContexts={selectedContexts}
                    />
                  ) : (
                    <FreeTextQuestion
                      setShowCompetencyModal={setShowCompetencyModal}
                      isEdit={isEdit}
                      question={question.question}
                      selectedContexts={selectedContexts}
                    />
                  )}
                </DndProvider>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <FdModal
          size="md"
          title={
            <Box>
              <span>Select Competency</span>
            </Box>
          }
          confirm="SAVE"
          dismiss="CANCEL"
          open={showCompetencyModal}
          onConfirm={() => {
            setShowCompetencyModal(undefined);
          }}
          onDismiss={() => {
            setShowCompetencyModal(undefined);
            setSelectedContexts([]);
          }}
        >
          <Box mt={2}>
            <SelectCompetency
              setSelectedClassification={setSelectedClassification}
              handleSelectedConcepts={handleSelectedConcepts}
              selectedConcepts={selectedConcepts}
              handleSelectedContexts={handleSelectedContexts}
              selectedContexts={selectedContexts}
              selectedCompetency={selectedCompetency}
              setSelectedCompetency={setSelectedCompetency}
              selectedClassification={selectedClassification}
            />
          </Box>
        </FdModal>
      </Box>
      <FdButton
        variant="secondary"
        fullWidth
        className={classes.button}
        onClick={handleAddQuestion}
      >
        ADD QUESTION
      </FdButton>
    </Box>
  );
};

export default AddQuizQuestions;
