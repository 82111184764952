import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FdAlert, FdTypography } from '@fifthdomain/fe-shared';
import SquadUsersTable from '../Fragments/SquadUsersTable';

const SquadUsers = ({
  squadId,
  allManagers,
  allUsers,
  onEdit,
  existingSquadMembersInOrg,
}) => {
  return (
    <Box mt={2} pr={1}>
      <FdAlert
        variant="info"
        message={
          <>
            You can add <strong>&nbsp;Function Lead&nbsp;</strong> to a squad in
            your Function only if they are not a part of another squad in
            another function.
          </>
        }
      />
      <Box mt={2}>
        <FdTypography variant="h4">Assign Squad Manager(s)</FdTypography>
        <FdTypography variant="captiontext1" color="secondary">
          The Squad Manager has the authority to view the skill profiles of all
          contributing members, including Squad Members and Managers, and to
          assign them training activities by designating appropriate work roles.
        </FdTypography>
      </Box>
      <SquadUsersTable
        allManagers={allManagers}
        existingSquadMembersInOrg={existingSquadMembersInOrg}
        squadId={squadId}
        onEdit={onEdit}
        isManagerView
      />
      <Box mt={3} mb={2}>
        <FdTypography variant="h4">Add Squad Member(s)</FdTypography>
        <SquadUsersTable
          allUsers={allUsers}
          existingSquadMembersInOrg={existingSquadMembersInOrg}
          squadId={squadId}
          onEdit={onEdit}
        />
      </Box>
    </Box>
  );
};

SquadUsers.propTypes = {
  onEdit: PropTypes.func,
  squadId: PropTypes.string.isRequired,
  allManagers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  existingSquadMembersInOrg: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

SquadUsers.defaultProps = {
  onEdit: undefined,
};

export default SquadUsers;
