import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useSnapshot,
  globalStore,
  FdLoadingSpinner,
  Authorization,
} from '@fifthdomain/fe-shared';

const ViewFunctionRoute = ({ component: Component, ...rest }) => {
  const { user, userType, loading, permissions } = useSnapshot(globalStore);
  const history = useHistory();

  const canManageFunctions = Authorization.canManageFunctions(permissions);
  const canManageSquads = Authorization.canManageSquads(permissions);

  if (loading || !permissions) return <FdLoadingSpinner />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          user &&
          userType !== 'PARTICIPANT' &&
          (canManageFunctions || canManageSquads)
        ) {
          return (
            <Component
              canManageFunctions={canManageFunctions}
              {...rest}
              {...props}
            />
          );
        }
        return history.goBack();
      }}
    />
  );
};

ViewFunctionRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ViewFunctionRoute;
