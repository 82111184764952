import { gql } from '@apollo/client';
import {
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { listAffiliationsWorkRoleByOrgId } from '../queries/customQueries';

const useGetUserWorkRoles = () => {
  const { orgId } = useSnapshot(globalStore);
  const { data: affiliationsWorkRolesData, loading: userWorkRolesLoading } =
    useQueryRecursive(gql(listAffiliationsWorkRoleByOrgId), {
      variables: {
        orgId,
        status: { eq: 'ACTIVE' },
      },
      skip: !orgId,
      staleTime: { minutes: 15 },
    });

  const userWorkRoles =
    affiliationsWorkRolesData?.listAffliationsByOrgId?.items?.map((wr) => ({
      userId: wr?.userId,
      workRole: wr?.customerWorkrole?.name,
    })) || [];

  return {
    userWorkRoles,
    userWorkRolesLoading,
  };
};

export default useGetUserWorkRoles;
