import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import * as singleSpa from 'single-spa';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, gql } from '@apollo/client';
import NavigationPrompt from 'react-router-navigation-prompt';
import {
  FdButton,
  BasePage,
  FdLoadingSpinner,
  FdModal,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { initialValues, validationSchema } from '../validation-schemas/Groups';
import { scrollToTop } from '../shared/utils/scroll';
import GroupDetails from '../components/Group/GroupDetails';
import GroupUsers from '../components/Group/GroupUsers';
import { createGroup, manageUserAndGroup } from '../graphql/mutations';
import { successToastMessage } from '../shared/utils/toast';
import { invalidateGroupQuery } from '../queries/invalidateQueries';

const useStyles = makeStyles()((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  stepper: {
    background: 'none',
    paddingLeft: '5px',
  },
}));

// TODO: Add 'Add Training Paths' to steps array later
const steps = ['Add Group Details', 'Add Users'];

const CreateGroup = () => {
  const [activeStep, setActiveStep] = useState(0);
  const history = useHistory();
  const { classes } = useStyles();
  const { orgId } = useSnapshot(globalStore);

  const [manageUserAndGroupMutation, { loading: manageUserAndGroupLoading }] =
    useMutation(gql(manageUserAndGroup), {
      onCompleted: () => {
        successToastMessage('Success! Group created');
        // eslint-disable-next-line no-use-before-define
        reset();
        // re-query list groups and invited users
        invalidateGroupQuery();
        singleSpa.navigateToUrl('/user-management/affiliated-users');
      },
    });

  const [createGroupMutation, { loading: createGroupLoading }] = useMutation(
    gql(createGroup),
    {
      onCompleted: async (data) => {
        const { id } = data?.createGroup;
        // eslint-disable-next-line no-use-before-define
        const values = getValues();
        const { users } = values;
        await manageUserAndGroupMutation({
          variables: {
            groupId: id,
            operation: 'ADDUSER',
            userIds: users,
          },
        });
      },
    },
  );

  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    control,
    formState: { isDirty },
    reset,
    getValues,
    trigger,
    handleSubmit,
  } = hookFormMethods;

  const onSubmit = async () => {
    const values = getValues();
    const { groupName, groupDescription } = values;
    await createGroupMutation({
      variables: {
        input: {
          name: groupName,
          description: groupDescription,
          orgId,
        },
      },
    });
  };

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['groupName', 'groupDescription']);
        break;
      }
      default:
        result = true;
        break;
    }
    return result;
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 1) {
        onSubmit();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const handleBack = () => {
    // on click cancel of first page go back to list page
    if (activeStep === 0) {
      singleSpa.navigateToUrl('/user-management/affiliated-users');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
    }
  };

  if (createGroupLoading || manageUserAndGroupLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <BasePage
      heading="Create Group"
      data-cy="create-group-base-page"
      breadCrumbs={[{ url: '/user-management', name: 'Home' }]}
      currentPageBreadcrumbLabel="User Management / Groups / Create Group"
      linkComponent={RouterLink}
    >
      <Box width="650px">
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <FormProvider {...hookFormMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {
            {
              0: <GroupDetails control={control} Controller={Controller} />,
              1: <GroupUsers />,
              // 2: <GroupCourses />, //Hidden for now sicne this its still under development
            }[activeStep]
          }
          <Box display="flex" justifyContent="space-between" pb={3}>
            <FdButton size="large" variant="secondary" onClick={handleBack}>
              {activeStep === 0 ? 'Cancel' : 'Back'}
            </FdButton>
            <Box>
              <FdButton size="large" variant="secondary" type="submit">
                Save
              </FdButton>
              {activeStep < 1 && (
                <FdButton
                  size="large"
                  onClick={handleNext}
                  style={{ marginLeft: '1rem' }}
                >
                  Next
                </FdButton>
              )}
            </Box>
          </Box>
        </form>
      </FormProvider>
      <NavigationPrompt
        when={isDirty}
        afterCancel={() => {
          if (window.location.pathname !== '/user-management/groups/create') {
            history.goBack();
          }
        }}
      >
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={onConfirm}
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};

export default CreateGroup;
