import { gql } from '@apollo/client';
import { useQueriesRecursive } from '@fifthdomain/fe-shared';
import { listUserSkillMetricsByUserId } from '../graphql/queries';

const useGetUserMetrics = ({ userIds }) => {
  const {
    refetch: refetchUserMetrics,
    data: userMetricsData,
    loading: userMetricsLoading,
  } = useQueriesRecursive(gql(listUserSkillMetricsByUserId), {
    variables: {
      limit: 1000,
    },
    skip: userIds?.length === 0,
    staleTime: { hours: 1 },
    recurringVariables: userIds?.map((u) => ({
      userId: u,
    })),
  });

  const userMetrics =
    userMetricsData?.listUserSkillMetricsByUserId?.items?.filter(
      (m) => m?.id,
    ) || [];

  return {
    refetchUserMetrics,
    userMetrics,
    userMetricsLoading,
  };
};

export default useGetUserMetrics;
