import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { addDays, format, isAfter } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  FdTab,
  FdTypography,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  BasePage,
  FdButton,
  FdAlert,
  FdSkeleton,
  PERMISSIONS_AFFILIATED,
  Authorization,
  FdExternalLink,
} from '@fifthdomain/fe-shared';
import {
  listAffliationRequestsByOrgId,
  listInvitedUsersByOrg,
} from '../graphql/queries';
import { sortObjectArrayByField } from '../shared/utils/objectUtils';
import { listGroupsByOrgId } from '../queries/customQueries';
import ManageGroups from '../components/Affiliated/ManageGroups';
import AffiliatedUsersTable from '../components/Affiliated/AffiliatedUsersTable';
import InviteAffiliatedUsersModal from '../components/Affiliated/InviteAffiliatedUsersModal';
import { capitalize, snakeCaseToTitleCase } from '../shared/utils/stringUtils';
import FdTextView from '../components/FdTextView';

const UsersAffiliated = () => {
  const globalSnap = useSnapshot(globalStore);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const { orgId, orgPricingTier, permissions, orgMaxInvitees } = globalSnap;

  const {
    data: affiliatedRequestsData,
    loading: affiliatedRequestsLoading,
    refetch: refetchAffiliatedRequestsData,
  } = useQueryRecursive(gql(listAffliationRequestsByOrgId), {
    variables: {
      orgId,
      limit: 500,
    },
    skip: !orgId,
  });
  const { data: groupsData, loading: groupsLoading } = useQueryRecursive(
    gql(listGroupsByOrgId),
    {
      variables: {
        orgId: globalSnap?.orgId,
      },
      skip: !globalSnap?.orgId,
    },
  );

  const {
    data: listInvitedUsersByOrgIdData,
    loading: listInvitedUsersByOrgIdLoading,
  } = useQueryRecursive(gql(listInvitedUsersByOrg), {
    variables: {
      orgId,
      filter: { accepted: { eq: false } },
    },
  });

  const invitedUser =
    listInvitedUsersByOrgIdData?.listInvitedUsersByOrg?.items || [];
  const refetchQueries = () => {
    refetchAffiliatedRequestsData();
  };

  const canManageUsers = Authorization.canManageUsers(permissions);

  const allAffiliatedUsers =
    affiliatedRequestsData?.listAffliationRequestsByOrgId?.items
      ?.filter((u) => !['DISAFFILIATED', 'REVOKED'].includes(u?.status))
      ?.map((u) => {
        const userEvents = u?.user?.assessments?.items?.map((assessment) => ({
          id: assessment?.assessment?.id,
          name: assessment?.assessment?.name,
          type: assessment?.assessment?.participantEventType,
          isProgress: assessment?.status !== 'NOT_STARTED',
          orgId: assessment?.assessment?.orgId,
        }));

        const courses = u?.user?.courses?.items
          ?.filter(
            ({ course }) =>
              course?.status === 'AVAILABLE' && course?.availability,
          )
          ?.map((course) => ({
            id: course.courseId,
            name: course.course?.name,
            availability: course.course?.availability,
            status: course?.course?.status,
            orgId: course?.course?.orgId,
            isProgress:
              course?.course?.modulePartProgresses?.items?.filter(
                (item) => item?.userId === u?.userId,
              )?.length > 0,
          }));

        const groups =
          u?.user?.groups?.items?.map((group) => ({
            id: group.groupId,
            name: group.group?.name,
          })) || [];

        return {
          id: u?.id,
          inviteToken:
            invitedUser?.filter((i) => i?.email?.includes(u?.email))[0]?.id ||
            '-',
          name: u?.user?.name,
          email: u?.email,
          userId: u?.userId,
          alias: u?.user?.alias,
          registrationType:
            u?.userId !== 'UNDEFINED' ? 'Registered' : 'Invited',
          assignedEvents: {
            assessments: userEvents?.filter(
              (event) => event.type === 'ASSESSMENT',
            ),
            competitions: userEvents?.filter(
              (event) => event.type === 'COMPETITION',
            ),
            courses,
          },
          groups,
          permissions: u?.user?.permissions?.map((p) =>
            snakeCaseToTitleCase(p === 'CREATE' ? 'Create Content' : p),
          ),
          affiliatedStatus:
            u?.status === 'ACCEPTED'
              ? 'Confirmed'
              : u?.status === 'PENDING' &&
                  !isAfter(addDays(new Date(u?.updatedAt), 30), new Date())
                ? 'Expired'
                : capitalize(u?.status),
          affiliatedDate:
            u?.status === 'ACCEPTED'
              ? format(new Date(u?.updatedAt), 'dd/MM/yyyy')
              : '',
        };
      }) || [];

  // only user with permission manage-groups
  const canManageGroups =
    (orgPricingTier !== 'STARTER' &&
      Authorization.hasPermission(permissions, [
        PERMISSIONS_AFFILIATED.MANAGE_GROUPS,
      ])) ||
    false;

  const groups =
    groupsData?.listGroupsByOrgId?.items
      ?.map((g) => ({
        id: g.id,
        name: g.name,
      }))
      ?.sort(sortObjectArrayByField('name', 'asc')) || [];
  const loading = affiliatedRequestsLoading || listInvitedUsersByOrgIdLoading;

  const canInviteUsers = orgMaxInvitees
    ? orgMaxInvitees > allAffiliatedUsers?.length
    : true;

  return (
    <BasePage
      data-cy="affiliated-users-page"
      breadCrumbs={[{ url: '/landing/landing-homepage', name: 'Home' }]}
      currentPageBreadcrumbLabel="Users / Affiliated"
      linkComponent={RouterLink}
      renderBreadCrumbAsButton
    >
      <FdCard variant="outlined">
        <Box className="flex justify-between mb-4">
          <FdTypography variant="h3">Affiliated Users</FdTypography>
          {canManageUsers && (
            <Box display="flex">
              {!canInviteUsers && (
                <FdTextView
                  mr={1}
                  tooltip={`You have already used ${orgMaxInvitees}/${orgMaxInvitees} affiliated user invitations available on the Consultant pricing tier plan. To invite more affiliated users, current users will need to be disaffiliated first.`}
                  noHeading
                />
              )}
              <FdSkeleton
                loading={groupsLoading || loading}
                height={32}
                width={220}
              >
                <FdButton
                  disabled={!canInviteUsers}
                  onClick={() => setShowInviteModal(true)}
                  data-cy="invite-button"
                >
                  invite affiliated user(s)
                </FdButton>
              </FdSkeleton>
            </Box>
          )}
        </Box>
        <FdTypography variant="body2" color="secondary">
          Affiliated users are linked to your organisation and can be granted
          &apos;Participate&apos; permissions for events across all
          organisations, as well as different manager permissions specific to
          the organisation they are affiliated with. Your organisation&apos;s
          managers have access to comprehensive skills insights for these users,
          including skills acquired from events across various organisations,
          not limited to your own. Refer to this
          <FdExternalLink
            href="https://au.intercom.help/fifth-domain/en/articles/13431-managing-affiliation-of-other-affiliated-users-in-your-organisation"
            noUnderline
          >
            article
          </FdExternalLink>
          to learn more.
        </FdTypography>
        <FdTab
          label={[
            {
              label: 'All Individuals',
              index: 0,
              data: (
                <Box>
                  <FdAlert
                    variant="info"
                    message="Under this tab, you will find all the affiliated users in your organisation. When you invite users from this tab, you can assign extra manager permissions, or edit them after the invitation is sent."
                  />
                  <Box mt={1}>
                    <FdSkeleton loading={loading} height={530}>
                      <AffiliatedUsersTable
                        canManageUsers={canManageUsers}
                        rows={allAffiliatedUsers}
                        refetchQueries={refetchQueries}
                      />
                    </FdSkeleton>
                  </Box>
                </Box>
              ),
            },
            {
              label: 'Participants',
              index: 1,
              data: (
                <Box>
                  <FdAlert
                    variant="info"
                    message="Under this tab, you can view all the affiliated users with only “Participate” permissions. You can edit the permissions of these users from the “All Individuals” tab."
                  />
                  <Box mt={1}>
                    <FdSkeleton loading={loading} height={530}>
                      <AffiliatedUsersTable
                        rows={allAffiliatedUsers}
                        participantsOnly
                      />
                    </FdSkeleton>
                  </Box>
                </Box>
              ),
            },
            ...(canManageGroups
              ? [
                  {
                    label: 'Groups',
                    index: 2,
                    data: <ManageGroups />,
                  },
                ]
              : [{}]),
          ]}
        />
      </FdCard>
      <InviteAffiliatedUsersModal
        allGroups={groups}
        showModal={showInviteModal}
        setShowModal={setShowInviteModal}
        refetchQueries={refetchQueries}
        allAffiliatedUsers={allAffiliatedUsers}
      />
    </BasePage>
  );
};
export default UsersAffiliated;
