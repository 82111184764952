import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { FdTypography, FdSkeleton } from '@fifthdomain/fe-shared';
import NoSquadIcon from './icons/NoSquadIcon';
import SquareButton from './Fragments/SquareButton';
import SquadAccordion from './Fragments/SquadAccordion';
import CreateSquad from './Create/CreateSquad';
import EditSquad from './Edit/EditSquad';
import { getTop3Skills } from './utils';

const NoSquads = () => (
  <Box
    className="flex flex-col items-center justify-center gap-y-6"
    height={400}
  >
    <NoSquadIcon />
    <FdTypography
      variant="body2"
      color="secondary"
      sx={{ textAlign: 'center', width: '300px' }}
    >
      Start creating squads by clicking the button below to meet the target
      requirements displayed on the Cyber Skills Cortex.
    </FdTypography>
  </Box>
);

const SquadList = ({
  canCreate,
  loading,
  users,
  existingSquadMembersInOrg,
  refetchFunction,
  userMetrics,
}) => {
  const [showCreateSquad, setShowCreateSquad] = useState(false);
  const [showEditSquad, setShowEditSquad] = useState(null);
  const { control } = useFormContext();
  const { fields: squads } = useFieldArray({
    control,
    name: 'squads',
  });
  const noSquads = squads?.length === 0;
  const squadIdx = (squads?.length || 0) + 1;
  const topSkills = getTop3Skills(userMetrics);
  const usersWithSkills = users?.map((u) => ({
    ...u,
    topSkills: topSkills?.find((s) => s?.userId === u?.id)?.skills || [],
  }));

  return (
    <Box className="px-6">
      <FdTypography variant="h4">Squads</FdTypography>
      {canCreate && (
        <Box mt={2}>
          <FdTypography variant="captiontext1" color="secondary">
            Create and manage squads to meet the function&apos;s target. Add
            users to squads, and their skills will fill the corresponding
            squares on the Skills Cortex, updating the readiness score in
            real-time. Note: Checkboxes only filter the Cyber Skills Cortex view
            and do not alter squad composition.
          </FdTypography>
        </Box>
      )}
      <FdSkeleton loading={loading} height={400}>
        {squads?.map((__, idx) => (
          <SquadAccordion
            idx={idx}
            onEditClick={setShowEditSquad}
            canEdit={canCreate}
          />
        ))}
      </FdSkeleton>
      {!canCreate && noSquads && (
        <Box mt={2}>
          <FdTypography variant="captiontext1" color="secondary">
            No Squads Available
          </FdTypography>
        </Box>
      )}
      {canCreate && (
        <Box className="flex flex-col justify-center items-center">
          {noSquads && !loading && <NoSquads />}
          <SquareButton
            label="CREATE A SQUAD"
            onClick={() => {
              setShowCreateSquad(true);
            }}
          />
        </Box>
      )}
      <CreateSquad
        openModal={showCreateSquad}
        setOpenModal={setShowCreateSquad}
        users={usersWithSkills}
        existingSquadMembersInOrg={existingSquadMembersInOrg}
        refetchFunction={refetchFunction}
        squadIdx={squadIdx}
      />
      <EditSquad
        openModal={showEditSquad}
        setOpenModal={setShowEditSquad}
        users={usersWithSkills}
        existingSquadMembersInOrg={existingSquadMembersInOrg}
        refetchFunction={refetchFunction}
      />
    </Box>
  );
};

SquadList.defaultProps = {
  canCreate: false,
};

SquadList.propTypes = {
  canCreate: PropTypes.bool,
  refetchFunction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  existingSquadMembersInOrg: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userMetrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SquadList;
