import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  FdTypography,
  FdExternalLink,
  FdRadioGroup,
  FdTextField,
  FdModal,
  TAG_MANAGEMENT_URL,
} from '@fifthdomain/fe-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { TAG_TYPE, TAG_COLOUR } from '../../constants';
import { validationSchema } from '../../validation-schemas/TagDirectory';

const useStyles = makeStyles()(() => ({
  selectedTag: {
    border: '2px solid #BDBDBD',
    padding: '4px',
    borderRadius: '50%',
  },
}));

const CreateTag = ({
  openModal,
  setOpenModal,
  isEdit,
  onConfirm,
  onDismiss,
  tagDetails,
}) => {
  const { classes } = useStyles();
  const reactHookFormMethods = useForm({
    defaultValues: tagDetails,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { control, getValues, setValue, trigger, reset } = reactHookFormMethods;

  useEffect(() => {
    reset(tagDetails);
  }, [tagDetails, reset]);

  return (
    <FormProvider {...reactHookFormMethods}>
      <form style={{ width: '100%' }}>
        <FdModal
          size="md"
          title={isEdit ? 'Edit Tag' : 'Create New Tag'}
          description={
            !isEdit &&
            ' Create a tag that can be applied and/or removed to users and challenges.'
          }
          confirm={isEdit ? 'Save' : 'Create'}
          dismiss="CANCEL"
          open={openModal}
          onConfirm={async () => {
            const result = await trigger();
            // submit confirm with values
            if (result) {
              const { tagName, tagDescription, tagType, tagColor, id } =
                getValues();
              onConfirm({
                tagName,
                tagDescription,
                tagType,
                tagColor,
                id,
              });
            }
          }}
          onDismiss={() => {
            reset();
            setOpenModal(false);
            onDismiss();
          }}
          data-cy="open-modal"
        >
          <Box className="flex flex-col w-full">
            <Box my={1}>
              <FdTypography variant="subtitle1">
                Tag Management Guidelines
              </FdTypography>
              <FdTypography variant="body2" color="secondary">
                For guidance on managing tags, refer to the FifthDomain Tag
                Management Guidelines below.
              </FdTypography>
              <FdExternalLink
                href={TAG_MANAGEMENT_URL}
                style={{ margin: '1rem 0' }}
              >
                Tag Management Guidelines
              </FdExternalLink>
            </Box>
            <Box mt={1}>
              <Controller
                control={control}
                name="tagName"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mt={1} mb={2}>
                    <FdTextField
                      id="tagName"
                      label="Tag Name"
                      required
                      fullWidth
                      error={error}
                      helperText={error?.message}
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
              <Box mt={3} mb={3}>
                <Controller
                  control={control}
                  name="tagDescription"
                  render={({
                    field: { ref, ...rest },
                    fieldState: { error },
                  }) => (
                    <Box mb={2}>
                      <FdTextField
                        id="tagDescription"
                        label="Tag Description"
                        fullWidth
                        multiline
                        rows={3}
                        error={error}
                        helperText={error?.message}
                        {...rest}
                        inputRef={ref}
                      />
                    </Box>
                  )}
                />
              </Box>
              {!isEdit && (
                <Box>
                  <Controller
                    control={control}
                    name="tagType"
                    render={({
                      field: { ref, ...rest },
                      fieldState: { error },
                    }) => (
                      <Box mt={1} mb={2}>
                        <Box mb={1}>
                          <FdTypography variant="subtitle1">
                            Tag Type
                          </FdTypography>
                        </Box>
                        <FdTypography variant="body2" color="secondary">
                          Tag type cannot be edited upon tag creation.
                        </FdTypography>
                        <Box mt={1} ml={2}>
                          <FdRadioGroup
                            id="tagType"
                            options={[
                              TAG_TYPE.CHALLENGE,
                              // TAG_TYPE.USER
                            ]}
                            fullWidth
                            error={error && error?.value?.message}
                            data-cy="tagType"
                            {...rest}
                            inputRef={ref}
                          />
                        </Box>
                      </Box>
                    )}
                  />
                </Box>
              )}
              <Controller
                control={control}
                name="tagColor"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mt={1}>
                    <FdTypography variant="subtitle1">Tag Colour</FdTypography>
                    <Box
                      display="flex"
                      flexDirection="row"
                      mt={1}
                      mb={2}
                      alignItems="baseline"
                    >
                      {TAG_COLOUR?.map((tagColour) => {
                        return (
                          <Box
                            mr={3}
                            className={
                              rest.value === tagColour.value
                                ? classes.selectedTag
                                : null
                            }
                          >
                            <IconButton
                              id="tagColor"
                              style={{
                                height: '40px',
                                borderRadius: '50%',
                                width: '40px',
                                backgroundColor: tagColour.backgroundColor,
                              }}
                              error={error && error.message}
                              data-cy="tagType"
                              {...rest}
                              inputRef={ref}
                              onClick={() => {
                                setValue('tagColor', tagColour.value);
                              }}
                              size="large" />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                )}
              />
            </Box>
          </Box>
        </FdModal>
      </form>
    </FormProvider>
  );
};

CreateTag.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  tagDetails: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
};

CreateTag.defaultProps = {
  isEdit: false,
  tagDetails: {},
};

export default CreateTag;
