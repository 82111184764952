import { gql } from '@apollo/client';
import {
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { listSquadsByOrgId } from '../queries/customQueries';

const useGetSquads = () => {
  const { orgId } = useSnapshot(globalStore);
  const {
    refetch: refetchSquads,
    data: squadsData,
    loading: squadsLoading,
  } = useQueryRecursive(gql(listSquadsByOrgId), {
    variables: {
      orgId,
      filter: { status: { eq: 'ACTIVE' } },
    },
    skip: !orgId,
    staleTime: { seconds: 0 },
  });
  const squads = squadsData?.listSquadsByOrgId?.items || [];

  return {
    refetchSquads,
    squads,
    squadsLoading,
  };
};

export default useGetSquads;
