import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { gql, useMutation } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  TablePagination,
  Link,
  IconButton,
  Divider,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { FdTypography, FdButton, FdModal } from '@fifthdomain/fe-shared';
import {
  successToastMessage,
  warningToastMessage,
} from '../../../shared/utils/toast';
import {
  deleteCustomerWorkroleFunction,
  updateCustomerWorkroleFunction,
} from '../../../graphql/mutations';
import { onError } from '../utils';
import { PROFICIENCY, PROFICIENCY_LEVELS } from '../../../constants';
import TargetsCortex from '../Cortex/TargetsCortex';

const onUnitUpdate = async ({
  operator,
  value,
  id,
  valueSetter,
  trigger,
  onCompleted,
}) => {
  const _value = Number(value) || null;
  const unitsId = `${id}.units`;
  if (operator === 'add') {
    valueSetter(unitsId, _value + 1);
  } else if (value > 1) {
    valueSetter(unitsId, _value - 1);
  }
  const result = await trigger(unitsId);
  if (result) {
    onCompleted(id);
  }
};

const WorkRoleGroups = ({ data }) => {
  return data?.map((g) => (
    <Box my={3}>
      <FdTypography variant="body2" sx={{ fontWeight: 600 }}>
        {g?.group}:
      </FdTypography>
      {g?.items?.map((s) => (
        <Box className="flex flex-col my-2">
          <Box className="flex gap-x-2">
            <FdTypography variant="body2">{s?.skillName}</FdTypography>
            <FdTypography variant="body2" sx={{ fontWeight: 600 }}>
              ({s?.skillAlias})
            </FdTypography>
          </Box>
          <Box
            className="flex justify-center rounded mt-1 px-2 py"
            sx={{
              backgroundColor: PROFICIENCY[s?.proficiency]?.color,
              width: 'max-content',
            }}
          >
            <FdTypography variant="captiontext1" style={{ fontWeight: 500 }}>
              {s?.proficiency}
            </FdTypography>
          </Box>
        </Box>
      ))}
    </Box>
  ));
};

const WorkRoleRow = ({
  workRoleIndex,
  label,
  removeWorkRole,
  setWorkRoleCortex,
  onCompleted,
  disableRemove,
}) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext();
  const id = `workRoles[${workRoleIndex}]`;
  const value = watch(`${id}.units`);
  const isError = errors?.workRoles?.[workRoleIndex]?.units;

  return (
    <Box className="grid grid-cols-3 items-center my-2">
      <Box>
        <Link color="inherit" onClick={() => setWorkRoleCortex(workRoleIndex)}>
          <FdTypography variant="subtitle1">{label}</FdTypography>
        </Link>
      </Box>
      <Box className="flex flex-col items-center justify-center">
        <Box className="flex items-center gap-x-2">
          <IconButton
            sx={{ bgcolor: 'rgba(217, 217, 217, 1)', color: 'black' }}
            size="small"
            onClick={() =>
              onUnitUpdate({
                operator: 'subtract',
                value,
                id,
                valueSetter: setValue,
                trigger,
                onCompleted,
              })
            }
          >
            <RemoveIcon />
          </IconButton>
          <Controller
            control={control}
            name={`workRoles[${workRoleIndex}].units`}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <TextField
                id={`workRoles-${workRoleIndex}`}
                sx={{ width: 64 }}
                inputProps={{
                  sx: {
                    textAlign: 'center',
                  },
                }}
                error={error}
                {...rest}
                inputRef={ref}
              />
            )}
          />
          <IconButton
            sx={{ bgcolor: 'rgba(217, 217, 217, 1)', color: 'black' }}
            size="small"
            onClick={() =>
              onUnitUpdate({
                operator: 'add',
                value,
                id,
                valueSetter: setValue,
                trigger,
                onCompleted,
              })
            }
          >
            <AddIcon />
          </IconButton>
        </Box>
        {isError && (
          <FdTypography variant="captiontext1" color="rgba(198, 40, 40, 1)">
            Work role units must be greater than or equal to 1.
          </FdTypography>
        )}
      </Box>
      <Box width="50px">
        <FdButton
          variant="text"
          disabled={disableRemove}
          onClick={() => removeWorkRole(workRoleIndex)}
        >
          Remove
        </FdButton>
      </Box>
    </Box>
  );
};

WorkRoleRow.propTypes = {
  workRoleIndex: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  removeWorkRole: PropTypes.func.isRequired,
  setWorkRoleCortex: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
  disableRemove: PropTypes.bool.isRequired,
};

const WorkRolesTable = ({ removeWorkRole, refetchFunction }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [workRoleToBeRemoved, setWorkRoleToBeRemoved] = useState(null);
  const [workRoleCortex, setWorkRoleCortex] = useState(null);
  const { watch, getValues } = useFormContext();
  const workRoles = watch('workRoles')?.map((wr, i) => ({ ...wr, slno: i }));

  const [deleteUserFunctionMutation, { loading: deleteUserFunctionLoading }] =
    useMutation(gql(deleteCustomerWorkroleFunction), {
      onError,
    });

  const [updateCustomerWorkRoleFunctionMutation] = useMutation(
    gql(updateCustomerWorkroleFunction),
    {
      onError,
    },
  );

  const handleChangePage = (__, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const rows = workRoles.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );
  const workRolesUnits = workRoles?.reduce((acc, workRole) => {
    return acc + (workRole?.units || 0);
  }, 0);
  const workRoleCortexData =
    workRoleCortex !== null ? workRoles[workRoleCortex] : null;

  const targets =
    workRoleCortexData?.skills
      ?.map((s) => ({ ...s, units: workRoleCortexData?.units }))
      ?.flat()
      ?.map((s) =>
        s?.skills?.items
          ?.map((si) => ({
            level: s?.difficulty,
            skillAlias: si?.skill?.alias,
            skillName: si?.skill?.name,
            specialty: si?.skill?.specialty?.name,
            units: s?.units,
          }))
          .flat(),
      )
      .flat() || [];
  const grouped = _.mapValues(
    _.groupBy(targets, 'skillAlias'),
    (items) => _.maxBy(items, 'level').level,
  );
  const fillers = _.flatMap(grouped, (maxLevel, skillAlias) =>
    _.times(maxLevel - 1, (i) => ({
      level: i + 1,
      skillAlias,
    })),
  );
  const cortexData = {
    targets,
    fillers,
  };
  const specialtyList = _.flatMap(
    _.groupBy(targets, 'specialty'),
    (items, group) => ({
      group,
      items: _.uniqBy(
        items?.map((s) => ({
          skillName: s?.skillName,
          skillAlias: s?.skillAlias,
          proficiency: PROFICIENCY_LEVELS[s?.level],
        })),
        (i) => `${i.skillAlias}-${i.proficiency}`,
      ),
    }),
  );

  const onUnitsUpdate = (id) => {
    const { pkId, units } = getValues(id) || {};
    if (pkId && units > 0) {
      updateCustomerWorkRoleFunctionMutation({
        variables: {
          input: {
            id: pkId,
            workRoleUnits: units,
          },
        },
        onCompleted: () => {
          successToastMessage('Success! Work role units updated');
          refetchFunction();
        },
      });
    }
  };

  return (
    <Box className="flex flex-col">
      <FdTypography variant="captiontext1" color="secondary">
        {`Added: ${workRoles.length} unique work roles, ${workRolesUnits} work role units`}
      </FdTypography>
      <Box minHeight={328}>
        {rows?.map((workRole) => (
          <WorkRoleRow
            workRoleIndex={workRole.slno}
            label={workRole.workRoleName}
            removeWorkRole={setWorkRoleToBeRemoved}
            setWorkRoleCortex={setWorkRoleCortex}
            onCompleted={onUnitsUpdate}
            disableRemove={
              workRoles?.length === 1 && getValues('status') === 'ACTIVE'
            }
          />
        ))}
      </Box>
      <Box>
        <Divider />
        <TablePagination
          component="div"
          count={workRoles.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 15, 20]}
        />
      </Box>
      <FdModal
        size="md"
        title="Remove Work Role?"
        description="Removing the work role will remove all the quantities selected for the this work role from the function target state. You can always add it again."
        confirm="REMOVE"
        dismiss="CANCEL"
        open={workRoleToBeRemoved !== null}
        onConfirm={() => {
          const pkId = getValues(`workRoles[${workRoleToBeRemoved}]`)?.pkId;
          const showToast = () =>
            successToastMessage(
              "Work Role removed from the function's target state.",
            );
          removeWorkRole(workRoleToBeRemoved); // only from FE
          setWorkRoleToBeRemoved(null);

          if (!pkId) {
            showToast();
            return;
          }
          // delete from db
          deleteUserFunctionMutation({
            variables: {
              input: {
                id: pkId,
              },
            },
            onCompleted: () => {
              showToast();
              refetchFunction();
            },
          });
        }}
        onDismiss={() => {
          setWorkRoleToBeRemoved(null);
          warningToastMessage('Work Role not removed.');
        }}
        disableConfirm={deleteUserFunctionLoading}
      />
      <FdModal
        size="xl"
        title=""
        dismiss="CLOSE"
        open={workRoleCortex !== null}
        showConfirm={false}
        onDismiss={() => setWorkRoleCortex(null)}
        hideHeader
      >
        <Box mt={2}>
          <FdTypography variant="h3">
            {workRoleCortexData?.workRoleName}
          </FdTypography>
          <FdTypography variant="body2">
            {`Current selected quantity: ${workRoleCortexData?.units}`}
          </FdTypography>
          <Box className="flex mt-4">
            <Box className="text-center">
              <FdTypography variant="subtitle1">
                Cyber Skills Cortex of the Work Role
              </FdTypography>
              <Box mt={1}>
                <TargetsCortex data={cortexData} />
              </Box>
            </Box>
            <Box>
              <FdTypography variant="subtitle2" color="secondary">
                Skill-Proficiency required for this Work Role:
              </FdTypography>
              <Box maxHeight={534} sx={{ overflowY: 'auto' }}>
                <WorkRoleGroups data={specialtyList} />
              </Box>
            </Box>
          </Box>
        </Box>
      </FdModal>
    </Box>
  );
};

WorkRolesTable.defaultProps = {
  refetchFunction: () => null,
};

WorkRolesTable.propTypes = {
  refetchFunction: PropTypes.func,
  removeWorkRole: PropTypes.func.isRequired,
};

export default WorkRolesTable;
