import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdTextField,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import FunctionRowCard from './Fragments/FunctionRowCard';
import WorkforceIcon from './icons/WorkforceIcon';
import FunctionIcon from './icons/FunctionIcon';

const FunctionList = ({ data, isActive, userMetrics, canManageFunctions }) => {
  const { getValues } = useFormContext();
  const { userId } = useSnapshot(globalStore);
  const [searchText, setSearchText] = useState('');
  const displayData = searchText
    ? data?.filter((fn) =>
        fn?.name?.toLowerCase().includes(searchText?.toLowerCase()),
      )
    : data;
  const functionCount = displayData?.length || 0;
  const allFunctions = getValues('allFunctions');
  const mySquad = allFunctions
    ?.filter(
      (af) =>
        !af?.functionLeads?.items?.map((fl) => fl?.userId)?.includes(userId),
    )
    ?.map((af) => af?.squads)
    ?.flat()
    ?.find((s) =>
      s?.members?.items?.some(
        (sm) =>
          sm?.status === 'ACTIVE' &&
          sm?.role === 'MANAGER' &&
          sm?.userId === userId,
      ),
    );

  return (
    <Box>
      <Box mt={2} mb={3}>
        <FdTextField
          placeholder="Search for Functions"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          fullWidth
        />
      </Box>
      <Box className="flex items-center gap-x-2 my-4">
        <WorkforceIcon />
        <FdTypography variant="subtitle1">
          {`${isActive ? 'Active' : 'Inactive'} Functions (${functionCount})`}
        </FdTypography>
      </Box>
      <Box sx={{ overflowY: 'auto', maxHeight: '680px' }}>
        {displayData?.length > 0 ? (
          displayData?.map((fn) => {
            const allMembers =
              fn?.squads
                ?.map((s) =>
                  s?.members?.items
                    .filter((m) => m?.status === 'ACTIVE')
                    ?.map((m) => m?.userId),
                )
                ?.flat() || [];
            const selectedUserMetrics =
              userMetrics?.filter((m) => allMembers.includes(m?.userId)) || [];

            return (
              <FunctionRowCard
                fn={fn}
                inActive={!isActive}
                userMetrics={selectedUserMetrics}
              />
            );
          })
        ) : (
          <Box
            sx={{
              borderRadius: '8px',
              backgroundColor: '#F9FAFB',
              padding: '8px 16px',
            }}
          >
            <FdTypography variant="body1" className="text-center">
              No Functions found
            </FdTypography>
          </Box>
        )}
      </Box>
      {isActive && mySquad && !canManageFunctions && (
        <Box>
          <Box className="flex items-center gap-x-2 my-4">
            <FunctionIcon />
            <FdTypography variant="subtitle1">Your Squad</FdTypography>
          </Box>
          <FunctionRowCard
            isYourSquad
            fn={{
              id: mySquad?.id,
              name: mySquad?.name,
              mySquadMemberCount: mySquad?.members?.items?.length || 0,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

FunctionList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isActive: PropTypes.bool.isRequired,
  userMetrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  canManageFunctions: PropTypes.bool.isRequired,
};

export default FunctionList;
