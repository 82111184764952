import Yup from '../Common';

export const initialValues = {
  functionId: '',
  functionName: '',
  functionDescription: '',
  status: 'INACTIVE',
  workRoles: [],
  workRolesSelection: [], // placeholder for FE validation
  functionLeadsSelection: [], // placeholder for FE validation
  functionLeads: [],
  squads: [],
  existingSquadMembers: [],
};

export const getValidationSchema = ({ functionNames, squadNames }) =>
  Yup.object().shape({
    functionId: Yup.string(),
    functionName: Yup.string()
      .required('Please enter a Function Name')
      .max(50, 'Function Name cannot be more than 50 characters.')
      .noWhitespace('Please enter a Function Name')
      .test(
        'unique-test',
        'Function name already taken. Please choose a different name.',
        (value) => !functionNames?.includes(value?.trim()?.toLowerCase()),
      ),
    functionDescription: Yup.string()
      .max(80, 'Function Description can only have 80 characters')
      .test(
        'noWhitespaceNonRequired',
        'Please enter a valid Function Description',
        (value) => !value || (value && value.trim().length),
      ),
    status: Yup.string(),
    workRolesSelection: Yup.array()
      .of(Yup.string())
      .test(
        'minimum-test',
        'Please add at least one work role to make this function active.',
        (__, context) => context.parent.workRoles.length > 0,
      ),
    workRoles: Yup.array()
      .of(
        Yup.object().shape({
          pkId: Yup.string(),
          workRoleId: Yup.string(),
          workRoleName: Yup.string(),
          units: Yup.number()
            .min(1, 'Work role units must be greater than or equal to 1.')
            .required(),
        }),
      )
      .min(1)
      .required(),
    functionLeadsSelection: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
          email: Yup.string(),
        }),
      )
      .min(1, 'Please select at least one Function lead.')
      .required('Please select at least one Function lead.'),
    functionLeads: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          name: Yup.string(),
          email: Yup.string(),
        }),
      )
      .min(1, 'Please select at least one Function lead.')
      .required('Please select at least one Function lead.'),
    squads: Yup.array().of(
      Yup.object().shape({
        squadId: Yup.string(),
        squadName: Yup.string()
          .required('Please enter a Squad Name')
          .max(50, 'Squad Name cannot be more than 50 characters.')
          .noWhitespace('Please enter a Squad Name')
          .test(
            'unique-test',
            'Squad name already taken. Please choose a different name.',
            (value) => !squadNames?.includes(value?.toLowerCase()),
          ),
        squadDescription: Yup.string()
          .max(80, 'Squad Description can only have 80 characters')
          .nullable()
          .test(
            'noWhitespaceNonRequired',
            'Please enter a valid Squad Description',
            (value) => !value || (value && value.trim().length),
          ),
        squadMembersSelection: Yup.array().of(
          Yup.object().shape({
            name: Yup.string(),
            userId: Yup.string(),
          }),
        ),
        squadMembers: Yup.array().of(
          Yup.object().shape({
            pkId: Yup.string(),
            id: Yup.string(),
            name: Yup.string(),
            email: Yup.string(),
            userId: Yup.string(),
            selected: Yup.bool(),
          }),
        ),
        squadManagersSelection: Yup.array()
          .of(
            Yup.object().shape({
              name: Yup.string(),
              userId: Yup.string(),
            }),
          )
          .test(
            'minimum-test',
            'Please select at least one Squad Manager to create a squad.',
            (__, context) => context.parent.squadManagers?.length > 0,
          ),
        squadManagers: Yup.array()
          .of(
            Yup.object().shape({
              pkId: Yup.string(),
              id: Yup.string(),
              name: Yup.string(),
              email: Yup.string(),
              userId: Yup.string(),
              selected: Yup.bool(),
            }),
          )
          .min(1)
          .required(),
        deletedUsers: Yup.array().of(Yup.string()),
      }),
    ),
  });
