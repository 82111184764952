import React from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, TextField, FormHelperText } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import {
  FdModal,
  FdAutocompleteStatic,
  FdTypography,
} from '@fifthdomain/fe-shared';
import useGetUsers from '../../../hooks/useGetUsers';

const AssignFunctionLeads = ({ show, setShow, isCreate, onConfirm }) => {
  const { users } = useGetUsers({ permission: 'MANAGE_SQUADS' });
  const { control, trigger, watch, getValues, reset } = useFormContext();
  const existingUsers = getValues('functionLeads')?.map((fl) => fl?.id);
  const usersForDisplay = isCreate
    ? users
    : users?.filter(({ id }) => !existingUsers.includes(id));
  const watchFunctionLeads = watch(
    isCreate ? 'functionLeads' : 'functionLeadsSelection',
  );
  const options = usersForDisplay?.map(({ id, name }) => ({ id, name })) || [];

  return (
    <FdModal
      size="lg"
      title={`Assign Function Lead(s) ${
        isCreate ? 'and activate the Function.' : ''
      }`}
      description={
        <>
          Every active function must have at least one Function Lead assigned.
          The appointed Function Lead holds the power to edit the target work
          roles for the function, and add or remove other leads.
          <br />
          <br />
          The Function Lead must be chosen from the pool of affiliated users
          within the organisation who possess the &quot;Manage Squads&quot;
          permission.
          <Box className="mt-3">
            <FdTypography variant="subtitle1">
              Select Function Lead(s) from the dropdown list.
            </FdTypography>
            <FdTypography variant="body2" color="secondary">
              {`${watchFunctionLeads?.length || 0} selected`}
            </FdTypography>
            <Box mt={1} mb={2}>
              <Controller
                control={control}
                name={isCreate ? 'functionLeads' : 'functionLeadsSelection'}
                render={({
                  field: { ref, onChange, value: fieldValue, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <Box>
                      <FdAutocompleteStatic
                        {...rest}
                        inputRef={ref}
                        id="select-function-leads"
                        options={options}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                        renderInput={(params) => {
                          return (
                            <Box className="flex items-center mr-2">
                              <TextField
                                {...params}
                                placeholder="Use checkboxes to select Function Lead(s)"
                                error={error}
                              />
                            </Box>
                          );
                        }}
                        renderOption={(props, option, { selected }) => {
                          const { key, ...optionProps } = props;
                          return (
                            <li key={key} {...optionProps}>
                              <Checkbox
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.name}
                            </li>
                          );
                        }}
                        value={fieldValue}
                        onChange={(__, item) => {
                          onChange(item);
                        }}
                        disableCloseOnSelect
                        multiple
                      />
                      {error && (
                        <Box mt={0.5}>
                          <FormHelperText
                            sx={{ color: 'rgba(198, 40, 40, 1)' }}
                          >
                            {error?.message}
                          </FormHelperText>
                        </Box>
                      )}
                    </Box>
                  );
                }}
              />
            </Box>
            {isCreate && (
              <FdTypography variant="body1">
                Would you like to make the selected user(s) as Function Lead(s)
                and activate this function?
              </FdTypography>
            )}
          </Box>
        </>
      }
      confirm={
        isCreate
          ? 'Activate function and assign function lead(s)'
          : 'Assign Function Lead(s)'
      }
      confirmStyles={{ backgroundColor: 'rgba(40, 149, 123, 1)' }}
      dismiss="CANCEL"
      open={show}
      onConfirm={async () => {
        const result = await trigger(
          isCreate ? ['functionLeads'] : ['functionLeadsSelection'],
        );
        if (result) {
          setShow(false);
          onConfirm();
        }
      }}
      onDismiss={() => {
        setShow(false);
        if (!isCreate) {
          reset();
        }
      }}
    />
  );
};

AssignFunctionLeads.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  isCreate: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AssignFunctionLeads;
