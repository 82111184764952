import React from 'react';
import { Box, Divider } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import _, { capitalize } from 'lodash';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { gql } from '@apollo/client';
import SpokeIcon from '@mui/icons-material/Spoke';
import {
  BasePage,
  useSnapshot,
  globalStore,
  useQueryRecursive,
  FdTypography,
  FdCard,
  FdChip,
  FdAvatarGroup,
  FdTab,
  getAvatarColor,
  getAvatarName,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import {
  initialValues,
  getValidationSchema,
} from '../validation-schemas/Squads';
import { listAffliationsByOrgId } from '../queries/customQueries';
import { snakeCaseToTitleCase } from '../shared/utils/stringUtils';
import SquadManagement from '../components/Squad/SquadManagement';
import SquadSkills from '../components/Squad/SquadSkills';
import { listSquadsByOrgId } from '../graphql/queries';
import { getCurrentUserRole } from '../components/Squad/utils';

const getUserType = (_type, _members) =>
  _members
    ?.filter((m) => m?.role === _type && m?.status === 'ACTIVE')
    ?.map((m) => ({
      pkId: m?.id,
      name: m?.user?.name,
      email: m?.user?.email,
      userId: m?.userId,
      permissions: m?.user?.permissions
        ?.filter((p) => !['PARTICIPATE', 'MANAGE_PROFILE'].includes(p))
        ?.map((p) => snakeCaseToTitleCase(p)),
      role: m?.role,
      assessments: m?.user?.assessments,
      courses: m?.user?.courses,
    })) || [];

const ViewEditSquad = () => {
  const { orgId, userId } = useSnapshot(globalStore);
  const { squadId } = useParams();
  const { data: affiliatedUsersData } = useQueryRecursive(
    gql(listAffliationsByOrgId),
    {
      variables: {
        orgId,
        limit: 500,
      },
    },
  );

  const {
    data: squadsByOrgIdData,
    loading: squadsByOrgIdLoading,
    refetch: refetchSquads,
  } = useQueryRecursive(gql(listSquadsByOrgId), {
    variables: {
      orgId,
      limit: 500,
    },
    staleTime: { seconds: 0 },
    onCompleted: (_data) => {
      const squadSelected =
        _data?.listSquadsByOrgId?.items?.find(
          (squad) => squad?.id === squadId,
        ) || {};
      const {
        name,
        description,
        members,
        function: squadFunction,
      } = squadSelected || {};
      // eslint-disable-next-line no-use-before-define
      reset({
        squadId,
        squadName: name,
        squadDescription: description,
        managers: getUserType('MANAGER', members?.items),
        members: getUserType('MEMBER', members?.items),
        functionLeads: squadFunction?.functionLeads?.items || [],
      });
    },
  });

  const allSquads = squadsByOrgIdData?.listSquadsByOrgId?.items || [];
  const allSquadUsers = allSquads
    ?.map((s) => s?.members?.items || [])
    ?.flat()
    ?.map((u) => u?.userId);
  const allSquadNames = allSquads?.map((s) => s?.name?.toLowerCase());

  const validationSchema = getValidationSchema({ squadNames: allSquadNames });

  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, getValues } = hookFormMethods;

  const allAffiliatedUsers =
    _.uniqBy(
      affiliatedUsersData?.listAffliationsByOrgId?.items
        ?.filter((u) => u?.status === 'ACTIVE')
        ?.map((u) => {
          return {
            id: u?.userId,
            name: u?.user?.name,
            email: u?.user?.email,
            userId: u?.userId,
            permissions: u?.user?.permissions
              ?.filter((p) => !['PARTICIPATE', 'MANAGE_PROFILE'].includes(p))
              ?.map((p) => snakeCaseToTitleCase(p)),
            assessments: u?.user?.assessments,
            courses: u?.user?.courses,
          };
        }),
      'userId',
    ) || [];

  const squadName = getValues('squadName');
  const currentUserRole = getCurrentUserRole(allSquads, squadId, userId);
  const isFunctionLead =
    getValues('functionLeads').some((fl) => fl === userId) || false;

  const squadMembers = [...getValues('managers'), ...getValues('members')];
  const squadMembersMapped = squadMembers?.map((m) => ({
    id: m?.userId,
    name: m?.name,
    role: `Squad ${capitalize(m?.role)}`,
  }));

  return (
    <BasePage
      heading=""
      data-cy="edit-squad-base-page"
      breadCrumbs={[
        { url: '/user-management', name: 'Home' },
        { url: '/user-management/squads', name: 'User / Squads' },
      ]}
      currentPageBreadcrumbLabel={squadName}
      linkComponent={RouterLink}
    >
      <FormProvider {...hookFormMethods}>
        <FdCard variant="outlined">
          <FdSkeleton loading={squadsByOrgIdLoading || !squadName} height={36}>
            <Box
              className="flex items-center gap-x-3 mb-2"
              style={{ height: '36px' }}
            >
              <SpokeIcon />
              <FdTypography variant="h3">{squadName}</FdTypography>
              <Divider orientation="vertical" />
              <FdAvatarGroup
                avatars={[
                  ...getValues('managers'),
                  ...getValues('members'),
                ]?.map((m) => ({
                  content: getAvatarName(m?.name),
                  color: getAvatarColor(m?.name),
                }))}
                size="xsmall"
                max={5}
                data-cy="squad-avatars"
                spacing="small"
              />
              {currentUserRole && (
                <>
                  <Divider orientation="vertical" />
                  <Box className="flex items-center gap-x-2">
                    <FdTypography variant="captiontext1" color="secondary">
                      My Role:
                    </FdTypography>
                    <FdChip
                      size="small"
                      label={currentUserRole}
                      style={{ backgroundColor: 'rgba(179, 229, 252, 1)' }}
                    />
                  </Box>
                </>
              )}
            </Box>
          </FdSkeleton>
          <FdTypography variant="body1" color="secondary">
            Welcome to this squad&apos;s profile. Explore this squad&apos;s
            skill and performance data in the &apos;Squad Skills&apos; tab. To
            view and manage squad details, click the &apos;Squad
            Management&apos; tab.
          </FdTypography>
        </FdCard>
        <FdTab
          label={[
            {
              label: 'Squad Skills',
              index: 0,
              data: <SquadSkills squadMembers={squadMembersMapped} />,
            },
            {
              label: 'Squad Management',
              index: 1,
              data: (
                <SquadManagement
                  allUsers={allAffiliatedUsers}
                  allSquadUsers={allSquadUsers}
                  onRemoveUser={() => refetchSquads()}
                  isFunctionLead={isFunctionLead}
                />
              ),
            },
          ]}
        />
      </FormProvider>
    </BasePage>
  );
};

export default ViewEditSquad;
