import React, { useState } from 'react';
import {
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  Divider,
} from '@mui/material';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { FdModal, FdTypography, FdCard } from '@fifthdomain/fe-shared';
import SummaryCell from './SummaryCell';
import { getFunctionCortexTargets } from '../utils';
import TargetsCortex from '../Cortex/TargetsCortex';

const RadioItem = ({ ...props }) => (
  <Radio size="small" sx={{ ml: 1.5 }} {...props} />
);

const SkillsTargetModal = ({ openModal, setOpenModal }) => {
  const [workRoleSelection, setWorkRoleSelection] = useState('all');
  const { getValues } = useFormContext();
  const workRoles = getValues('workRoles');
  const workRolesCount = workRoles?.length || 0;
  const { targets, fillers } = getFunctionCortexTargets(workRoles);
  const selectedWorkRoleSkills =
    workRoleSelection === 'all'
      ? []
      : workRoles
          ?.filter((wr) => wr?.workRoleName === workRoleSelection)
          ?.map((wr) =>
            wr?.skills?.map((s) => s?.skills?.items[0]?.skill?.alias),
          )
          ?.flat() || [];
  const cortexTargets =
    workRoleSelection === 'all'
      ? targets
      : targets?.filter((t) => selectedWorkRoleSkills.includes(t?.skillAlias));
  const cortexFillers =
    workRoleSelection === 'all'
      ? fillers
      : fillers?.filter((t) => selectedWorkRoleSkills.includes(t?.skillAlias));
  const cortexData = {
    targets: cortexTargets,
    fillers: cortexFillers,
  };
  const radioOptions = workRoles?.map((wr) => ({
    label: `${wr?.units} x ${wr?.workRoleName}`,
    value: wr?.workRoleName,
  }));
  const uniqueRequiredSkills = _.uniqBy(targets, 'skillAlias')?.length || 0;
  const requiredUnits = _.sumBy(targets, 'units');

  return (
    <FdModal
      size="xl"
      open={openModal}
      showConfirm={false}
      dismiss="Close"
      onDismiss={() => {
        setWorkRoleSelection('all');
        setOpenModal(null);
      }}
      hideHeader
    >
      <Box mt={2.5} mb={1} ml={1.5}>
        <FdTypography variant="h3">Function Skills Target</FdTypography>
        <FdCard variant="outlined" sx={{ mt: 2 }}>
          <Box className="grid grid-cols-3 divide-x">
            <SummaryCell
              header={requiredUnits > 0 ? requiredUnits : '-'}
              label={
                <Box className="flex items-center gap-x-2">
                  <Box
                    sx={{
                      width: '16px',
                      height: '16px',
                      border: '3px solid rgba(0, 151, 167, 1)',
                    }}
                  />
                  Required Units
                </Box>
              }
              tooltipText="Each square on the Skills Cortex represents a Skill-Proficiency unit. The Required Units for a function are the total skill-proficiency units needed, combining all units required for each selected work role. This is represented by highlighted borders on the Skills Cortex"
            />
            <SummaryCell
              header={uniqueRequiredSkills > 0 ? uniqueRequiredSkills : '-'}
              label="Required Unique Skills"
              tooltipText="The total number of distinct skills needed for the function, considering all selected work roles."
            />
            <SummaryCell
              header={workRolesCount > 0 ? workRolesCount : '-'}
              label="Number of Unique Work Roles"
              tooltipText="The number of different work roles required as a target for the function."
            />
          </Box>
        </FdCard>
        <Box className="flex gap-x-2 mt-6">
          <Box className="text-center">
            <FdTypography variant="subtitle1">
              Cyber Skills Cortex of the Work Role
            </FdTypography>
            <Box mt={1}>
              <TargetsCortex data={cortexData} />
            </Box>
          </Box>
          <Box>
            <FdTypography variant="subtitle2" color="secondary">
              Work Roles required for this function
            </FdTypography>
            <RadioGroup
              defaultValue="all"
              sx={{ mt: 1 }}
              onChange={(e) => setWorkRoleSelection(e.target.value)}
            >
              <FormControlLabel
                value="all"
                control={<RadioItem />}
                label="All"
              />
              <Divider />
              <Box maxHeight={480} sx={{ overflowY: 'auto' }}>
                {radioOptions.map((option) => (
                  <FormControlLabel
                    value={option.value}
                    control={<RadioItem />}
                    label={option.label}
                  />
                ))}
              </Box>
            </RadioGroup>
          </Box>
        </Box>
      </Box>
    </FdModal>
  );
};

export default SkillsTargetModal;
