import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';
import anime from 'animejs';

const ReadinessScore = ({ score }) => {
  const boxRef = useRef(null);

  useEffect(() => {
    anime.set(boxRef.current, { rotateY: -180 });
    anime({
      targets: boxRef.current,
      rotateY: [-180, 0],
      duration: 1000,
      easing: 'easeOutBack',
    });
  }, [score]);

  return (
    <Box
      ref={boxRef}
      sx={{
        width: '32px',
        height: '28px',
        borderRadius: '4px',
        backgroundColor: '#00BCD4',
        textAlign: 'center',
      }}
    >
      <FdTypography variant="subtitle1" color="white">
        {score}
      </FdTypography>
    </Box>
  );
};

ReadinessScore.propTypes = {
  score: PropTypes.string.isRequired,
};

export default ReadinessScore;
