import { gql } from '@apollo/client';
import {
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { listFunctionsByOrgId } from '../graphql/queries';

const useGetFunctions = () => {
  const { orgId } = useSnapshot(globalStore);
  const { data: functionsData, loading: allFunctionsLoading } =
    useQueryRecursive(gql(listFunctionsByOrgId), {
      variables: {
        orgId,
        filter: { status: { ne: 'REMOVED' } },
      },
      skip: !orgId,
      staleTime: { seconds: 0 },
    });
  const allFunctions = functionsData?.listFunctionsByOrgId?.items || [];

  return {
    allFunctions,
    allFunctionsLoading,
  };
};

export default useGetFunctions;
