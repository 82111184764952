import React, { useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  IconButton,
} from '@mui/material';
import * as singleSpa from 'single-spa';
import { gql, useMutation } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import NavigationPrompt from 'react-router-navigation-prompt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdButton,
  BasePage,
  FdModal,
  useSnapshot,
  globalStore,
  FdTooltip,
  FdLoadingSpinner,
} from '@fifthdomain/fe-shared';
import {
  getValidationSchema,
  initialValues,
} from '../validation-schemas/Functions';
import { scrollToTop } from '../shared/utils/scroll';
import FunctionDetails from '../components/Functions/Create/FunctionDetails';
import FunctionSkillsTarget from '../components/Functions/Create/FunctionSkillsTarget';
import AssignFunctionLeads from '../components/Functions/Fragments/AssignFunctionLeads';
import {
  createFunction,
  createCustomerWorkroleFunction,
  createUserFunction,
} from '../graphql/mutations';
import { successToastMessage } from '../shared/utils/toast';
import useGetCustomerWorkRoles from '../hooks/useGetCustomerWorkRoles';
import useGetFunctions from '../hooks/useGetFunctions';
import {
  createCustomerWorkRoles,
  onError,
} from '../components/Functions/utils';

const steps = ['Add Function Details', 'Set Skills Target'];

const CreateFunction = () => {
  const [activeStep, setActiveStep] = useState(0);
  const {
    orgId,
    userId,
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);
  const [showFunctionLeads, setShowFunctionLeads] = useState(false);
  const { allFunctions, allFunctionsLoading } = useGetFunctions();

  if (allFunctionsLoading) <FdLoadingSpinner />;

  const validationSchema = getValidationSchema({
    functionNames: allFunctions?.map((fn) => fn.name?.toLowerCase()),
  });

  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const {
    formState: { isDirty },
    reset,
    getValues,
    trigger,
  } = hookFormMethods;

  const [createFunctionMutation, { loading: createFunctionMutationLoading }] =
    useMutation(gql(createFunction), {
      onError,
    });

  const [
    createCustomerWorkRoleFunctionMutation,
    { loading: createWorkRoleMutationLoading },
  ] = useMutation(gql(createCustomerWorkroleFunction), {
    onError,
  });

  const [createUserFunctionMutation, { loading: createUserFunctionLoading }] =
    useMutation(gql(createUserFunction), {
      onError,
    });

  const { customerWorkRoles } = useGetCustomerWorkRoles();

  const onSubmit = async ({ saveInactive = false } = {}) => {
    const result = await trigger(
      saveInactive
        ? ['functionName', 'functionDescription']
        : hasSkillTarget
          ? [
              'functionName',
              'functionDescription',
              'workRoles',
              'functionLeads',
            ]
          : ['functionName', 'functionDescription', 'functionLeads'],
    );

    if (result) {
      const { functionName, functionDescription, workRoles, functionLeads } =
        getValues();
      createFunctionMutation({
        variables: {
          input: {
            name: functionName.trim(),
            description: functionDescription.trim(),
            orgId,
            userId,
            status: saveInactive ? 'INACTIVE' : 'ACTIVE',
          },
        },
        onCompleted: async (_data) => {
          const onCompleted = () => {
            successToastMessage(
              saveInactive
                ? 'Success! Inactive Function created'
                : 'Success! Active Function created. Function Lead(s) have been assigned and can now access the function.',
            );
            reset();
            setTimeout(() => {
              singleSpa.navigateToUrl('/user-management/workforce/functions');
            }, 500);
          };
          // assign workRoles if present
          if (
            workRoles.length > 0 ||
            // no workRoles for - no skill targets orgs
            (!hasSkillTarget && functionLeads.length > 0)
          ) {
            const { id: functionId } = _data?.createFunction || {};
            await createCustomerWorkRoles({
              workRoleMutation: createCustomerWorkRoleFunctionMutation,
              userMutation: createUserFunctionMutation,
              functionId,
              workRoles,
              functionLeads,
              onCompleted,
            });
          } else {
            onCompleted();
          }
        },
      });
    }
  };

  const validatePage = async () => {
    let result;
    switch (activeStep) {
      case 0: {
        result = await trigger(['functionName', 'functionDescription']);
        break;
      }
      default:
        result = true;
        break;
    }
    return result;
  };

  const handleNext = async () => {
    if (await validatePage()) {
      if (activeStep === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollToTop();
      }
    }
  };

  const handleBack = () => {
    // on click cancel of first page go back to list page
    if (activeStep === 0) {
      singleSpa.navigateToUrl('/user-management/workforce/functions');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
    }
  };
  const loading =
    createFunctionMutationLoading ||
    createWorkRoleMutationLoading ||
    createUserFunctionLoading;
  const showActionButtons = !hasSkillTarget || activeStep === 1;

  return (
    <BasePage
      heading="Create a Function"
      data-cy="create-function-base-page"
      breadCrumbs={[
        {
          url: '/user-management/workforce/functions',
          name: 'Home/ Workforce/ Functions',
        },
      ]}
      currentPageBreadcrumbLabel="Create Function"
      linkComponent={RouterLink}
    >
      {hasSkillTarget && (
        <Box width="406px">
          <Stepper activeStep={activeStep} sx={{ height: '75px' }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}
      <FormProvider {...hookFormMethods}>
        <form>
          {
            {
              0: <FunctionDetails />,
              1: (
                <FunctionSkillsTarget
                  allCustomerWorkRoles={customerWorkRoles}
                />
              ),
            }[activeStep]
          }
          <Box className="flex justify-between mt-3">
            <FdButton
              size="large"
              variant="secondary"
              onClick={() =>
                singleSpa.navigateToUrl('/user-management/workforce/functions')
              }
            >
              Cancel
            </FdButton>
            <Box className="flex gap-x-4">
              {activeStep === 1 && (
                <FdButton size="large" variant="secondary" onClick={handleBack}>
                  Back
                </FdButton>
              )}
              {showActionButtons && (
                <FdButton
                  size="large"
                  disabled={loading}
                  endIcon={
                    <FdTooltip title="This will save the function as inactive (draft), and it will not yet be available to the Function Lead.">
                      <IconButton size="small" className="h-2.5 text-white">
                        <InfoOutlinedIcon />
                      </IconButton>
                    </FdTooltip>
                  }
                  onClick={() => onSubmit({ saveInactive: true })}
                >
                  {loading && <CircularProgress size={16} className="mr-2" />}
                  Save as an inactive function
                </FdButton>
              )}
              {showActionButtons && (
                <FdButton
                  size="large"
                  disabled={loading}
                  variant="primary"
                  style={{ backgroundColor: 'rgba(40, 149, 123, 1)' }}
                  endIcon={
                    <FdTooltip title="This will activate the function, making it available for the Function Lead to access, who can then begin to create squads for the function.">
                      <IconButton size="small" className="h-2.5 text-white">
                        <InfoOutlinedIcon />
                      </IconButton>
                    </FdTooltip>
                  }
                  onClick={async () => {
                    const result = await trigger([
                      'functionName',
                      'functionDescription',
                      ...(hasSkillTarget
                        ? ['workRolesSelection', 'workRoles']
                        : []),
                    ]);
                    if (result) {
                      setShowFunctionLeads(true);
                    }
                  }}
                >
                  {loading && <CircularProgress size={16} className="mr-2" />}
                  Save as an active function
                </FdButton>
              )}
              {activeStep < 1 && hasSkillTarget && (
                <FdButton size="large" onClick={handleNext}>
                  Next
                </FdButton>
              )}
            </Box>
          </Box>
          <AssignFunctionLeads
            isCreate
            show={showFunctionLeads}
            setShow={setShowFunctionLeads}
            onConfirm={onSubmit}
          />
        </form>
      </FormProvider>
      <NavigationPrompt when={isDirty} afterCancel={isDirty}>
        {({ onConfirm, onCancel }) => (
          <FdModal
            title="Are you sure you want to leave?"
            description="You have unsaved changes. Click the Stay button to go back to the form and save your changes."
            confirm="Stay"
            dismiss="Leave"
            open
            onConfirm={onCancel}
            onDismiss={onConfirm}
          />
        )}
      </NavigationPrompt>
    </BasePage>
  );
};

export default CreateFunction;
