import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdTable,
  FdModal,
  FdTypography,
  FdAlert,
  FdChip,
  globalStore,
  useSnapshot,
  FdCheckbox,
  Authorization,
} from '@fifthdomain/fe-shared';
import {
  errorToastMessage,
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import AssessmentsIcon from './Icons/AssessmentsIcon';
import CompetitionsIcon from './Icons/CompetitionsIcon';
import CoursesIcon from './Icons/CoursesIcon';
import { addUsers } from '../../graphql/mutations';
import { invalidateAffiliatedQuery } from '../../queries/invalidateQueries';
import FdTextView from '../FdTextView';

const NonAffiliatedUsersTable = ({
  rows,
  refetchQueries,
  allAffiliatedUsers,
}) => {
  const globalSnap = useSnapshot(globalStore);
  const [sendAffiliationUser, setSendAffiliationUser] = useState(undefined);
  const [confirm1, setConfirm1] = useState(false);
  const [confirm2, setConfirm2] = useState(false);
  const { orgMaxInvitees } = globalSnap;

  const [addUsersMutation, { loading: inviteUsersLoading }] = useMutation(
    gql(addUsers),
    {
      onCompleted: (_data) => {
        setSendAffiliationUser(undefined);
        successToastMessage(
          'Success! Affiliation request sent. User moved to Affiliated Users list.',
        );
        refetchQueries();
        invalidateAffiliatedQuery();
      },
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );

  // reset on open
  useEffect(() => {
    setConfirm1(false);
    setConfirm2(false);
  }, [sendAffiliationUser]);

  const columns = [
    { field: 'name', width: 350, headerName: 'Name' },
    {
      field: 'registrationType',
      width: 200,
      headerName: 'Registration Status',
      renderCell: (params) => (
        <FdChip
          color={
            params?.row?.registrationType === 'Registered'
              ? 'success'
              : 'default'
          }
          size="small"
          label={params?.row?.registrationType}
        />
      ),
    },
    { field: 'alias', width: 150, headerName: 'Alias' },
    { field: 'email', width: 400, headerName: 'Email Address' },
    {
      field: 'assignedEvents',
      width: 200,
      headerName: 'Assigned Event Types',
      unSort: true,
      renderCell: (params) => (
        <Box className="flex items-center gap-x-2">
          {params?.row?.assignedEvents?.includes('ASSESSMENT') && (
            <AssessmentsIcon />
          )}
          {params?.row?.assignedEvents?.includes('COMPETITION') && (
            <CompetitionsIcon />
          )}
          {params?.row?.assignedEvents?.includes('LAB') && <CoursesIcon />}
        </Box>
      ),
    },
  ];

  const canInviteUsers = orgMaxInvitees
    ? orgMaxInvitees > allAffiliatedUsers?.length
    : true;

  const actions = [
    {
      label: 'View Profile',
      show: (row) => row?.registrationType === 'Registered',
      onClick: ({ userId }) => {
        window.open(
          `/competitions/non-affiliated-view-user/${userId}`,
          '_blank',
          'noopener,noreferrer',
        );
      },
    },
    {
      label: canInviteUsers ? (
        <>Send Affiliation request</>
      ) : (
        <Box>
          <FdTextView
            mt={2}
            label="Send Affiliation request"
            tooltip="You have already used the maximum number of affiliated user invitations available on your organisation's current plan. To invite more affiliated users, you will need to disaffiliate current users or revoke pending affiliation requests. Alternatively, contact FifthDomain to discuss upgrading your plan."
            disabled
          />
        </Box>
      ),
      show: () => Authorization.canManageUsers(globalSnap.permissions),
      onClick: (row) => canInviteUsers && setSendAffiliationUser(row),
    },
    {
      show: () => false, // dummy entry to display drop down
    },
  ];

  return (
    <Box height={643}>
      <FdTable
        toolbarSettings={{
          title: '',
          filterButton: true,
          searchBox: true,
        }}
        rows={rows || []}
        columns={columns}
        tablePageSize={10}
        actions={actions}
        gridId="user-management-list-groups"
        loading={inviteUsersLoading}
      />
      <FdModal
        size="md"
        title="Send Affiliation request?"
        confirm="send affiliation request"
        dismiss="CANCEL"
        disableConfirm={!confirm1 || !confirm2}
        open={sendAffiliationUser}
        onConfirm={() => {
          addUsersMutation({
            variables: {
              emails: [sendAffiliationUser?.email],
              orgId: globalSnap?.orgId,
              participantType: 'INTERNAL',
              permissions: ['PARTICIPATE', 'MANAGE_PROFILE'],
              affliationAction: 'NEW_REQUEST',
            },
          });
        }}
        onDismiss={() => {
          setSendAffiliationUser(undefined);
          warningToastMessage('Affiliation request not sent.');
        }}
      >
        <FdTypography variant="body2">
          This action will send an email to the user(s) for them to confirm
          their affiliation with your organisation. A user only becomes
          affiliated after the affiliation is confirmed by them.
        </FdTypography>
        <Box mt={2}>
          <FdAlert
            variant="info"
            title="Information to Note"
            message={
              <Box>
                <FdTypography>
                  Users who receive an invitation to confirm their affiliation
                  will be moved to the list of Affiliated users with a
                  &apos;pending&apos; status.
                </FdTypography>
                <Box mt={1}>
                  <FdTypography>
                    If the user declines the affiliation, they will revert to
                    being a non-affiliated user within your organisation.
                  </FdTypography>
                </Box>
              </Box>
            }
          />
        </Box>
        <Box mt={2}>
          <Box className="flex items-center gap-x-2">
            <FdTypography variant="subtitle1">
              Acknowledge terms to proceed with sending Affiliation Request.
            </FdTypography>
          </Box>
          <FdCheckbox
            label="I understand and acknowledge that upon confirming their affiliation, the user will gain access to the Organisation's Hall of Fame."
            checked={confirm1}
            onChange={setConfirm1}
          />
          <FdCheckbox
            label="I recognise and accept that an affiliated user has the right to disaffiliate from this organisation at any moment of their choosing."
            checked={confirm2}
            onChange={setConfirm2}
          />
        </Box>
      </FdModal>
    </Box>
  );
};

NonAffiliatedUsersTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  refetchQueries: PropTypes.func.isRequired,
  allAffiliatedUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default NonAffiliatedUsersTable;
