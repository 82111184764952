import Yup from '../Common';

export const initialValues = {
  squadId: '',
  squadName: '',
  squadDescription: '',
  managers: [],
  members: [],
  functionLeads: [],
};

const userType = {
  pkId: Yup.string(),
  name: Yup.string(),
  email: Yup.string(),
  userId: Yup.string(),
  permissions: Yup.array().of(Yup.string()),
};

export const getValidationSchema = ({ squadNames }) =>
  Yup.object().shape({
    squadId: Yup.string(),
    squadName: Yup.string()
      .required('Please enter a Squad Name')
      .max(50, 'Squad Name cannot be more than 50 characters.')
      .noWhitespace('Please enter a Squad Name')
      .test(
        'unique-test',
        'Squad name already taken. Please choose a different name.',
        (value) => !squadNames?.includes(value?.toLowerCase()),
      ),
    squadDescription: Yup.string()
      .max(80, 'Squad Description can only have 80 characters')
      .test(
        'noWhitespaceNonRequired',
        'Please enter a valid Squad Description',
        (value) => !value || (value && value.trim().length),
      ),
    managers: Yup.array()
      .of(Yup.object().shape(userType))
      .min(1, 'Please select at least one Squad Manager to create a Squad.'),
    members: Yup.array().of(Yup.object().shape(userType)),
    functionLeads: Yup.array().of(Yup.string()),
  });
