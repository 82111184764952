import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdTypography,
  FdTooltip,
  getAvatarColor,
} from '@fifthdomain/fe-shared';
import RadarNivo from '../Charts/RadarNivo';
import HighlightedLabel from './HighlightedLabel';

const LabelWithTooltip = ({ title, tooltipText, ...props }) => {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <FdTypography variant="captiontext1" color="secondary">
        {title}
      </FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton size="small" style={{ marginLeft: '5px' }}>
          <InfoOutlinedIcon />
        </IconButton>
      </FdTooltip>
    </Box>
  );
};

LabelWithTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  tooltipText: PropTypes.node.isRequired,
};

const LabelValue = ({ label, value }) => (
  <Box className="flex items-center justify-between w-4/5 ml-11 mt-1">
    <FdTypography variant="body2">{label}</FdTypography>
    <FdTypography variant="subtitle1">{value}</FdTypography>
  </Box>
);
LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const PerformanceTriangle = ({ performanceData, performanceDataUsers }) => {
  const theme = useTheme();
  const isDarkTheme = theme?.palette?.type === 'dark';
  const { squad, affiliatedUsers } = performanceData || {};
  const getUserRows = (_field) =>
    Object.assign(
      {},
      ...performanceDataUsers?.map((u) => ({
        [u?.userName]: u[_field],
      })),
    );
  const radarChartData = [
    {
      label: 'Success',
      baseLine: 100,
      'Squad Average': squad?.success,
      'Affiliated User Average': affiliatedUsers?.success,
      ...getUserRows('success'),
    },
    {
      label: 'Speed',
      baseLine: 100,
      'Squad Average': squad?.speed,
      'Affiliated User Average': affiliatedUsers?.speed,
      ...getUserRows('speed'),
    },
    {
      label: 'Efficiency',
      baseLine: 100,
      'Squad Average': squad?.efficiency,
      'Affiliated User Average': affiliatedUsers?.efficiency,
      ...getUserRows('efficiency'),
    },
  ];

  const keys = [
    'baseLine',
    'Squad Average',
    'Affiliated User Average',
    ...performanceDataUsers?.map((u) => u?.userName),
  ];
  const getBorderColor = (v) => {
    let color;
    switch (v?.key) {
      case 'baseLine':
        color = 'rgba(227, 230, 236, 1)';
        break;
      case 'Squad Average':
        color = 'rgba(22, 51, 102, 1)';
        break;
      case 'Affiliated User Average':
        color = 'rgb(180, 191, 197)';
        break;
      default:
        color = getAvatarColor(v?.key);
        break;
    }
    return color;
  };

  return (
    <Box className="flex">
      <Box mt={1} mb={1} width="100%">
        <Box display="flex">
          <Box width="100%">
            <Box className="flex justify-between mt-1">
              <Box className="flex items-center">
                <HighlightedLabel value={squad?.score || 0} />
                <FdTypography variant="captiontext1">
                  FifthDomain Score
                </FdTypography>
                <FdTooltip
                  title={
                    <Box>
                      The FifthDomain score (0-100) is a combined weighted sum
                      of Success, Efficiency, and Speed scores, with Success
                      carrying the highest weightage.
                      <br />
                      The displayed FifthDomain Score is the average FifthDomain
                      Score of current Squad Managers and Squad Members.
                    </Box>
                  }
                >
                  <IconButton size="small" style={{ marginLeft: '5px' }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </FdTooltip>
              </Box>
              <Box className="flex">
                <LabelWithTooltip
                  title="Success"
                  tooltipText="The Success Score (0-100) measures ability to capture flags, taking into account points earned for solved challenges relative to overall points available."
                  mr={3}
                />
                <LabelWithTooltip
                  title="Efficiency"
                  tooltipText="The Efficiency Score (0-100) measures ability to score points in challenges while minimising the number of attempts made."
                  mr={3}
                />
                <LabelWithTooltip
                  title="Speed"
                  tooltipText="The Speed Score (0-100) reflects quickness in completing challenges within the allocated time for an event."
                />
              </Box>
            </Box>
            <Box className="flex flex-col">
              <Box height="350px" my={3}>
                <RadarNivo
                  data={radarChartData}
                  keys={keys}
                  indexBy="label"
                  colors={keys?.map(() => 'transparent')}
                  gridLabel={(props) => {
                    // eslint-disable-next-line react/prop-types
                    const { x, y, id } = props;
                    const anchor = x > 5 ? 'start' : x < -5 ? 'end' : 'middle';
                    const lineData = radarChartData?.find(
                      (d) => d.label === id,
                    );
                    return (
                      <g
                        transform={`translate(${
                          x + (id === 'Success' ? 30 : 0)
                        }, ${y})`}
                      >
                        <text
                          alignmentBaseline="middle"
                          textAnchor={id === 'Success' ? 'start' : anchor}
                          fontSize={12}
                          fontFamily="Montserrat, sans-serif"
                          fontWeight={700}
                          opacity={0.7}
                          fill={isDarkTheme ? '#fff' : '#000'}
                        >
                          {id}
                          <tspan
                            fontSize={12}
                            fontWeight={400}
                            x={0}
                            opacity={0.7}
                            dy={25}
                          >
                            {`Squad Average ${lineData['Squad Average']}`}
                          </tspan>
                          <tspan
                            fontSize={12}
                            fontWeight={400}
                            x={0}
                            dy={20}
                            opacity={0.7}
                          >
                            {`Affiliated User Average ${lineData['Affiliated User Average']}`}
                          </tspan>
                        </text>
                      </g>
                    );
                  }}
                  borderColor={(v) => getBorderColor(v)}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PerformanceTriangle.propTypes = {
  performanceData: PropTypes.shape({}).isRequired,
  performanceDataUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PerformanceTriangle;
