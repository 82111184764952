import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  Box,
  TextField,
  Checkbox,
  FormHelperText,
  createFilterOptions,
} from '@mui/material';
import {
  FdTypography,
  FdButton,
  FdTable,
  FdRadioGroup,
  FdIconWithTooltip,
  FdDelayed,
  FdAutocompleteStatic,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';

const SEARCH_TYPE = {
  name: 'Search by Name',
  skill: 'Search by Skill',
};

const SquadUsersTable = ({
  isManagerView,
  allManagers,
  allUsers,
  existingSquadMembersInOrg,
  squadId,
  onEdit,
}) => {
  const [searchType, setSearchType] = useState(SEARCH_TYPE.name);
  const {
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);
  const { trigger, control, setValue, getValues, watch } = useFormContext();
  const usersSelector = `${squadId}.${
    isManagerView ? 'squadManagers' : 'squadMembers'
  }`;
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) =>
      searchType === SEARCH_TYPE.name
        ? option.name
        : option?.topSkills
            ?.map((s) => `${s?.skillName}(${s?.skill})`)
            ?.join(', '),
  });
  const { fields: squadUsers } = useFieldArray({
    control,
    name: usersSelector,
  });
  const columns = [
    { field: 'name', width: 350, headerName: 'Name' },
    {
      field: 'topSkills',
      width: 200,
      filterable: hasSkillTarget,
      headerName: 'Top Skills Demonstrated',
      valueGetter: (params) => params?.value?.map((s) => s?.skill).join(', '),
      renderCell: (params) =>
        params?.row?.topSkills?.map((s) => s?.skill).join(', '),
    },
    ...(!isManagerView
      ? [
          {
            field: 'workRole',
            width: 350,
            filterable: hasSkillTarget,
            renderHeader: () => (
              <Box className="flex items-center gap-x-1">
                Work Role
                <FdIconWithTooltip title="This is the work role assigned to the user by either User with Manage Users permission or by their Squad Manager." />
              </Box>
            ),
          },
        ]
      : []),
  ];
  const squadUsersForTable = squadUsers?.map((s) => {
    const user = allUsers?.find((u) => u?.id === s?.userId) || {};
    return {
      ...s,
      topSkills: user?.topSkills || [],
      workRole: user?.workRole || '',
    };
  });

  const actions = [
    {
      label: 'Remove',
      disabled: isManagerView && squadUsers?.length === 1,
      onClick: (row) => {
        setValue(
          usersSelector,
          squadUsers?.filter((su) => su?.userId !== row?.userId),
        );
        // marked for delete in db
        if (row?.pkId) {
          setValue(`${squadId}.deletedUsers`, [
            ...(getValues(`${squadId}.deletedUsers`) || []),
            { pkId: row.pkId, userId: row.userId },
          ]);
        }
        onEdit?.(true);
      },
    },
  ];
  const dropDownSelector = `${squadId}.${
    isManagerView ? 'squadManagersSelection' : 'squadMembersSelection'
  }`;
  const squadUsersSelection =
    [
      ...(getValues(`${squadId}.squadManagers`) || []),
      ...(getValues(`${squadId}.squadMembers`) || []),
    ]?.map((u) => u?.userId) || [];
  const deletedUsers =
    watch(`${squadId}.deletedUsers`)?.map((u) => u?.userId) || [];

  const autoCompleteOptions =
    (
      (isManagerView
        ? [
            ...(allManagers
              ?.filter((u) => !existingSquadMembersInOrg?.includes(u?.id))
              ?.map((m) => ({
                userId: m?.id,
                name: m?.name,
                topSkills: m?.topSkills,
              })) || []),
            ...(allUsers?.filter(
              (u) =>
                deletedUsers?.includes(u?.id) &&
                u?.permissions.includes('MANAGE_SQUADS'),
            ) || []), // include deleted users in FE but not saved in db yet
          ]
        : [
            ...(allUsers
              ?.filter((u) => !existingSquadMembersInOrg?.includes(u?.id))
              ?.map((u) => ({
                userId: u?.id,
                name: u?.name,
                topSkills: u?.topSkills,
              })) || []),
            ...(allUsers?.filter((u) => deletedUsers?.includes(u?.id)) || []),
            // include deleted users in FE but not saved in db yet
          ]) || []
    )?.filter((u) => !squadUsersSelection.includes(u?.userId)) || [];

  return (
    <Box mt={1}>
      <FdTypography variant="subtitle2">
        {isManagerView
          ? 'Select Squad Members from list of available Affiliated Users with "Manage Squads" permission:'
          : 'Select Squad Members from list of available Affiliated Users:'}
      </FdTypography>
      <FdRadioGroup
        id="searchType"
        label=""
        options={[SEARCH_TYPE.name, SEARCH_TYPE.skill]}
        defaultValue={searchType}
        placement="horizontal"
        onChange={(_value) => setSearchType(_value)}
        fullWidth
      />
      <Box className="flex items-center gap-x-3 mt-1 mb-2">
        <Box className="w-full">
          <Controller
            control={control}
            name={dropDownSelector}
            render={({
              field: { ref, onChange, value: fieldValue, ...rest },
              fieldState: { error },
            }) => (
              <FdDelayed
                showSkeleton
                height="56px"
                triggerField={autoCompleteOptions}
                delay={1}
              >
                <FdAutocompleteStatic
                  {...rest}
                  filterOptions={filterOptions}
                  inputRef={ref}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) =>
                    option?.userId === value?.userId
                  }
                  renderInput={(params) => {
                    return (
                      <Box className="flex items-center mr-2">
                        <TextField
                          {...params}
                          error={error}
                          placeholder={`Search by names or skills to select squad ${
                            isManagerView ? 'managers' : 'members'
                          }.`}
                        />
                      </Box>
                    );
                  }}
                  options={autoCompleteOptions}
                  fullWidth
                  renderOption={(props, option, { selected }) => {
                    const { ...optionProps } = props;
                    return (
                      <li {...optionProps}>
                        <Box className="flex items-center gap-x-2 mb-1">
                          <Checkbox checked={selected} />
                          <Box className="flex flex-col" key={optionProps.key}>
                            <FdTypography variant="subtitle1">
                              {option?.name}
                            </FdTypography>
                            {option.topSkills?.length > 0 && (
                              <FdTypography variant="captiontext1">
                                Top skills:{' '}
                                {option?.topSkills
                                  ?.map((s) => `${s?.skillName}(${s?.skill})`)
                                  ?.join(', ')}
                              </FdTypography>
                            )}
                          </Box>
                        </Box>
                      </li>
                    );
                  }}
                  value={fieldValue}
                  multiple
                  onChange={(__, item) => {
                    onChange(item);
                  }}
                />
                {error && (
                  <Box mt={0.5}>
                    <FormHelperText
                      style={{
                        color: 'rgba(198, 40, 40, 1)',
                      }}
                    >
                      {error?.message}
                    </FormHelperText>
                  </Box>
                )}
              </FdDelayed>
            )}
          />
        </Box>
        <FdButton
          style={{ width: '300px' }}
          onClick={() => {
            if (getValues(dropDownSelector)?.length > 0) {
              setValue(usersSelector, [
                ...squadUsers,
                ...getValues(dropDownSelector),
              ]);
              setValue(dropDownSelector, []);
              trigger(dropDownSelector);
              onEdit?.(true);
            }
          }}
        >
          {`Add squad ${isManagerView ? 'manager(s)' : 'members'}`}
        </FdButton>
      </Box>
      <FdTypography variant="subtitle2">{`Added Squad ${
        isManagerView ? 'Managers' : 'Members'
      } (${squadUsers?.length})`}</FdTypography>
      <Box mt={1} height={320}>
        <FdTable
          toolbarSettings={{
            title: '',
            filterButton: true,
            searchBox: true,
          }}
          columnVisibilityModel={{
            topSkills: hasSkillTarget,
            workRole: hasSkillTarget,
          }}
          rows={squadUsersForTable || []}
          columns={columns}
          tablePageSize={2}
          actions={actions}
        />
      </Box>
    </Box>
  );
};

SquadUsersTable.defaultProps = {
  isManagerView: false,
  allManagers: [],
  allUsers: [],
  onEdit: undefined,
  existingSquadMembersInOrg: [],
  squadId: undefined,
};

SquadUsersTable.propTypes = {
  isManagerView: PropTypes.bool,
  allManagers: PropTypes.arrayOf(PropTypes.shape({})),
  allUsers: PropTypes.arrayOf(PropTypes.shape({})),
  onEdit: PropTypes.func,
  existingSquadMembersInOrg: PropTypes.arrayOf(PropTypes.shape({})),
  squadId: PropTypes.string,
};

export default SquadUsersTable;
