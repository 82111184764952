import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { FdTextField } from '@fifthdomain/fe-shared';
import SquadUserCard from './SquadUserCard';
import EditContainer from '../EditContainer';
import FdTextView from '../FdTextView';
import {
  createSquadMember,
  deleteSquadMember,
  updateSquad,
} from '../../graphql/mutations';
import {
  errorToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';
import { addUsers, modalContentByType } from './utils';

const SquadManagement = ({
  allUsers,
  allSquadUsers,
  onRemoveUser,
  isFunctionLead,
}) => {
  const { control, reset, trigger, getValues } = useFormContext();
  const squadId = getValues('squadId');

  const [updateSquadMutation] = useMutation(gql(updateSquad), {
    onCompleted: () => {
      successToastMessage('Success! Squad description updated.');
    },
  });

  const [deleteSquadMemberMutation] = useMutation(gql(deleteSquadMember));
  const [createSquadMemberMutation] = useMutation(gql(createSquadMember));

  const onAddUser = (_newUsers, _type) => {
    const modalContent = modalContentByType[_type];
    const role = modalContent?.role;
    const toastTitle = modalContent?.title;
    const newUsers = _newUsers?.map((u) => ({ userId: u?.userId, role }));
    // add new users based on role
    addUsers(squadId, newUsers, createSquadMemberMutation)
      .then(() => {
        successToastMessage(`Success! ${toastTitle}(s) added.`);
      })
      .catch((error) => {
        errorToastMessage(error.message);
      });
  };

  const onDeleteUser = (_row, _type) => {
    const pkId = _row?.pkId;
    const modalContent = modalContentByType[_type];
    const showToast = () =>
      successToastMessage(`Success! ${modalContent?.title} removed.`);
    // not a database record hence remove it from form
    if (!pkId) {
      showToast();
      return;
    }
    deleteSquadMemberMutation({
      variables: {
        input: {
          id: pkId,
        },
      },
      onCompleted: () => {
        showToast();
        onRemoveUser();
      },
      onError: (error) => errorToastMessage(error),
    });
  };

  return (
    <Box className="flex flex-col">
      <Controller
        control={control}
        name="squadDescription"
        render={({ field: { ref, value, ...rest }, fieldState: { error } }) => (
          <Box mb={2}>
            <EditContainer
              title="Squad Description"
              labelOnly={
                <Box>
                  <FdTextView
                    mt={2}
                    label="Squad Description"
                    value={
                      getValues('squadDescription') || 'No description provided'
                    }
                    noHeading
                  />
                </Box>
              }
              hideEdit={false}
              onCancel={() => reset()}
              onSave={async () => {
                const res = await trigger(['squadDescription']);
                if (res) {
                  const { squadDescription } = getValues();
                  updateSquadMutation({
                    variables: {
                      input: {
                        id: squadId,
                        description: squadDescription,
                      },
                    },
                  });
                }
              }}
              error={error}
            >
              <FdTextField
                id="squadDescription"
                label=""
                placeholder="Squad description must be less than 80 characters."
                fullWidth
                multiline
                rows={3}
                error={error}
                helperText={error && error.message}
                value={value}
                {...rest}
                inputRef={ref}
              />
            </EditContainer>
          </Box>
        )}
      />
      <SquadUserCard
        heading="Squad Manager(s)"
        description="Squad Manager holds the power to add or remove Squad Member(s) and Squad Viewer(s) to a squad,  and  to view squad’s skill and performance data. Their own skills also contribute to the skills of the squad. Affiliated users can be Squad Managers for only one squad at a time."
        actionButtonLabel="Add SQUAD manager(s)"
        modalOptions={{
          heading: 'Add Squad Manager(s)',
          description:
            "Every squad created must have at least one Squad Manager assigned mandatorily. Squad Managers are chosen from the pool of affiliated users within an organisation with Manage Squad permission, and there's no upper limit to how many Squad Managers a squad can have.",
          selectHeading:
            'Select Squad Member(s) from list of Affiliated Users:',
          errorMessage: 'Please select at least one Squad Manager.',
        }}
        toastLabel="Squad Manager(s)"
        allUsers={allUsers}
        allSquadUsers={allSquadUsers}
        type="managers"
        onAddUser={onAddUser}
        onDeleteUser={onDeleteUser}
        isFunctionLead={isFunctionLead}
      />
      <SquadUserCard
        heading="Squad Member(s)"
        description="The skills of a Squad Member contribute to the skills and performance metrics of the squad. Affiliated users are limited to membership in a single squad within the organisation at any given moment. They will notice a marker on their Home Page signifying their squad association, but their access to squad details stops there."
        actionButtonLabel="Add SQUAD member(s)"
        modalOptions={{
          heading: 'Add Squad Member(s)',
          description: '',
          selectHeading: 'Select Squad Members from list of Affiliated Users:',
          errorMessage: 'Please select at least one Squad Member to continue.',
        }}
        toastLabel="Squad Member(s)"
        allUsers={allUsers}
        allSquadUsers={allSquadUsers}
        type="members"
        onAddUser={onAddUser}
        onDeleteUser={onDeleteUser}
        isFunctionLead={isFunctionLead}
      />
    </Box>
  );
};

SquadManagement.propTypes = {
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allSquadUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  isFunctionLead: PropTypes.bool.isRequired,
};

export default SquadManagement;
