import { gql } from '@apollo/client';
import {
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { listAffiliationsByOrgId } from '../queries/customQueries';

const useGetUsers = ({ permission } = {}) => {
  const { orgId } = useSnapshot(globalStore);
  const { data: registeredUsersData, loading: usersLoading } =
    useQueryRecursive(gql(listAffiliationsByOrgId), {
      variables: {
        orgId,
        status: { eq: 'ACTIVE' },
      },
      skip: !orgId,
      staleTime: { minutes: 15 },
    });
  const registeredUsers =
    registeredUsersData?.listAffliationsByOrgId?.items?.map((u) => ({
      id: u?.userId,
      ...u?.user,
    })) || [];

  const users = permission
    ? registeredUsers?.filter((user) => user?.permissions?.includes(permission))
    : registeredUsers;

  return {
    users,
    usersLoading,
  };
};

export default useGetUsers;
