import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import {
  FdTable,
  FdModal,
  FdButton,
  FdIconWithTooltip,
  FdExternalLink,
  FdTypography,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import { warningToastMessage } from '../../shared/utils/toast';
import { getCommaSeparatedPlusSuffix } from '../../shared/utils/stringUtils';
import { modalContentByType } from './utils';
import CompetitionsIcon from '../Affiliated/Icons/CompetitionsIcon';
import CoursesIcon from '../Affiliated/Icons/CoursesIcon';
import AssessmentsIcon from '../Affiliated/Icons/AssessmentsIcon';
import TrainingsIcon from '../Affiliated/Icons/TrainingsIcon';
import TableHeaderColumnWithTooltip from '../Affiliated/TableHeaderColumnWithTooltip';

const eventEngagement = {
  competitions: {
    icon: <CompetitionsIcon />,
  },
  assessments: {
    icon: <AssessmentsIcon />,
  },
  trainings: {
    icon: <TrainingsIcon />,
  },
  courses: {
    icon: <CoursesIcon />,
  },
};

const SquadUsersTable = ({
  rows,
  actionButtonLabel,
  onAddUser,
  onRemoveUser,
  type,
  isFunctionLead,
}) => {
  const globalSnap = useSnapshot(globalStore);
  const rowsDisplay = rows?.map((u) => {
    const userEvents =
      u?.assessments?.items?.map((a) => ({
        type: a?.assessment?.participantEventType,
        isProgress: a?.status !== 'NOT_STARTED',
        orgId: a?.assessment?.orgId,
      })) || [];

    const courses =
      u?.courses?.items
        ?.filter(
          ({ course }) =>
            course?.status === 'AVAILABLE' && course?.availability,
        )
        ?.map((course) => ({
          status: course?.course?.status,
          orgId: course?.course?.orgId,
          isProgress:
            course?.course?.modulePartProgresses?.items?.filter(
              (item) => item?.userId === u?.userId,
            )?.length > 0,
        })) || [];

    return {
      ...u,
      assignedEvents: {
        assessments: userEvents?.filter((event) => event.type === 'ASSESSMENT'),
        competitions: userEvents?.filter(
          (event) => event.type === 'COMPETITION',
        ),
        trainings: userEvents?.filter((event) => event.type === 'TRAINING'),
        courses,
      },
    };
  });

  const [userToDelete, setUserToDelete] = useState(null);
  const columns = [
    { field: 'name', width: 400, headerName: 'Name' },
    {
      field: 'email',
      width: 500,
      headerName: 'Email',
    },
    {
      field: 'permissions',
      width: 200,
      headerName: 'Permissions',
      valueGetter: (params) => params.value?.join(', '),
      renderCell: (params) =>
        getCommaSeparatedPlusSuffix(
          params.row.permissions?.map((p) => p),
          5,
        ),
    },
    {
      field: 'assignedEvents',
      width: 200,
      headerName: 'Event Engagement',
      searchable: false,
      filterable: false,
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Event Engagement"
          tooltipText="The Event Engagement column displays a comparison between the number of events initiated by the user and the number of events assigned to them. It's crucial to understand that this only includes events conducted by your organisation and excludes any events initiated or assigned to the user that are hosted by other organisations."
        />
      ),
      renderCell: (params) => {
        const assignedEvents = params?.row?.assignedEvents || [];
        return (
          <Box className="flex items-center gap-x-2">
            {Object.entries(assignedEvents).map(([key, _value]) => {
              const val = _value?.filter((i) => i?.orgId === globalSnap?.orgId);
              const participantStarted = val
                ?.map((item) => item?.isProgress)
                ?.filter((value) => value).length;
              const totalEvents = val?.length;
              return val?.length > 0 ? (
                <>
                  <IconButton size="small" style={{ marginLeft: '5px' }}>
                    {eventEngagement[key]?.icon}
                  </IconButton>
                  <FdTypography variant="captiontext2" color="secondary">
                    {`  ${participantStarted}/ ${totalEvents}`}
                  </FdTypography>
                </>
              ) : (
                <></>
              );
            })}
          </Box>
        );
      },
    },
  ];
  const modalContent = modalContentByType[type];
  const actions = [
    ...(isFunctionLead
      ? [
          {
            CustomElement: (row) => {
              const { rowData } = row;
              const onlyOneManager = type === 'managers' && rows?.length === 1;

              return (
                <Box className="mr-2">
                  <FdButton
                    size="small"
                    onClick={() => setUserToDelete(rowData)}
                    disabled={onlyOneManager}
                    variant="tertiary"
                  >
                    Remove
                  </FdButton>
                  {onlyOneManager && (
                    <FdIconWithTooltip title="You cannot remove the only Squad Manager for this squad . Add another Squad Manager first, and then try removing the Squad Manager you wish to remove." />
                  )}
                </Box>
              );
            },
            width: 250,
          },
        ]
      : []),
    {
      CustomElement: (row) => {
        const { rowData } = row;

        return (
          <Box>
            <FdExternalLink
              href={`/competitions/affiliated-view-user/${rowData?.userId}`}
              style={{
                fontSize: 12,
                fontWeight: 500,
                textDecoration: 'none',
              }}
            >
              VIEW PROFILE
            </FdExternalLink>
          </Box>
        );
      },
      width: 250,
    },
  ];

  return (
    <Box>
      <FdTable
        toolbarSettings={{
          title: '',
          filterButton: true,
          searchBox: true,
          headerActions: isFunctionLead
            ? [
                {
                  label: actionButtonLabel,
                  onClick: onAddUser,
                },
              ]
            : [],
        }}
        columnVisibilityModel={{
          permissions: isFunctionLead,
        }}
        rows={rowsDisplay || []}
        columns={columns}
        tablePageSize={10}
        actions={actions}
        gridId="squad-users"
        autoHeight
        hideFooter
      />
      <FdModal
        size="md"
        title={`Remove ${modalContent?.title}?`}
        description={modalContent?.description}
        confirm="CANCEL"
        dismiss="REMOVE"
        open={userToDelete}
        onConfirm={() => {
          setUserToDelete(null);
          warningToastMessage(`${modalContent?.title} not removed.`);
        }}
        onDismiss={() => {
          onRemoveUser(userToDelete, modalContent?.title);
          setUserToDelete(null);
        }}
      />
    </Box>
  );
};

SquadUsersTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  actionButtonLabel: PropTypes.string.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isFunctionLead: PropTypes.bool.isRequired,
};

export default SquadUsersTable;
