import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdCortex,
  cortexSort,
  getCortexLevels,
  getCortexCellColor,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import TickIcon from '../icons/TickIcon';
import ErrorIcon from '../icons/ErrorIcon';
import { proficiencyLevels } from '../../../constants';
import useGetSkills from '../../../hooks/useGetSkills';
import { getFulfilledUnits } from '../utils';

const ToolTip = ({
  skillName,
  proficiencyName,
  requiredUnits,
  fulfilledUnits,
  squadUsers,
  hasSkillTarget,
}) => (
  <Box>
    <FdTypography
      variant="captiontext2"
      color="contrastText"
      style={{ fontWeight: 600, marginBottom: '0.2rem' }}
    >
      {skillName}
    </FdTypography>
    <FdTypography variant="captiontext2" color="contrastText">
      {proficiencyName}
    </FdTypography>
    <Box mt={2}>
      <Box>
        {hasSkillTarget && requiredUnits && (
          <>
            <FdTypography variant="captiontext2" color="contrastText">
              Required Units: <strong>{requiredUnits}</strong>
            </FdTypography>
            ----------------------------------------
            <FdTypography variant="captiontext2" color="contrastText">
              Fulfilled Units: <strong>{fulfilledUnits}</strong>
            </FdTypography>
          </>
        )}
        {((!hasSkillTarget && squadUsers?.length > 0) ||
          (hasSkillTarget && fulfilledUnits > 0)) && (
          <Box mt={2}>
            <FdTypography variant="captiontext2" color="contrastText">
              Contributors:
            </FdTypography>
            <ul className="list-disc list-outside ml-4 text-white">
              {squadUsers?.map((su) => (
                <li key={su?.value}>
                  {`${su?.value} member${su?.value > 1 ? 's' : ''} from ${su?.name}.`}
                </li>
              ))}
            </ul>
          </Box>
        )}
      </Box>
      {hasSkillTarget && !requiredUnits && (
        <FdTypography variant="captiontext2" color="contrastText">
          Not required
        </FdTypography>
      )}
    </Box>
  </Box>
);

ToolTip.propTypes = {
  skillName: PropTypes.string.isRequired,
  proficiencyName: PropTypes.string.isRequired,
  requiredUnits: PropTypes.number.isRequired,
  fulfilledUnits: PropTypes.number.isRequired,
  squadUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasSkillTarget: PropTypes.bool.isRequired,
};

const defaultBorderWidth = '1px';
const targetBorder = 'rgba(0, 151, 167, 1)';
const targetBorderWidth = '2px';
const fillerBorder = 'rgba(77, 208, 225, 1)';

const FulfilledCortex = ({ data, loading, squads, ...props }) => {
  const {
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);
  const { skills, skillsLoading } = useGetSkills();
  const { targets, fillers, metrics } = data || {};

  const specialties = _(skills)
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items: items?.filter((s) => s?.alias),
    }))
    .value();
  const cortexLoading = skillsLoading || loading;

  const specialtiesFormatted =
    specialties?.sort(cortexSort)?.map((specialty, idx) => {
      const { levels = [], flip = false } = getCortexLevels(idx);

      const dataSorted = specialty?.items?.map((skill) => {
        const targetUnits = targets?.reduce(
          (acc, t) =>
            t?.skillAlias === skill?.alias ? acc + (t?.units || 0) : acc,
          0,
        );
        const skillMetrics = metrics?.filter(
          (m) => m?.skill?.alias === skill?.alias,
        );

        return {
          id: skill?.alias,
          idWidth: '40px',
          idDisplay: (
            <Box className="flex items-center">
              {targetUnits > 0 ? <strong>{skill?.alias}</strong> : skill?.alias}
            </Box>
          ),
          units: targetUnits > 0 ? targetUnits : null,
          data: [
            ...(levels?.map((l) => {
              const isTarget = targets?.some(
                (t) => t?.skillAlias === skill?.alias && t?.level === l,
              );
              const isFiller = fillers.some(
                (t) => t?.skillAlias === skill?.alias && t?.level === l,
              );
              const metricValues = skillMetrics?.filter(
                (m) => m?.difficulty === l,
              );
              const units =
                _.sumBy(
                  targets?.filter(
                    (t) => t?.skillAlias === skill?.alias && t?.level === l,
                  ),
                  'units',
                ) || '';

              const degradedScore = _.sumBy(metricValues, 'degradedScore') || 0;
              const fulfilledUnits = getFulfilledUnits(
                l,
                targets,
                skillMetrics,
                skill?.alias,
              );
              const metricUserIds = metricValues?.map((m) => m?.userId);
              const squadUsers = _.map(
                _.countBy(
                  squads
                    ?.map((s) =>
                      s?.members?.items?.map((m) => ({
                        userId: m?.userId,
                        name: s?.function?.name
                          ? `${s?.name}, ${s?.function?.name}`
                          : s?.name || m?.squadName,
                      })),
                    )
                    ?.flat()
                    ?.filter((s) => metricUserIds?.includes(s?.userId)),
                  'name',
                ),
                (value, key) => ({ name: key, value }),
              );

              return {
                x: l,
                yAlias: skill?.alias,
                yBorderColor: isTarget
                  ? targetBorder
                  : isFiller
                    ? fillerBorder
                    : getCortexCellColor(degradedScore)?.bgColor,
                yBorderWidth: isTarget ? targetBorderWidth : defaultBorderWidth,
                yValue: degradedScore,
                yHideValue: true,
                yFulfilledUnits: isTarget ? fulfilledUnits : 0,
                // actual fulfilled units as per level
                yFulfilledSummary: isTarget
                  ? fulfilledUnits > units
                    ? units
                    : fulfilledUnits
                  : 0,
                yToolTip: (
                  <ToolTip
                    skillName={`${skill?.name} (${skill?.alias})`}
                    proficiencyName={`Level ${l} - ${proficiencyLevels[l - 1]}`}
                    requiredUnits={units}
                    fulfilledUnits={fulfilledUnits}
                    squadUsers={squadUsers}
                    hasSkillTarget={hasSkillTarget}
                  />
                ),
              };
            }) || []),
          ],
        };
      });
      return { ...specialty, flip, levels, dataSorted };
    }) || [];

  const rowHeaders = specialtiesFormatted
    .map((s) =>
      s?.dataSorted?.map((ds) => {
        const fulfilledUnits = ds?.data?.reduce(
          (acc, d) =>
            d?.yFulfilledSummary ? acc + (d?.yFulfilledSummary || 0) : acc,
          0,
        );
        const fulfilledUnitsDisplay =
          fulfilledUnits > ds?.units ? ds?.units : fulfilledUnits;
        const targetFulfilled = fulfilledUnitsDisplay === ds?.units;

        return {
          id: ds?.id,
          headerContent: (
            <Box
              className="flex items-center justify-center gap-x-2"
              width={52}
            >
              {ds?.units && (
                <Box
                  className={`flex items-center gap-x-2 ${
                    !s?.flip ? 'flex-row-reverse' : 'flex-row'
                  }`}
                >
                  {targetFulfilled ? <TickIcon /> : <ErrorIcon />}
                  <Box width={36} className="text-center">
                    <FdTypography variant="captiontext2">{`(${fulfilledUnitsDisplay}/${ds?.units})`}</FdTypography>
                  </Box>
                </Box>
              )}
            </Box>
          ),
        };
      }),
    )
    .flat();

  return (
    <FdCortex
      specialties={specialtiesFormatted}
      rowHeaders={hasSkillTarget ? rowHeaders : []}
      loading={cortexLoading}
      {...props}
    />
  );
};

FulfilledCortex.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  squads: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FulfilledCortex;
