import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isWithinInterval, subMonths, format } from 'date-fns';
import { gql } from '@apollo/client';
import {
  useQueriesRecursive,
  FdCortex,
  getCortexLevels,
  cortexSort,
} from '@fifthdomain/fe-shared';
import { listTaskAttemptAggregatesByUserId } from '../../graphql/queries';
import useGetSkills from '../../hooks/useGetSkills';
import { PROFICIENCY_LEVELS } from '../../constants';

const degradationRate = {
  1: 2,
  2: 3,
  3: 5,
  4: 8,
  5: 13,
};

const CyberBrain = ({ allMemberIds, timeLine, cortexMemberList }) => {
  const { skills, skillsLoading } = useGetSkills();
  const specialties = _(skills)
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items: items?.filter((s) => s?.alias),
    }))
    .value();

  const {
    data: listTaskAttemptAggregatesByUserIdData,
    loading: listTaskAttemptAggregatesByUserIdLoading,
  } = useQueriesRecursive(gql(listTaskAttemptAggregatesByUserId), {
    variables: {
      limit: 1000,
    },
    recurringVariables: allMemberIds?.map((m) => ({
      userId: m,
    })),
  });

  const cortexUserIds = cortexMemberList
    ?.filter((m) => m.checked)
    ?.map((cm) => cm?.id);

  const listTaskAttemptAggregates =
    listTaskAttemptAggregatesByUserIdData?.listTaskAttemptAggregatesByUserId?.items?.filter(
      (u) => cortexUserIds?.includes(u?.userId),
    ) || [];

  const attemptsData = listTaskAttemptAggregates?.filter(
    (la) => la?.task?.skills?.items?.length > 0,
  );

  const attemptsDataUnique = _.uniqWith(
    attemptsData,
    (a, b) => a?.taskId === b?.taskId && a?.userId === b?.userId,
  );
  const attemptsDataTimeLineBased = timeLine
    ? attemptsDataUnique?.filter((a) =>
        isWithinInterval(new Date(a?.updatedAt), {
          start: new Date(timeLine?.startDate),
          end: new Date(timeLine?.endDate),
        }),
      )
    : attemptsDataUnique;
  const onlySolves = attemptsDataTimeLineBased?.filter(
    (a) => a?.status === 'COMPLETED',
  );

  // logic for level solves/effort
  // Ln = All solves/effort in Sum (Ln.. + L5)
  const getLevelPoints = (_level, _attempts) => {
    const levelEndDate2 = new Date();
    const levelStartDate2 = subMonths(levelEndDate2, degradationRate[_level]);

    return (
      _attempts?.filter(
        (a) =>
          a?.task?.difficulty === _level &&
          isWithinInterval(new Date(a?.updatedAt), {
            start: levelStartDate2,
            end: levelEndDate2,
          }),
      )?.length || 0
    );
  };

  const getDegradationScore = (_level, _attempts) => {
    let finalScore = 0;
    for (let index = _level; index <= 5; index += 1) {
      const levelEndDate = new Date();
      const levelStartDate = subMonths(levelEndDate, degradationRate[index]);

      const scoreInTimeFrame =
        _attempts?.filter(
          (a) =>
            a?.task?.difficulty === index &&
            isWithinInterval(new Date(a?.updatedAt), {
              start: levelStartDate,
              end: levelEndDate,
            }),
        )?.length || 0;

      finalScore += scoreInTimeFrame;
    }

    return finalScore > 0 ? finalScore : 0;
  };

  const specialtiesFormatted =
    specialties?.sort(cortexSort)?.map((specialty, idx) => {
      const { levels = [], flip = false } = getCortexLevels(idx);

      const dataSorted = specialty?.items?.map((skill) => {
        const onlySolvesUniqueByTaskId = _.uniqBy(onlySolves, 'taskId');
        const attempts =
          onlySolvesUniqueByTaskId?.filter((a) =>
            a?.task.skills.items?.some((s) =>
              s?.skill?.alias?.includes(skill?.alias),
            ),
          ) || [];

        const lastSolveDate = _.maxBy(
          attempts,
          (item) => new Date(item.updatedAt),
        )?.updatedAt;
        const lastSolveDateFormatted = lastSolveDate
          ? format(new Date(lastSolveDate), 'dd/MM/yyyy')
          : 'N/A';

        return {
          id: skill?.alias,
          idWidth: '30px',
          data: [
            ...(levels?.map((l) => {
              const levelPoints = getLevelPoints(l, attempts) || undefined;
              const contributors = _(onlySolves) // contributors shows all user solves
                .groupBy('userId')
                .map((items, userId) => {
                  const userName =
                    cortexMemberList?.find((u) => u?.id === userId)?.name ||
                    'Unknown';
                  const count = items?.length || 0;
                  return `${userName} (${count} Solve${count > 1 ? 's' : ''})`;
                })
                .value();
              const yContributors = levelPoints ? contributors : [];
              const yLabelLastSolve = levelPoints
                ? lastSolveDateFormatted
                : 'N/A';

              return {
                x: l,
                yAlias: skill?.alias,
                yValue: getDegradationScore(l, attempts) || 0,
                yValueDisplay: levelPoints || ' ', // send an empty space if no solves to show up in cortex
                yType: 'solves',
                yLevelPoints:
                  attempts?.filter((a) => a?.task?.difficulty === l)?.length ||
                  0,
                yToolTip: (
                  <Box className="flex flex-col my-1">
                    <Box>
                      <strong>Skill: </strong>
                      {`${skill?.name} (${skill?.alias})`}
                    </Box>
                    <Box>
                      <strong>Proficiency: </strong>
                      {`Level ${l} (${PROFICIENCY_LEVELS[l]})`}
                    </Box>
                    <Box>
                      <strong>
                        {yContributors?.length > 0
                          ? 'Solve Contributors:'
                          : 'Total Solved Challenges: '}
                      </strong>
                      <Box className="w-60">
                        {yContributors?.map((c) => (
                          <li key={c} className="ml-2">
                            {c}
                          </li>
                        ))}
                      </Box>
                    </Box>
                    <Box>
                      <strong>Last Solve: </strong>
                      {yLabelLastSolve}
                    </Box>
                  </Box>
                ),
              };
            }) || []),
          ],
        };
      });
      return { ...specialty, flip, levels, dataSorted };
    }) || [];

  const cortexLoading =
    skillsLoading || listTaskAttemptAggregatesByUserIdLoading;

  return (
    <FdCortex specialties={specialtiesFormatted} loading={cortexLoading} />
  );
};

CyberBrain.defaultProps = {
  timeLine: undefined,
};

CyberBrain.propTypes = {
  allMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  cortexMemberList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  timeLine: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
};

export default CyberBrain;
