import React, { useRef, useState } from 'react';
import { Box, Menu } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDrag, useDrop } from 'react-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FdTextField, FdTypography } from '@fifthdomain/fe-shared';

const useStyles = makeStyles()(() => ({
  optionContainer: {},
  menu: {
    '& p': {
      padding: '5px 10px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
}));

const ChoiceCard = ({
  choice,
  index,
  moveCard,
  handleDeleteChoice,
  handleCorrectQuestion,
  handleOptionupdate,
}) => {
  const [showSelect, setShowSelect] = useState(null);
  const { classes } = useStyles();
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'BOX',
    item: () => {
      return { id: choice.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));
  const [{ handlerId }, drop] = useDrop({
    accept: 'BOX',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const handleOpenSelect = (e) => {
    e.stopPropagation();
    setShowSelect(e.currentTarget);
  };
  const handleCloseSelect = () => {
    setShowSelect(null);
  };

  const opacity = isDragging ? 0.5 : 1;

  drag(drop(ref));
  return (
    <Box
      mb={2}
      display="flex"
      alignItems="center"
      width="100%"
      className={classes.optionContainer}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <Box mr={2}>
        <DragIndicatorIcon />
      </Box>
      {choice.correct && (
        <Box mr={2}>
          <CheckCircleIcon />
        </Box>
      )}
      <Box mr={2} width="100%">
        <FdTextField
          id="options-name"
          fullWidth
          name={choice.id}
          placeholder={`Answer option ${choice.id}`}
          value={choice.label}
          onChange={handleOptionupdate}
        />
      </Box>
      <MoreVertIcon onClick={handleOpenSelect} />
      <Menu
        anchorEl={showSelect}
        open={Boolean(showSelect)}
        onClose={handleCloseSelect}
        classes={{
          paper: classes.menu,
        }}
      >
        <FdTypography
          onClick={() => {
            handleCorrectQuestion(choice.id);
            setShowSelect(false);
          }}
        >
          Mark as {choice.correct ? 'Incorrect' : 'Correct'}
        </FdTypography>
        <FdTypography onClick={() => handleDeleteChoice(choice.id)}>
          Delete
        </FdTypography>
      </Menu>
    </Box>
  );
};

export default ChoiceCard;
