import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Box } from '@mui/material';
import {
  FdTypography,
  FdChip,
  FdAvatar,
  getAvatarColor,
  getAvatarName,
  FdExternalLink,
} from '@fifthdomain/fe-shared';

const SquadMemberList = ({
  allMembers,
  onMemberSelect,
  description,
  showColorBar,
}) => {
  return (
    <Box>
      <FdTypography variant="subtitle2">Squad Member Selection</FdTypography>
      <FdTypography variant="captiontext1" color="secondary">
        {description}
      </FdTypography>
      <Box my={1}>
        {allMembers?.map((option) => (
          <Box key={option.id} className="flex items-center gap-x-1 my-">
            <Checkbox
              checked={option.checked}
              onChange={() => onMemberSelect(option.id)}
            />
            <Box className="flex items-center">
              {showColorBar && (
                <Box
                  width={30}
                  height={5}
                  bgcolor={getAvatarColor(option.name)}
                  borderRadius="4px"
                  mr={1}
                />
              )}
              <FdAvatar
                color={getAvatarColor(option.name)}
                size="xsmall"
                variant="circular"
                content={getAvatarName(option.name) || ''}
              />
              <FdExternalLink
                href={`/competitions/affiliated-view-user/${option.id}`}
                style={{
                  fontWeight: 500,
                  textDecoration: 'underline',
                  paddingBottom: '4px',
                  margin: '0 1rem 0 0.5rem',
                }}
              >
                {option.name}
              </FdExternalLink>
              <FdChip
                size="small"
                label={option?.role}
                style={{
                  backgroundColor:
                    option?.role === 'Squad Manager'
                      ? 'rgba(179, 229, 252, 1)'
                      : 'rgba(239, 239, 239, 1)',
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

SquadMemberList.defaultProps = {
  showColorBar: false,
};

SquadMemberList.propTypes = {
  allMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onMemberSelect: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  showColorBar: PropTypes.bool,
};

export default SquadMemberList;
