import React from 'react';

const FunctionIcon = () => (
  <svg
    width="24"
    height="23"
    viewBox="0 0 24 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.8 15.0372C6.12 15.0372 7.2 16.1172 7.2 17.4372C7.2 18.7572 6.12 19.8372 4.8 19.8372C3.48 19.8372 2.4 18.7572 2.4 17.4372C2.4 16.1172 3.48 15.0372 4.8 15.0372ZM4.8 12.6372C2.16 12.6372 0 14.7972 0 17.4372C0 20.0772 2.16 22.2372 4.8 22.2372C7.44 22.2372 9.6 20.0772 9.6 17.4372C9.6 14.7972 7.44 12.6372 4.8 12.6372ZM12 3.03721C13.32 3.03721 14.4 4.11721 14.4 5.43721C14.4 6.75721 13.32 7.83721 12 7.83721C10.68 7.83721 9.6 6.75721 9.6 5.43721C9.6 4.11721 10.68 3.03721 12 3.03721ZM12 0.637207C9.36 0.637207 7.2 2.79721 7.2 5.43721C7.2 8.07721 9.36 10.2372 12 10.2372C14.64 10.2372 16.8 8.07721 16.8 5.43721C16.8 2.79721 14.64 0.637207 12 0.637207ZM19.2 15.0372C20.52 15.0372 21.6 16.1172 21.6 17.4372C21.6 18.7572 20.52 19.8372 19.2 19.8372C17.88 19.8372 16.8 18.7572 16.8 17.4372C16.8 16.1172 17.88 15.0372 19.2 15.0372ZM19.2 12.6372C16.56 12.6372 14.4 14.7972 14.4 17.4372C14.4 20.0772 16.56 22.2372 19.2 22.2372C21.84 22.2372 24 20.0772 24 17.4372C24 14.7972 21.84 12.6372 19.2 12.6372Z"
      fill="currentColor"
    />
  </svg>
);

export default FunctionIcon;
