import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import {
  FdCard,
  FdAlert,
  FdTypography,
  FdButton,
  FdModal,
} from '@fifthdomain/fe-shared';
import {
  successToastMessage,
  warningToastMessage,
} from '../../../shared/utils/toast';
import {
  updateFunction,
  updateSquad,
  updateSquadMember,
} from '../../../graphql/mutations';
import { onError, updateFunctionStatus } from '../utils';
import { useFormContext } from 'react-hook-form';

const DeactivateFunction = ({ refetchFunction }) => {
  const [showInactiveModal, setShowInactiveModal] = useState(false);
  const { getValues } = useFormContext();
  const { functionId, squads } = getValues();
  const [updateFunctionMutation, { loading: updateFunctionMutationLoading }] =
    useMutation(gql(updateFunction), {
      onError,
    });
  const [updateSquadMutation, { loading: updateSquadMutationLoading }] =
    useMutation(gql(updateSquad), {
      onError,
    });
  const [updateSquadUserMutation, { loading: updateSquadUserMutationLoading }] =
    useMutation(gql(updateSquadMember), {
      onError,
    });
  const loading =
    updateFunctionMutationLoading ||
    updateSquadMutationLoading ||
    updateSquadUserMutationLoading;

  const squadsForDeactivation = squads?.map((s) => ({
    squadId: s?.squadId,
    users: [
      ...(s?.squadMembers?.map((m) => m?.pkId) || []),
      ...(s?.squadManagers?.map((m) => m?.pkId) || []),
    ],
  }));

  return (
    <FdCard variant="outlined" sx={{ mt: 2 }}>
      <FdTypography variant="h3">Deactivate Function</FdTypography>
      <FdAlert
        variant="warning"
        alertTitle="Attention"
        message="When you deactivate a function, it loses its squads, including the users in those squads. The users then become available to be added to other functions, as they are no longer part of any squad. An inactive function retains its target skills data and Function Lead details. However, the Function Lead will lose access to this function immediately upon deactivation."
        sx={{ mt: 2 }}
      />
      <FdButton
        variant="secondary"
        style={{
          marginTop: '1rem',
          color: 'rgba(198, 40, 40, 1)',
          borderColor: 'rgba(198, 40, 40, 1)',
        }}
        onClick={() => setShowInactiveModal(true)}
      >
        Make Function Inactive
      </FdButton>
      <FdModal
        title="Make this function inactive?"
        description={
          <>
            Are you sure you want to make this function inactive?
            <br />
            <br />
            If you proceed, data for all squads in the function and their
            associated members will be lost. An inactive function retains its
            target skills data and Function Lead details. However, the Function
            Lead will lose access to this function immediately upon
            deactivation.
            <br />
            <br />
            Please ensure this is your intended action, as it cannot be undone.
          </>
        }
        confirm="Make function inactive"
        showConfirmInRed
        dismiss="Cancel"
        open={showInactiveModal}
        disableConfirm={loading}
        confirmLoading={loading}
        onConfirm={() => {
          updateFunctionStatus({
            updateFunctionMutation,
            updateSquadMutation,
            updateSquadUserMutation,
            functionId,
            squads: squadsForDeactivation,
            onCompleted: () => {
              setShowInactiveModal(false);
              successToastMessage(
                'Function is now inactive, and its squads are disbanded. The former squad members can now be added to another function.',
              );
              refetchFunction();
            },
            status: 'INACTIVE',
          });
        }}
        onDismiss={() => {
          setShowInactiveModal(false);
          warningToastMessage('Function not made inactive.');
        }}
      />
    </FdCard>
  );
};

export default DeactivateFunction;
