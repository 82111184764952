import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdTextField,
  FdButton,
  FdTypography,
} from '@fifthdomain/fe-shared';
import { warningToastMessage } from '../../../shared/utils/toast';

const FunctionDetails = ({ isEditMode, canManageFunctions, onClickSave }) => {
  const [editInProgress, setEditInProgress] = useState(false);
  const { getValues, control, reset, trigger } = useFormContext();
  return (
    <FdCard
      variant="outlined"
      heading={
        isEditMode ? (
          <Box display="flex" justifyContent="space-between">
            <FdTypography variant="h3">Details</FdTypography>
            {canManageFunctions && (
              <Box display="flex" justifyContent="space-between">
                {editInProgress ? (
                  <Box display="flex">
                    <Box pr={1}>
                      <FdButton
                        variant="secondary"
                        size="small"
                        onClick={() => {
                          reset();
                          setEditInProgress(false);
                          warningToastMessage('Changes to function not saved');
                        }}
                      >
                        CANCEL
                      </FdButton>
                    </Box>
                    <Box>
                      <FdButton
                        variant="primary"
                        size="small"
                        onClick={async () => {
                          if (
                            await trigger(['groupName', 'groupDescription'])
                          ) {
                            setEditInProgress(false);
                            onClickSave?.();
                          }
                        }}
                      >
                        Save
                      </FdButton>
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={() => setEditInProgress(true)}
                    >
                      Edit
                    </FdButton>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <FdTypography variant="h3">Add Function Details</FdTypography>
        )
      }
    >
      <Box display="flex" flexDirection="column">
        {!isEditMode || (isEditMode && editInProgress) ? (
          <Box>
            <Controller
              control={control}
              name="functionName"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1} mb={2}>
                  <FdTextField
                    id="functionName"
                    label="Function Name"
                    required
                    fullWidth
                    error={error}
                    placeholder="Function name must be less than 50 characters."
                    helperText={error && error.message}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Box mt={0} mb={0}>
              <Controller
                control={control}
                name="functionDescription"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => (
                  <Box mb={2}>
                    <FdTextField
                      id="functionDescription"
                      label="Function Description"
                      placeholder="Function description must be less than 80 characters."
                      fullWidth
                      multiline
                      rows={3}
                      error={error}
                      helperText={error && error.message}
                      {...rest}
                      inputRef={ref}
                    />
                  </Box>
                )}
              />
            </Box>
          </Box>
        ) : (
          <Box style={{ whiteSpace: 'pre-wrap' }}>
            <Box>
              <FdTypography variant="subtitle1">Function Name</FdTypography>
              <FdTypography variant="body1" color="secondary">
                {getValues('functionName')}
              </FdTypography>
            </Box>
            <Box mt={2}>
              <FdTypography variant="subtitle1">
                Function Description
              </FdTypography>
              <FdTypography variant="body1" color="secondary">
                {getValues('functionDescription') || 'No description provided.'}
              </FdTypography>
            </Box>
          </Box>
        )}
      </Box>
    </FdCard>
  );
};

FunctionDetails.propTypes = {
  isEditMode: PropTypes.bool,
  onClickSave: PropTypes.func,
  canManageFunctions: PropTypes.bool,
};

FunctionDetails.defaultProps = {
  isEditMode: false,
  onClickSave: undefined,
  canManageFunctions: false,
};

export default FunctionDetails;
