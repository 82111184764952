import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useSnapshot, globalStore } from '@fifthdomain/fe-shared';
import TickIcon from '../icons/TickIcon';
import ErrorIcon from '../icons/ErrorIcon';
import FulfilledCortex from './FulFilledCortex';
import LegendElement from '../Fragments/LegendElement';

const FunctionCortex = ({ data, loading, squads }) => {
  const {
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);

  return (
    <FulfilledCortex
      data={data}
      loading={loading}
      squads={squads}
      legends={
        hasSkillTarget && (
          <Box mt={5} className="flex items-center justify-center gap-x-3">
            <LegendElement Icon={TickIcon} label="Fulfilled Skill (Current)" />
            <LegendElement
              Icon={ErrorIcon}
              label="Unfulfilled Skill (Current)"
            />
          </Box>
        )
      }
    />
  );
};

FunctionCortex.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  squads: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FunctionCortex;
