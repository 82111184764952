import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Box, Divider, Link, Checkbox } from '@mui/material';
import {
  FdTypography,
  FdAccordion,
  FdAvatar,
  getAvatarColor,
  getAvatarName,
  FdCheckbox,
  FdButton,
} from '@fifthdomain/fe-shared';
import { SQUAD_ROLES } from '../../../constants';

const SquadAccordion = ({ onEditClick, idx, canEdit }) => {
  const { setValue, control, watch } = useFormContext();
  const squadId = `squads[${idx}]`;
  const squad = watch(squadId);
  const { squadName, squadManagers, squadMembers } = squad || {};
  const allMembers = [
    ...(squadManagers?.map((m, mIdx) => ({
      name: m?.name,
      role: SQUAD_ROLES.MANAGER,
      selected: m?.selected,
      userId: m?.userId,
      mIdx,
    })) || []),
    ...(squadMembers?.map((m, mIdx) => ({
      name: m?.name,
      role: SQUAD_ROLES.MEMBER,
      selected: m?.selected,
      userId: m?.userId,
      mIdx,
    })) || []),
  ]?.map((m) => ({
    ...m,
    avatarName: getAvatarName(m?.name),
    avatarColor: getAvatarColor(m?.name),
  }));
  const summaryText = `${allMembers?.length || 0} Contributing Members`;
  const selectedMembers = allMembers?.filter((m) => m?.selected) || [];
  const setAllSelected = (status) => {
    allMembers?.forEach((m) => {
      setValue(
        `${squadId}.${
          m.role === SQUAD_ROLES.MANAGER ? 'squadManagers' : 'squadMembers'
        }[${m.mIdx}].selected`,
        status,
      );
    });
  };

  return (
    <Box mt={2}>
      <FdAccordion
        startAdornment
        showToggleButton={false}
        summary={() => (
          <Box>
            <FdTypography variant="body1">{squadName}</FdTypography>
            <Box className="flex items-center gap-x-1">
              <FdTypography variant="captiontext1">{summaryText}</FdTypography>
              <FdTypography variant="captiontext1" color="secondary">
                {`(${
                  selectedMembers.length === 0 ? 'none' : selectedMembers.length
                } selected)`}
              </FdTypography>
            </Box>
          </Box>
        )}
        actions={
          canEdit && (
            <FdButton
              variant="secondary"
              onClick={() => onEditClick({ ...squad, idx })}
            >
              Edit
            </FdButton>
          )
        }
        content={() => (
          <Box ml={1}>
            <Divider />
            <Box ml={1.5}>
              <FdCheckbox
                label="Select All"
                checked={selectedMembers.length === allMembers?.length}
                onChange={(checked) => {
                  setAllSelected(checked);
                }}
              />
            </Box>
            <Divider sx={{ width: '185px' }} />
            {allMembers?.map((member) => (
              <Box className="flex items-center gap-x-2">
                <Controller
                  control={control}
                  name={`squads[${idx}].${
                    member.role === SQUAD_ROLES.MANAGER
                      ? 'squadManagers'
                      : 'squadMembers'
                  }[${member.mIdx}].selected`}
                  render={({ field: { ref, value, ...fieldProps } }) => (
                    <Checkbox inputRef={ref} checked={value} {...fieldProps} />
                  )}
                />
                <FdAvatar
                  size="xsmall"
                  content={member.avatarName}
                  color={member.avatarColor}
                />
                <Link
                  style={{ color: '#3333FF' }}
                  onClick={() =>
                    window.open(
                      `/competitions/affiliated-view-user/${member?.userId}`,
                      '_blank',
                    )
                  }
                >
                  <FdTypography variant="body1" color="#3333FF">
                    {member.name}
                  </FdTypography>
                </Link>
                <Box
                  className="rounded-xl px-2 py-0.5"
                  sx={{
                    backgroundColor:
                      member.role === SQUAD_ROLES.MANAGER
                        ? 'rgba(179, 229, 252, 1)'
                        : 'rgba(239, 239, 239, 1)',
                  }}
                >
                  <FdTypography variant="captiontext1">
                    {member.role}
                  </FdTypography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      />
    </Box>
  );
};

SquadAccordion.defaultProps = {
  canEdit: true,
};

SquadAccordion.propTypes = {
  header: PropTypes.node.isRequired,
  squad: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  onEditClick: PropTypes.func.isRequired,
  canEdit: PropTypes.bool,
  idx: PropTypes.number.isRequired,
};

export default SquadAccordion;
