import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthSession } from '@fifthdomain/fe-shared';

const UserManagementQuizRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <Component {...rest} {...props} />;
        }
        return null;
      }}
    />
  );
};

UserManagementQuizRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default UserManagementQuizRoute;
