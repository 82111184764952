import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdButton,
  FdCard,
  FdTable,
  FdModal,
  FdAlert,
} from '@fifthdomain/fe-shared';
import AssignFunctionLeads from './AssignFunctionLeads';
import {
  successToastMessage,
  warningToastMessage,
} from '../../../shared/utils/toast';
import {
  createUserFunction,
  deleteUserFunction,
} from '../../../graphql/mutations';
import { onError, updateFunctionLeads } from '../utils';

const FunctionLeadsTable = ({
  loading,
  refetchFunction,
  showError,
  canManageFunctions,
}) => {
  const { getValues } = useFormContext();
  const { functionLeads, functionId, functionLeadsSelection } = getValues();
  const [showFunctionLeads, setShowFunctionLeads] = useState(false);
  const [showRemoveFunctionLead, setShowRemoveFunctionLead] = useState(false);
  const [deleteUserFunctionMutation, { loading: deleteUserFunctionLoading }] =
    useMutation(gql(deleteUserFunction), {
      onError,
    });

  const [createUserFunctionMutation, { loading: createUserFunctionLoading }] =
    useMutation(gql(createUserFunction), {
      onError,
    });
  const rows = functionLeads || [];
  const columns = [
    { field: 'name', width: 350, headerName: 'Name' },
    {
      field: 'email',
      width: 400,
      headerName: 'Email',
    },
  ];

  const actions = [
    {
      label: 'Remove',
      onClick: (row) => {
        setShowRemoveFunctionLead(row);
      },
    },
  ];

  return (
    <FdCard variant="outlined" sx={{ mt: 2 }}>
      <FdTypography variant="h3">Assign Function Lead(s)</FdTypography>
      {showError && (
        <Box mt={1}>
          <FdAlert
            variant="error"
            message="Please select at least one Function lead."
          />
        </Box>
      )}
      <FdTypography variant="body2" color="secondary" sx={{ mt: 2 }}>
        Every function created must have at least one Function Lead assigned.
        The appointed function lead holds the power to edit the target work
        roles for the function, and add or remove other leads.
        <br />
        <br />
        The Function Lead must be chosen from the pool of affiliated users
        within the organisation who possess the &apos;Manage Squads&apos;
        permission.
      </FdTypography>
      <FdCard variant="outlined" sx={{ mt: 2 }}>
        <Box className="flex items-center justify-between">
          <FdTypography variant="subtitle1" sx={{ fontWeight: 600 }}>
            Function Lead(s) for this function
          </FdTypography>
          {canManageFunctions && (
            <FdButton onClick={() => setShowFunctionLeads(true)}>
              Add function leads
            </FdButton>
          )}
        </Box>
        <Box mt={2}>
          <FdTable
            toolbarSettings={{
              title: '',
              filterButton: true,
              searchBox: true,
            }}
            rows={rows}
            columns={columns}
            tablePageSize={10}
            actions={canManageFunctions ? actions : []}
            loading={
              loading || deleteUserFunctionLoading || createUserFunctionLoading
            }
            hideFooter
            autoHeight
          />
        </Box>
      </FdCard>
      <AssignFunctionLeads
        show={showFunctionLeads}
        setShow={setShowFunctionLeads}
        onConfirm={() => {
          setShowFunctionLeads(false);
          updateFunctionLeads({
            userMutation: createUserFunctionMutation,
            functionId,
            functionLeads: functionLeadsSelection,
            onCompleted: () => {
              successToastMessage('Success! Function Lead(s) added.');
              refetchFunction();
            },
          });
        }}
      />
      <FdModal
        size="md"
        title="Remove Function Lead?"
        description="This will remove the Function Lead from the function, making it unavailable for the Function Lead to access the function."
        confirm="Remove"
        dismiss="Cancel"
        confirmStyles={{ backgroundColor: 'rgba(198, 40, 40, 1)' }}
        open={showRemoveFunctionLead}
        onConfirm={() => {
          setShowRemoveFunctionLead(false);
          deleteUserFunctionMutation({
            variables: {
              input: {
                id: showRemoveFunctionLead?.pkId,
              },
            },
            onCompleted: () => {
              successToastMessage(
                'Success! Function Lead is now removed. Function Lead will not be able to access the function.',
              );
              refetchFunction();
            },
          });
        }}
        onDismiss={() => {
          setShowRemoveFunctionLead(false);
          warningToastMessage('Function Lead not removed.');
        }}
        disableConfirm={deleteUserFunctionLoading}
      />
    </FdCard>
  );
};

FunctionLeadsTable.propTypes = {
  loading: PropTypes.bool,
  refetchFunction: PropTypes.func.isRequired,
};

FunctionLeadsTable.defaultProps = {
  loading: false,
};

export default FunctionLeadsTable;
