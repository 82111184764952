import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { Box } from '@mui/material';
import { FdTable, FdChip, FdTypography, FdCard } from '@fifthdomain/fe-shared';
import { capitalize } from '../../shared/utils/stringUtils';
import { TAG_COLOUR } from '../../constants';
import { updateTag as updateTagDirectory } from '../../graphql/mutations';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import CreateTag from './CreateTag';

const TagsTable = ({ status, tags, onEditRefetch }) => {
  const [tagDetails, setTagDetails] = useState({});
  const [editTag, setEditTag] = useState(false);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const subHeading = (_status) => {
    switch (_status) {
      case 'all':
        return 'All active user and challenge tags are contained in the list below.';
      case 'archived':
        return 'Archived tags can no longer be applied to users or challenges. Unarchive tags in this list if they need to be further applied to users or challenges.';
      default:
        return `Active tags that are assignable to ${status} are contained in the list below. `;
    }
  };

  const [updateTagDirectoryMutation] = useMutation(gql(updateTagDirectory), {
    onCompleted: () => {
      setEditTag(false);
      successToastMessage('Success! Tag details updated');
      onEditRefetch();
    },
  });

  const [archiveTagDirectoryMutation] = useMutation(gql(updateTagDirectory));

  const getTagColor = (label) => {
    const color = TAG_COLOUR.find((c) => c.value.includes(label));
    return color.backgroundColor;
  };

  const archiveTags = async (selectedTagIds) => {
    if (selectedTagIds.length > 0 && tags.length > 0) {
      const selectedRows = tags.filter((row) =>
        selectedTagIds.includes(row.id),
      );

      const archiveStatus = status === 'archived' ? 'READY' : 'ARCHIVED';

      if (selectedRows && selectedRows.length > 0) {
        const promises = [];
        selectedRows.forEach((row) => {
          promises.push(
            archiveTagDirectoryMutation({
              variables: {
                input: {
                  id: row.id,
                  userId: row.userId,
                  orgId: row.orgId,
                  status: archiveStatus,
                },
              },
            }),
          );
        });
        await Promise.all(promises);

        setEditTag(false);
        successToastMessage('Success! Tag details updated');
        onEditRefetch();
      }
    }
  };

  const handleSelectionChange = (newSelections) => {
    setSelectedRowIds(newSelections);
  };

  const columns = [
    {
      field: 'tagName',
      width: 300,
      headerName: 'Tag',
      valueGetter: (params) => params?.value,
      renderCell: (params) => {
        return (
          <FdChip
            size="small"
            label={params?.value}
            style={{ backgroundColor: getTagColor(params?.row?.tagColor) }}
          />
        );
      },
    },
    {
      field: 'tagType',
      width: 200,
      headerName: 'Tag Type',
      valueGetter: (params) => params?.value?.value,
      hide: ['user', 'challenge'].includes(status),
    },
    {
      field: 'tagDescription',
      width: 300,
      headerName: 'Tag Description',
    },
    {
      field: 'tagCreator',
      width: 300,
      headerName: 'Tag Creator',
    },
    {
      field: 'tagDateCreated',
      width: 300,
      headerName: 'Date Created',
    },
    {
      field: 'tagCount',
      width: 200,
      type: 'number',
      headerName: 'Tag Count',
    },
  ];

  const actions = [
    {
      label: 'Edit',
      show: () => status !== 'archived',
      onClick: (row) => {
        setTagDetails(row);
        setEditTag(true);
      },
    },
    {
      label: status !== 'archived' ? 'Archive' : 'Unarchive',
      show: () => true,
      onClick: (row) => {
        if (row) {
          archiveTags([row.id]);
        }
      },
    },
    {
      show: () => false, // this is to show the drop down instead of buttons
    },
  ];

  const tagsByType = tags?.filter(
    (tag) =>
      (status === 'all' && tag.status === 'READY') ||
      (status === 'archived' && tag.status === 'ARCHIVED') ||
      (tag?.tagType?.value?.includes(capitalize(status)) &&
        tag.status === 'READY'),
  );

  return (
    <FdCard>
      <Box display="flex" flexDirection="column" mb={2}>
        <FdTypography variant="h3">{`${capitalize(status)} Tags`}</FdTypography>
        <Box mt={1}>
          <FdTypography variant="body2" color="secondary">
            {subHeading(status)}
          </FdTypography>
        </Box>
      </Box>
      <Box height="715px" bgcolor="#fff">
        <FdTable
          toolbarSettings={{
            filterButton: true,
            headerActions:
              selectedRowIds?.length > 0
                ? [
                    {
                      label:
                        status === 'archived'
                          ? 'BULK UNARCHIVE'
                          : 'BULK ARCHIVE',
                      onClick: () => {
                        archiveTags(selectedRowIds);
                      },
                    },
                  ]
                : [],
            searchBox: true,
          }}
          rows={tagsByType}
          columns={columns}
          actions={actions}
          tablePageSize={10}
          selection
          onSelectionModelChange={handleSelectionChange}
          selectionModel={selectedRowIds}
          gridId="usermanagement-list-tags"
        />

        <CreateTag
          openModal={editTag}
          onConfirm={async ({ id, tagName, tagDescription, tagColor }) => {
            const previousValue = tagDetails?.tagName.toLowerCase();
            const duplicateTagName = tags
              ?.map((tag) => tag?.tagName.toLowerCase())
              ?.includes(tagName.toLowerCase());

            if (previousValue !== tagName.toLowerCase()) {
              if (duplicateTagName) {
                warningToastMessage('Tag name already exists');
                return;
              }
            }
            // edit tag directory
            await updateTagDirectoryMutation({
              variables: {
                input: {
                  id,
                  color: tagColor || 'YELLOW',
                  name: tagName,
                  description: tagDescription,
                },
              },
            });
          }}
          onDismiss={() => {
            warningToastMessage('No edits to tag made');
          }}
          setOpenModal={setEditTag}
          tagDetails={tagDetails}
          isEdit
        />
      </Box>
    </FdCard>
  );
};

TagsTable.propTypes = {
  status: PropTypes.string.isRequired,
  onEditRefetch: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.objectOf({})).isRequired,
};

export default TagsTable;
