import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as singleSpa from 'single-spa';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { FdCard, useSnapshot, globalStore } from '@fifthdomain/fe-shared';
import FunctionSummary from '../components/Functions/FunctionSummary';
import FunctionDashboard from '../components/Functions/FunctionDashboard';
import FunctionSkillsProfile from '../components/Functions/FunctionSkillsProfile';
import useGetFunctions from '../hooks/useGetFunctions';
import useGetSquads from '../hooks/useGetSquads';
import {
  initialValues,
  getValidationSchema,
} from '../validation-schemas/Functions/listFunctions';
import useGetUserMetrics from '../hooks/useGetUserMetrics';

const ListFunctions = ({ canManageFunctions }) => {
  const { userId } = useSnapshot(globalStore);
  const { allFunctions, allFunctionsLoading } = useGetFunctions();
  const { squads, squadsLoading } = useGetSquads();
  const validationSchema = getValidationSchema();
  const hookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const userIds =
    _.uniq(
      squads
        ?.map(
          (s) =>
            s?.members?.items
              ?.filter((m) => m?.status === 'ACTIVE')
              ?.map((m) => m?.userId) || [],
        )
        .flat(),
    ) || [];
  const { userMetrics, userMetricsLoading } = useGetUserMetrics({
    userIds,
  });
  const { reset } = hookFormMethods;
  const loading = allFunctionsLoading || squadsLoading || userMetricsLoading;

  useEffect(() => {
    if (!allFunctionsLoading && !squadsLoading) {
      const functions = allFunctions?.map((fn) => ({
        ...fn,
        squads: squads?.filter((fs) => fs?.functionId === fn?.id) || [],
      }));
      const allActiveFunctions = functions.filter(
        (fn) => fn?.status === 'ACTIVE',
      );
      const activeFunctions = canManageFunctions
        ? allActiveFunctions
        : allActiveFunctions.filter((fn) =>
            fn?.functionLeads?.items?.some((l) => l?.userId === userId),
          );
      // check if they are not function leads and they are a squad manager to a squad
      if (!canManageFunctions && activeFunctions.length === 0) {
        const managingSquadId = allActiveFunctions
          ?.map((fn) => fn?.squads)
          ?.flat()
          ?.find((s) =>
            s?.members?.items?.some(
              (m) =>
                m?.userId === userId &&
                m?.role === 'MANAGER' &&
                m?.status === 'ACTIVE',
            ),
          )?.id;
        // navigate to their squad
        if (managingSquadId) {
          singleSpa.navigateToUrl(
            `/user-management/squads/view/${managingSquadId}`,
          );
        }
      }

      reset({
        activeFunctions,
        inactiveFunctions: functions.filter((fn) => fn?.status === 'INACTIVE'),
        selectedFunctions: activeFunctions,
        allFunctions: allActiveFunctions,
      });
    }
  }, [
    allFunctions,
    allFunctionsLoading,
    canManageFunctions,
    reset,
    squads,
    squadsLoading,
    userId,
  ]);

  return (
    <FormProvider {...hookFormMethods}>
      <form>
        <FunctionSummary loading={loading} userMetrics={userMetrics} />
        <Box mt={2}>
          <FdCard variant="outlined">
            <Box className="flex w-full gap-x-9">
              <Box
                width="55%"
                sx={{ backgroundColor: '#FAFAFA', borderRadius: '8px' }}
              >
                <FunctionSkillsProfile
                  loading={loading}
                  userMetrics={userMetrics}
                />
              </Box>
              <Box width="45%">
                <FunctionDashboard
                  loading={loading}
                  canManageFunctions={canManageFunctions}
                  userMetrics={userMetrics}
                />
              </Box>
            </Box>
          </FdCard>
        </Box>
      </form>
    </FormProvider>
  );
};

ListFunctions.propTypes = {
  canManageFunctions: PropTypes.bool.isRequired,
};

export default ListFunctions;
