import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdTypography,
  FdButton,
  FdAlert,
  Authorization,
} from '@fifthdomain/fe-shared';
import UserPickerModal from './UserPickerModal';
import { sortObjectArrayByField } from '../../shared/utils/objectUtils';
import SquadUsersTable from './SquadUsersTable';
import { successToastMessage } from '../../shared/utils/toast';
import { titleCaseToSnakeCase } from '../../shared/utils/stringUtils';

const SquadUserCard = ({
  heading,
  description,
  actionButtonLabel,
  optional,
  modalOptions,
  toastLabel,
  allUsers,
  allSquadUsers,
  type,
  onAddUser,
  onDeleteUser,
  isFunctionLead,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const {
    control,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext();

  const { fields: currentUsers, remove: removeUser } = useFieldArray({
    control,
    name: type,
  });
  const allCurrentUserIds = [...getValues('managers'), ...getValues('members')]
    .flat()
    ?.map((u) => u?.userId);

  const filterUsers = (_user) => {
    const userPermissions = _user?.permissions?.map((p) =>
      titleCaseToSnakeCase(p),
    );
    if (type === 'managers') {
      return Authorization.canManageSquads(userPermissions);
    }
    return _user;
  };

  const usersForPicker = allUsers
    // exclude users who are already present in the squad
    ?.filter((u) => !allCurrentUserIds?.includes(u?.userId))
    // exclude users who are already part of an existing squad
    ?.filter((u) => !allSquadUsers?.includes(u?.userId))
    ?.filter(filterUsers)
    ?.sort(sortObjectArrayByField('name', 'asc'));

  const onUsersSelected = async (_usersSelected) => {
    setValue(type, [
      ...currentUsers,
      ...(_usersSelected?.map((u) => ({
        ...u,
        role: type.slice(0, -1).toUpperCase(),
      })) || []),
    ]);
    const res = await trigger(type);
    if (res) {
      onAddUser?.(_usersSelected, type);
    }
  };

  const onAddClick = () => {
    setOpenModal(true);
  };

  const onRemoveUser = (row, label) => {
    removeUser(row?.index);
    if (onDeleteUser) {
      onDeleteUser(row, type);
    } else {
      successToastMessage(`Success! ${label} removed.`);
    }
  };

  return (
    <Box mb={1}>
      <FdCard variant="outlined">
        <Box className="flex items-center gap-x-2">
          <FdTypography variant="h3">{heading}</FdTypography>
          {optional && (
            <FdTypography variant="body1" color="secondary">
              optional
            </FdTypography>
          )}
        </Box>
        {type === 'managers' && errors?.managers && (
          <Box mt={1}>
            <FdAlert
              variant="error"
              message="Please select at least one Squad Manager to create a Squad."
            />
          </Box>
        )}
        <Box my={2}>
          <FdTypography variant="body1" color="secondary">
            {description}
          </FdTypography>
        </Box>
        {currentUsers?.length > 0 ? (
          <SquadUsersTable
            rows={currentUsers?.map((u, i) => ({ ...u, index: i }))}
            actionButtonLabel={actionButtonLabel}
            onAddUser={onAddClick}
            onRemoveUser={onRemoveUser}
            type={type}
            isFunctionLead={isFunctionLead}
          />
        ) : (
          isFunctionLead && (
            <FdButton onClick={onAddClick}>{actionButtonLabel}</FdButton>
          )
        )}
      </FdCard>
      <UserPickerModal
        open={openModal}
        setOpen={setOpenModal}
        options={usersForPicker}
        heading={modalOptions?.heading}
        description={modalOptions?.description}
        selectHeading={modalOptions?.selectHeading}
        errorMessage={modalOptions?.errorMessage}
        optional={optional}
        toastLabel={toastLabel}
        onUsersSelected={onUsersSelected}
        isEdit
      />
    </Box>
  );
};

SquadUserCard.defaultProps = {
  optional: false,
  onAddUser: undefined,
  onDeleteUser: undefined,
};

SquadUserCard.propTypes = {
  modalOptions: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    selectHeading: PropTypes.string,
    errorMessage: PropTypes.string,
  }).isRequired,
  optional: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionButtonLabel: PropTypes.string.isRequired,
  toastLabel: PropTypes.string.isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allSquadUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  type: PropTypes.string.isRequired,
  onAddUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  isFunctionLead: PropTypes.bool.isRequired,
};

export default SquadUserCard;
