import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { gql } from '@apollo/client';
import {
  addWeeks,
  differenceInCalendarWeeks,
  isWithinInterval,
} from 'date-fns';
import { Box, Divider, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdCard,
  FdTypography,
  FdTooltip,
  useQueriesRecursive,
} from '@fifthdomain/fe-shared';
import CyberBrain from '../Cortex/CyberBrain';
import PerformanceTriangle from './PerformanceTriangle';
import TimePeriodFilter from './TimePeriodFilter';
import { getArrayByLength } from '../../shared/utils/objectUtils';
import { SKILLS_PERFORMANCE_TIMELINE_START_DATE } from '../../constants';
import SquadMemberList from './SquadMemberList';
import { listUserScoreboardsByUserId } from '../../graphql/queries';

const CardHeading = ({ heading, tooltipText, subHeading }) => (
  <Box>
    <Box className="flex">
      <FdTypography variant="h3">{heading}</FdTypography>
      {tooltipText && (
        <FdTooltip title={tooltipText}>
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </FdTooltip>
      )}
    </Box>
    <FdTypography
      variant="body1"
      color="secondary"
      style={{ marginTop: '0.5rem', textAlign: 'justify' }}
    >
      {subHeading}
    </FdTypography>
  </Box>
);

CardHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};

const getAverage = (objectArray, columnsToAverage) => {
  const average = _.reduce(
    objectArray,
    (result, item) => {
      _.forEach(columnsToAverage, (key) => {
        if (_.isNumber(item[key])) {
          // eslint-disable-next-line no-param-reassign
          result[key] = (result[key] || 0) + item[key];
        }
      });
      return result;
    },
    {},
  );
  _.forEach(average, (value, key) => {
    average[key] = parseFloat((value / (objectArray?.length || 1)).toFixed(2));
  });

  return average;
};

const GraphBar = ({ label, color }) => (
  <Box className="flex items-center gap-x-2">
    <Box
      height="6px"
      width="30px"
      style={{
        backgroundColor: color,
        borderRadius: '4px',
      }}
    />
    <FdTypography variant="captiontext1" color="secondary">
      {label}
    </FdTypography>
  </Box>
);

GraphBar.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

const SquadSkills = ({ squadMembers }) => {
  const [cortexValueRange, setCortexValueRange] = useState([0, 100]);
  const [triangleValueRange, setTriangleValueRange] = useState([0, 100]);
  const [cortexMemberList, setCortexMemberList] = useState([]);
  const [perfTriangleMemberList, setPerfTriangleMemberList] = useState([]);
  const allMemberIds = squadMembers?.map((m) => m?.id);

  const { data: userScoreboardsData } = useQueriesRecursive(
    gql(listUserScoreboardsByUserId),
    {
      variables: {
        limit: 1000,
      },
      recurringVariables: allMemberIds?.map((m) => ({
        userId: m,
      })),
    },
  );
  const noOfWeeks = differenceInCalendarWeeks(
    new Date(),
    SKILLS_PERFORMANCE_TIMELINE_START_DATE,
  );

  const slice = 100 / (noOfWeeks > 0 ? noOfWeeks : 1);
  const markers = getArrayByLength(Number(noOfWeeks + 1), (__, i) => i + 1);
  const marks =
    markers?.map((m, i) => {
      const last = noOfWeeks + 1 === i + 1;
      return {
        value: last ? 100 : i * slice,
        date: addWeeks(SKILLS_PERFORMANCE_TIMELINE_START_DATE, i),
        first: i === 0,
        last,
      };
    }) || [];

  const getTimeLineDate = (_value) =>
    (_value === 100
      ? new Date()
      : marks?.find((m) => m?.value === _value)?.date) || new Date();

  const allUserScoreboards =
    userScoreboardsData?.listUserScoreboardsByUserId?.items?.filter((s) =>
      isWithinInterval(new Date(s?.updatedAt), {
        start: getTimeLineDate(triangleValueRange[0]),
        end: getTimeLineDate(triangleValueRange[1]),
      }),
    ) || [];

  const userScoreboards = allUserScoreboards?.filter((m) =>
    allMemberIds?.includes(m?.userId),
  );

  const columnsToAverage = ['successScore', 'speed', 'efficiency', 'score'];
  const squadAverage = getAverage(userScoreboards, columnsToAverage);
  const affiliatedUsersAverage = getAverage(
    allUserScoreboards,
    columnsToAverage,
  );
  const performanceData = {
    squad: {
      score: Math.round(squadAverage?.score) || 0,
      success: squadAverage?.successScore || 0,
      efficiency: squadAverage?.efficiency || 0,
      speed: squadAverage?.speed || 0,
    },
    affiliatedUsers: {
      success: affiliatedUsersAverage?.successScore || 0,
      efficiency: affiliatedUsersAverage?.efficiency || 0,
      speed: affiliatedUsersAverage?.speed || 0,
    },
  };
  // for each member in the squad, get their average
  const performanceDataUsers = perfTriangleMemberList
    ?.filter((m) => m.checked)
    ?.map((m) => {
      const squadMemberScoreboards = userScoreboards?.filter(
        (u) => u?.userId === m?.id,
      );
      const userAverage = getAverage(squadMemberScoreboards, columnsToAverage);
      return {
        userId: m?.id,
        userName: m?.name,
        success: userAverage?.successScore || 0,
        efficiency: userAverage?.efficiency || 0,
        speed: userAverage?.speed || 0,
      };
    });

  const onCortexValueRangeChange = (__, newValue) =>
    setCortexValueRange(newValue);
  const onTriangleValueRangeChange = (__, newValue) =>
    setTriangleValueRange(newValue);

  const assignChecked = (items, id) =>
    items?.map((i) => ({
      ...i,
      checked: id === i.id ? !i.checked : i.checked,
    }));

  useEffect(() => {
    setCortexMemberList(
      squadMembers?.map((s) => ({ ...s, checked: true })) || [],
    );
    setPerfTriangleMemberList(
      squadMembers?.map((s) => ({ ...s, checked: true })) || [],
    );
  }, [squadMembers]);

  return (
    <Box className="flex flex-col gap-y-2">
      <FdCard
        variant="outlined"
        heading={
          <CardHeading
            heading="Cyber Skills Cortex"
            subHeading={
              <Box className="text-justify">
                The Cyber Skills Cortex displays demonstrated cyber skills
                aligned with the Dreyfus model&apos;s five proficiency levels
                (1-5). Mapped to one of six FifthDomain Professional
                Specialties, each skill darkens in corresponding squares from
                the centre of the Cortex as one&apos;s demonstration of
                proficiency in that skill increases.
              </Box>
            }
            tooltipText="The Squad Cyber Skills Cortex displays skills that current Squad Managers and Squad Members have demonstrated across the platform."
          />
        }
      >
        <Box className="mt-2 mb-6">
          <Divider />
        </Box>
        <TimePeriodFilter
          marks={marks}
          step={null}
          valueRange={cortexValueRange}
          onValueChange={onCortexValueRangeChange}
          description="Drag the slider below to explore performance metrics from October 2023 to the selected week. The leftmost position represents week 1 of October 2023 (the earliest view available), and the rightmost indicates the current week."
        />
        <Box mt={1} mb={6}>
          <Divider />
        </Box>
        <Box className="flex gap-x-4 items-center">
          <Box className="flex justify-center w-3/5">
            <CyberBrain
              allMemberIds={allMemberIds}
              cortexMemberList={cortexMemberList}
              timeLine={{
                startDate: getTimeLineDate(cortexValueRange[0]),
                endDate: getTimeLineDate(cortexValueRange[1]),
              }}
            />
          </Box>
          <Box className="w-2/5">
            <SquadMemberList
              description="Use the checkboxes to filter your view of this squad's Cyber Skills Cortex:"
              allMembers={cortexMemberList}
              onMemberSelect={(id) =>
                setCortexMemberList((items) => assignChecked(items, id))
              }
            />
          </Box>
        </Box>
      </FdCard>
      <FdCard
        variant="outlined"
        heading={
          <CardHeading
            heading="Squad Performance Triangle"
            subHeading={
              <Box className="text-justify">
                The Performance Triangle visualises Success, Efficiency, and
                Speed. The blue triangle represents the average of this squad,
                whilst the grey one represents this organisation&&apos;s
                affiliated user average. Use the checkboxes below to populate
                Performance Triangles for specific members of this squad.
              </Box>
            }
            tooltipText="The Squad Performance Triangle displays performance data that current Squad Managers and Squad Members have demonstrated across the platform."
          />
        }
      >
        <TimePeriodFilter
          marks={marks}
          step={null}
          valueRange={triangleValueRange}
          onValueChange={onTriangleValueRangeChange}
          description="Drag the slider below to explore performance metrics from October 2023 to the selected week. The leftmost position represents week 1 of October 2023 (the earliest view available), and the rightmost indicates the current week. "
        />
        <Box mt={1} mb={6}>
          <Divider />
        </Box>
        <Box className="flex gap-x-4 items-center">
          <Box className="w-3/5">
            <PerformanceTriangle
              performanceData={performanceData}
              performanceDataUsers={performanceDataUsers}
            />
          </Box>
          <Box className="w-2/5">
            <Box my={3} className="flex flex-col gap-y-3 w-full justify-center">
              <GraphBar color="rgba(22, 51, 102, 1)" label="Squad Average" />
              <GraphBar
                color="rgba(180, 191, 197, 1)"
                label="Affiliated User Average"
              />
            </Box>
            <SquadMemberList
              description="Use the checkboxes to filter your view of this squad's Performance Triangle:"
              allMembers={perfTriangleMemberList}
              onMemberSelect={(id) =>
                setPerfTriangleMemberList((items) => assignChecked(items, id))
              }
              showColorBar
            />
          </Box>
        </Box>
      </FdCard>
    </Box>
  );
};

SquadSkills.propTypes = {
  squadMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SquadSkills;
