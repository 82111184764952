import React from 'react';

const NoSquadIcon = () => (
  <svg
    width="52"
    height="46"
    viewBox="0 0 52 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.00868 38.6424C2.05313 38.6424 0.453125 40.2424 0.453125 42.198C0.453125 44.1535 2.05313 45.7535 4.00868 45.7535C5.96424 45.7535 7.56424 44.1535 7.56424 42.198C7.56424 40.2424 5.96424 38.6424 4.00868 38.6424ZM9.34201 29.7535C7.38646 29.7535 5.78646 31.3535 5.78646 33.3091C5.78646 35.2647 7.38646 36.8647 9.34201 36.8647C11.2976 36.8647 12.8976 35.2647 12.8976 33.3091C12.8976 31.3535 11.2976 29.7535 9.34201 29.7535ZM14.6753 38.6424C12.7198 38.6424 11.1198 40.2424 11.1198 42.198C11.1198 44.1535 12.7198 45.7535 14.6753 45.7535C16.6309 45.7535 18.2309 44.1535 18.2309 42.198C18.2309 40.2424 16.6309 38.6424 14.6753 38.6424Z"
      fill="black"
      fill-opacity="0.38"
    />
    <path
      d="M37.5634 38.6424C35.6078 38.6424 34.0078 40.2424 34.0078 42.198C34.0078 44.1535 35.6078 45.7535 37.5634 45.7535C39.5189 45.7535 41.1189 44.1535 41.1189 42.198C41.1189 40.2424 39.5189 38.6424 37.5634 38.6424ZM42.8967 29.7535C40.9411 29.7535 39.3411 31.3535 39.3411 33.3091C39.3411 35.2647 40.9411 36.8647 42.8967 36.8647C44.8523 36.8647 46.4523 35.2647 46.4523 33.3091C46.4523 31.3535 44.8523 29.7535 42.8967 29.7535ZM48.23 38.6424C46.2745 38.6424 44.6745 40.2424 44.6745 42.198C44.6745 44.1535 46.2745 45.7535 48.23 45.7535C50.1856 45.7535 51.7856 44.1535 51.7856 42.198C51.7856 40.2424 50.1856 38.6424 48.23 38.6424Z"
      fill="black"
      fill-opacity="0.38"
    />
    <path
      d="M4.00868 9.254C2.05313 9.254 0.453125 10.854 0.453125 12.8096C0.453125 14.7651 2.05313 16.3651 4.00868 16.3651C5.96424 16.3651 7.56424 14.7651 7.56424 12.8096C7.56424 10.854 5.96424 9.254 4.00868 9.254ZM9.34201 0.365112C7.38646 0.365112 5.78646 1.96511 5.78646 3.92067C5.78646 5.87622 7.38646 7.47622 9.34201 7.47622C11.2976 7.47622 12.8976 5.87622 12.8976 3.92067C12.8976 1.96511 11.2976 0.365112 9.34201 0.365112ZM14.6753 9.254C12.7198 9.254 11.1198 10.854 11.1198 12.8096C11.1198 14.7651 12.7198 16.3651 14.6753 16.3651C16.6309 16.3651 18.2309 14.7651 18.2309 12.8096C18.2309 10.854 16.6309 9.254 14.6753 9.254Z"
      fill="black"
      fill-opacity="0.38"
    />
    <path
      d="M37.5634 9.254C35.6078 9.254 34.0078 10.854 34.0078 12.8096C34.0078 14.7651 35.6078 16.3651 37.5634 16.3651C39.5189 16.3651 41.1189 14.7651 41.1189 12.8096C41.1189 10.854 39.5189 9.254 37.5634 9.254ZM42.8967 0.365112C40.9411 0.365112 39.3411 1.96511 39.3411 3.92067C39.3411 5.87622 40.9411 7.47622 42.8967 7.47622C44.8523 7.47622 46.4523 5.87622 46.4523 3.92067C46.4523 1.96511 44.8523 0.365112 42.8967 0.365112ZM48.23 9.254C46.2745 9.254 44.6745 10.854 44.6745 12.8096C44.6745 14.7651 46.2745 16.3651 48.23 16.3651C50.1856 16.3651 51.7856 14.7651 51.7856 12.8096C51.7856 10.854 50.1856 9.254 48.23 9.254Z"
      fill="black"
      fill-opacity="0.38"
    />
  </svg>
);

export default NoSquadIcon;
