import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const options = {
  position: 'bottom-left',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const styles = {
  color: '#000',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)',
  borderRadius: '8px',
  fontFamily: 'Montserrat',
  width: 'max-content',
};

export const successToastMessage = (message) => {
  toast.success(message, {
    ...options,
    style: {
      ...styles,
      backgroundColor: 'rgba(202, 247, 236, 1)',
      '--toastify-icon-color-success': '#28957B',
    },
    toastId: 'success-toast',
  });
};

export const errorToastMessage = (message) => {
  toast.error(message, {
    ...options,
    style: {
      ...styles,
      backgroundColor: 'rgba(255, 219, 230, 1)',
      '--toastify-icon-color-error': '#C62828',
    },
    toastId: 'error-toast',
  });
};

export const warningToastMessage = (message) => {
  toast.warning(message, {
    ...options,
    style: {
      ...styles,
      backgroundColor: 'rgba(255, 243, 224, 1)',
      '--toastify-icon-color-warning': '#EF6C00',
    },
    toastId: 'warning-toast',
  });
};

export const infoToastMessage = (message) => {
  toast.warning(message, {
    ...options,
    style: {
      ...styles,
      backgroundColor: 'rgba(235, 242, 255, 1)',
      '--toastify-icon-color-info': '#2420E2',
    },
    toastId: 'info-toast',
  });
};

export const stickyToastMessage = (
  icon,
  message,
  toastId,
  backgroundColor = '#FFE0B2',
) => {
  toast.warning(message, {
    position: 'bottom-left',
    autoClose: false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      ...styles,
      backgroundColor,
      width: '800px',
      '--toastify-icon-color-warning': '#EF6C00',
    },
    toastId,
    icon,
  });
};
