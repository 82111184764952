import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import {
  FdCard,
  FdButton,
  FdTypography,
  FdModal,
  FdSelect,
  FdLoadingSpinner,
  FdRadioGroup,
  FdMultiChipTextField,
  InformationBox,
  useQueryRecursive,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import {
  listUsersByOrgId,
  listUsersByGroupId,
  listInvitedUsersByGroupId,
} from '../../graphql/queries';
import GroupUsersTable from './GroupUsersTable';
import AddUserImage from '../../shared/images/add-user.svg';
import {
  warningToastMessage,
  errorToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';
import { validEmail } from '../../shared/utils/emailUtil';
import { addUsers, removeInvitedUser } from '../../graphql/mutations';

const GroupUsers = ({
  onAddUsers,
  manageUserAndGroupLoading,
  onRemoveUser,
  groupId,
}) => {
  const registeredType1 = 'Registered Users';
  const registeredType2 = 'Add Users via Email';
  const [action, setAction] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [registeredType, setRegisteredType] = useState(registeredType1);
  const [usersForTableDisplay, setUsersForTableDisplay] = useState([]);
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { orgId } = useSnapshot(globalStore);

  const {
    data: listInvitedUsersByGroupIdData,
    loading: listInvitedUsersByGroupIdLoading,
    refetch: refetchListInvitedUsersByGroupId,
  } = useQueryRecursive(gql(listInvitedUsersByGroupId), {
    variables: {
      groupId,
      filter: { accepted: { eq: false } },
      limit: 500,
    },
    skip: !groupId,
  });

  const [removeInvitedUserMutation, { loading: removeInvitedUserLoading }] =
    useMutation(gql(removeInvitedUser), {
      onCompleted: () => {
        refetchListInvitedUsersByGroupId();
        successToastMessage('Success! User deleted');
      },
    });

  const {
    data: listUsersByGroupIdData,
    loading: listUsersByGroupIdLoading,
    refetch: refetchListUsersByGroupId,
  } = useQueryRecursive(gql(listUsersByGroupId), {
    variables: {
      groupId,
      limit: 500,
    },
    skip: !groupId,
  });

  const {
    data: registeredUsersData,
    loading: registeredUsersLoading,
    refetch: refetchListUsersByOrgId,
  } = useQueryRecursive(gql(listUsersByOrgId), {
    variables: {
      orgId,
    },
    skip: !orgId,
  });

  const { setValue, control, getValues } = useFormContext();
  const users = useWatch({
    control,
    name: 'users',
    defaultValue: getValues('users'),
  });

  if (
    action === registeredType2 &&
    listUsersByGroupIdData?.listUsersByGroupId?.items?.length > users.length
  ) {
    listUsersByGroupIdData?.listUsersByGroupId?.items?.forEach((groupUser) => {
      if (!users.includes(groupUser.userId)) {
        users.push(groupUser.userId);
      }
    });
    setValue('users', users);
  }

  const [addUsersMutation, { loading: inviteParticipantLoading }] = useMutation(
    gql(addUsers),
    {
      onCompleted: (_data) => {
        setShowModal(false);
        setEmails([]);
        setRegisteredType(registeredType1);
        successToastMessage('Participant/s Invited');
        refetchListUsersByGroupId();
        refetchListUsersByOrgId();
        refetchListInvitedUsersByGroupId();
      },
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );

  useEffect(() => {
    if (groupId) {
      const _users =
        listUsersByGroupIdData?.listUsersByGroupId?.items.map((u) => ({
          name: u.user.name,
          id: u.user.id,
          email: u.user.email,
        })) || [];
      setUsersForTableDisplay((u) => [...u, ..._users]);

      // display invited users for this group
      const _invitedUsers =
        listInvitedUsersByGroupIdData?.listInvitedUsersByGroupId?.items.map(
          (invitedUser) => ({
            name: '-',
            id: invitedUser.id,
            email: invitedUser.email,
          }),
        ) || [];
      setUsersForTableDisplay((u) => [...u, ..._invitedUsers]);
    }
  }, [groupId, listUsersByGroupIdData, listInvitedUsersByGroupIdData]);

  if (
    registeredUsersLoading ||
    manageUserAndGroupLoading ||
    listUsersByGroupIdLoading ||
    removeInvitedUserLoading ||
    listInvitedUsersByGroupIdLoading
  ) {
    return <FdLoadingSpinner />;
  }

  const registeredUsers = registeredUsersData?.listUsersByOrgId?.items || [];

  const openModal = () => {
    setShowError(false);
    setSelectedUsers([]);
    setShowModal(true);
  };
  // exclude existing users
  const availableUsers = registeredUsers.filter((ru) => !users.includes(ru.id));
  // users for dropdown display
  const usersForSelectDisplay = availableUsers
    .map((au) => `${au.name} (${au.email})`)
    .sort((a, b) => a.localeCompare(b));
  const onDeleteUser = async (row) => {
    const { id, name } = row;
    if (name === '-') {
      await removeInvitedUserMutation({
        variables: {
          inviteToken: id,
        },
      });
    } else {
      setAction('DELETE');

      setValue(
        'users',
        users.filter((u) => u !== row.id),
      );
      setUsersForTableDisplay((prevUsers) =>
        prevUsers.filter((u) => u.id !== row.id),
      );
      onRemoveUser?.(row); // trigger callback if present
    }
  };

  return (
    <FdCard>
      {usersForTableDisplay?.length > 0 ? (
        <Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <FdTypography variant="h3">Users in the Group</FdTypography>
            <FdButton variant="primary" onClick={() => openModal()}>
              Add Users
            </FdButton>
          </Box>
          <GroupUsersTable
            rows={_.uniqBy(usersForTableDisplay, 'email')}
            onDeleteRow={onDeleteUser}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <FdTypography variant="h2">
              Start adding Users to the Group.
            </FdTypography>
          </Box>
          <Box my={2}>
            <img src={AddUserImage} alt="add-user" />
          </Box>
          <Box my={1}>
            <FdButton size="large" onClick={() => openModal()}>
              Add Users
            </FdButton>
          </Box>
        </Box>
      )}
      <FdModal
        size="md"
        title="Add Users"
        confirm={inviteParticipantLoading ? 'Loading...' : 'OK'}
        dismiss="CANCEL"
        open={showModal}
        onConfirm={() => {
          if (registeredType === registeredType1 && selectedUsers.length) {
            setShowModal(false);
            // find userIds from the dropdown selection list
            const selectedUserIds = availableUsers
              .filter((au) =>
                selectedUsers.includes(`${au.name} (${au.email})`),
              )
              .map((u) => u.id);
            setValue('users', [...users, ...selectedUserIds]);

            const _newUsers = availableUsers
              .filter((au) =>
                selectedUsers.includes(`${au.name} (${au.email})`),
              )
              .map((u) => ({
                name: u.name,
                id: u.id,
                email: u.email,
              }));
            setUsersForTableDisplay((u) => [...u, ..._newUsers]);

            setAction(registeredType1);
            onAddUsers?.(selectedUserIds); // run callback if present
          } else if (registeredType === registeredType2) {
            if (emails.length < 1) {
              setError('Groups must have at least one member');
              return;
            }

            if (emails.some((email) => !validEmail(email))) {
              setError('One or more of the email addresses are invalid');
              return;
            }
            setAction(registeredType2);
            addUsersMutation({
              variables: {
                emails,
                orgId,
                groups: [groupId],
                type: 'PARTICIPANT',
                participantType: 'EXTERNAL',
                participantEventType: document.location.host.includes('lab.')
                  ? 'LAB'
                  : undefined,
              },
            });
          } else {
            setShowError(true);
          }
        }}
        onDismiss={() => {
          setShowModal(false);
          warningToastMessage('Changes to group not saved');
        }}
      >
        {groupId && (
          <Box mb={2} ml={1}>
            <FdRadioGroup
              id="selectUserType"
              options={[registeredType1, registeredType2]}
              onChange={(e) => {
                setRegisteredType(e.value);
              }}
              defaultValue={registeredType}
            />
          </Box>
        )}

        {registeredType === registeredType1 && (
          <Box display="flex" alignItems="flex-end" mb={2} mt={0.5}>
            <FdSelect
              inputTitle={registeredType1}
              id="users"
              options={usersForSelectDisplay}
              multiple
              width="100%"
              onChange={setSelectedUsers}
              error={showError && selectedUsers.length === 0}
              helperText={
                showError &&
                selectedUsers.length === 0 &&
                'Groups must have at least one member'
              }
            />
          </Box>
        )}
        {registeredType === registeredType2 && (
          <Box display="flex" alignItems="flex-end" mb={2} mt={1}>
            <FdMultiChipTextField
              id="Emails"
              width="100%"
              label="Email Addresses"
              placeholder="Emails"
              helperText="Tip:You can copy and paste a list of emails"
              required
              values={emails}
              setValues={setEmails}
              data-cy="emails-selector"
            />
          </Box>
        )}
        {error && (
          <Box display="flex">
            <InformationBox variant="error" status="" message={error} />
          </Box>
        )}
      </FdModal>
    </FdCard>
  );
};

GroupUsers.propTypes = {
  onAddUsers: PropTypes.func,
  onRemoveUser: PropTypes.func,
  manageUserAndGroupLoading: PropTypes.bool,
  groupId: PropTypes.string.isRequired,
};

GroupUsers.defaultProps = {
  onAddUsers: undefined,
  onRemoveUser: undefined,
  manageUserAndGroupLoading: false,
};

export default GroupUsers;
