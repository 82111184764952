import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { FdTextField } from '@fifthdomain/fe-shared';

const SquadDetails = ({ squadId }) => {
  const { control } = useFormContext();

  return (
    <Box className="flex flex-col mt-2">
      <Box>
        <Controller
          control={control}
          name={`${squadId}.squadName`}
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id={`${squadId}.squadName`}
                label="Squad Name"
                required
                fullWidth
                error={error}
                placeholder="Squad name must be less than 50 characters."
                helperText={
                  (error && error.message) ||
                  'Enter a name for this team that is viewable by participants.'
                }
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
        <Box mt={0} mb={0}>
          <Controller
            control={control}
            name={`${squadId}.squadDescription`}
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mb={2}>
                <FdTextField
                  id={`${squadId}.squadDescription`}
                  label="Squad Description"
                  placeholder="Squad description must be less than 80 characters."
                  fullWidth
                  multiline
                  rows={3}
                  error={error}
                  helperText={error && error.message}
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

SquadDetails.propTypes = {
  isEditMode: PropTypes.bool,
  onClickSave: PropTypes.func,
  squadId: PropTypes.string,
};

SquadDetails.defaultProps = {
  isEditMode: false,
  onClickSave: undefined,
  squadId: undefined,
};

export default SquadDetails;
