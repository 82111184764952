import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdButton,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import ReadinessScore from './Fragments/ReadinessScore';
import SkillsTargetModal from './Fragments/SkillsTargetModal';
import { getFunctionCortexTargets, getReadinessScore } from './utils';
import FulfilledCortex from './Cortex/FulFilledCortex';

const FunctionSkillsSquadsProfile = ({ userMetrics }) => {
  const [openSkillsModal, setOpenSkillsModal] = useState(null);
  const { getValues } = useFormContext();
  const {
    features: { hasSkillTarget },
  } = useSnapshot(globalStore);
  const workRoles = getValues('workRoles');
  const squads = getValues('squads');
  const selectedSquads =
    squads?.map((s) => [
      ...(s?.squadManagers?.filter((m) => m?.selected) || []),
      ...(s?.squadMembers?.filter((m) => m?.selected) || []),
    ]) || [];
  const selectedSquadsUsers = selectedSquads?.flat();
  const { targets, fillers } = getFunctionCortexTargets(workRoles);
  const selectedSquadMembers = selectedSquadsUsers?.map((s) => s?.userId) || [];
  const selectedUserMetrics =
    userMetrics?.filter((m) => selectedSquadMembers.includes(m?.userId)) || [];
  const cortexData = {
    targets: hasSkillTarget ? targets : [],
    fillers: hasSkillTarget ? fillers : [],
    metrics: selectedUserMetrics,
  };
  const selectedUsersCount = selectedSquadsUsers?.length || 0;
  const squadsForMetrics =
    squads?.map((s) => ({
      members: {
        items: [
          ...(s?.squadManagers
            ?.filter((m) => m?.selected)
            ?.map((sm) => ({ userId: sm?.userId, squadName: s?.squadName })) ||
            []),
          ...(s?.squadMembers
            ?.filter((m) => m?.selected)
            ?.map((sm) => ({ userId: sm?.userId, squadName: s?.squadName })) ||
            []),
        ],
      },
    })) || [];
  const { fulfilledUnitsDisplay, readinessScore } = getReadinessScore(
    targets,
    selectedUserMetrics,
    squadsForMetrics,
  );
  const targetUnits = _.sumBy(targets, 'units');

  return (
    <Box>
      <Box className="flex items-center gap-x-6">
        <FdTypography variant="h4">Functions Skills Profile</FdTypography>
        {hasSkillTarget && (
          <FdButton
            variant="secondary"
            onClick={() => setOpenSkillsModal(true)}
          >
            View function skills target
          </FdButton>
        )}
      </Box>
      <Box mt={2}>
        <FdTypography variant="captiontext1" color="secondary">
          {hasSkillTarget ? (
            <>
              Use checkboxes in the Squads section to filter the view of
              fulfilled and required skills. Hover over a square to see units
              fulfilled (filled squares) vs. required (bordered squares) for
              each Skill-Proficiency. Total units for a skill are shown on the
              side labels as (fulfilled/required). Click the info icons on the
              top bar for definitions of required, fulfilled, and unfulfilled
              units.
            </>
          ) : (
            <>
              Use the checkboxes in the Squads section to filter your view of
              cumulative demonstrated skills. You can hover over each
              Skill-Proficiency square in the Cyber Skills Cortex below to see
              skill names and contributors.
            </>
          )}
        </FdTypography>
        <Box
          className={`flex flex-col gap-y-1 mt-2 ${!hasSkillTarget && 'items-center'}`}
          sx={{
            backgroundColor: '#F4F4F4',
            borderRadius: '4px',
            mt: 1,
            padding: '8px 16px',
          }}
        >
          <FdTypography variant="body2" color="secondary">
            Selected:<strong>{` ${selectedUsersCount} `}</strong>users in{' '}
            <strong>{` ${
              selectedSquads?.filter((s) => s?.length > 0).length
            } `}</strong>{' '}
            Squads
          </FdTypography>
          {hasSkillTarget && (
            <Box className="flex items-center justify-between">
              <FdTypography variant="body2">
                Fulfilled Units for selected:
                <strong>{` ${fulfilledUnitsDisplay}/${targetUnits} `}</strong>
              </FdTypography>
              <Box className="flex items-center gap-x-2">
                <FdTypography variant="body2">
                  Readiness Score for selected:
                </FdTypography>
                <ReadinessScore score={readinessScore} />
              </Box>
            </Box>
          )}
        </Box>
        <Box className="flex justify-center mt-4">
          <FulfilledCortex data={cortexData} squads={squadsForMetrics} />
        </Box>
      </Box>
      <SkillsTargetModal
        openModal={openSkillsModal}
        setOpenModal={setOpenSkillsModal}
      />
    </Box>
  );
};

FunctionSkillsSquadsProfile.propTypes = {
  userMetrics: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default FunctionSkillsSquadsProfile;
